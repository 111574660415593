import { useState } from "react";
import { useAppContext } from "../context/useAppContext";
import Routes from "../Routes";
import { AnimatePresence, motion } from "framer-motion";

export function Container() {
  const { isLoading } = useAppContext();
  return (
    <div
      className="flex justify-center items-center w-screen h-[calc(var(--vh,1vh)_*_100)] dark:bg-slate-800 overflow-hidden"
      >
      {isLoading ? (
        <motion.div
          className="flex justify-center items-center w-40 h-40 rounded-[50%] bg-primary-400 relative"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            repeat: Infinity,
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <span className="text-white">Loading...</span>
        </motion.div>
      ) : (
        <AnimatePresence mode="wait">
          <Routes />
        </AnimatePresence>
      )}
    </div>
  );
}
