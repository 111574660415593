import { API } from "aws-amplify";

export interface IGroup {
    category: string;
    items: IGroupItem[]
}

export interface IGroupItem {
    groupId: string;
    name: string;
    default: boolean;
    color: string;
    createdAt: string;
    updatedAt: string;
}

export type CreateGroupParams = {
    name: string;
    color: string;
}

export type UpdateGroupParmas = {
    groupId: string;
    name: string;
    color: string;
}

export type MoveGroupParams = {
    moveGroupId: string;
    targetGroupIndex: number;
}

export type DeleteGroupParams = {
    groupId: string;
}

export function createGroup(params: CreateGroupParams): Promise<string> {
    return API.put("groups", "/groups", {
        body: params
    });
}

export function getGroup(): Promise<IGroup> {
    return API.get("groups", "/groups", {});
}

export function updateGroups(params: UpdateGroupParmas): Promise<IGroup> {
    return API.post("groups", "/groups", {
        body: params
    })
}

export function moveGroup(params: MoveGroupParams): Promise<IGroup> {
    return API.post("groups", "/groups/move", {
        body: params
    });
}

export function deleteGroup(params: DeleteGroupParams): Promise<IGroup> {
    return API.del("groups", "/groups", {
        body: params
    });
}
