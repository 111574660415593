import { SyntheticEvent, useState } from "react";
import { useAppContext } from "../context/useAppContext";
import { motion } from "framer-motion";
import { formClasses, inputClasses } from "../styles";
import clsx from "clsx";
import { useFormFields } from "../hooks/useFormFields";
import Button from "../components/Button";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { createUser } from "../api/users";

export type ConfirmSignupProps = {
  email: string;
  password: string;
};

export function ConfirmSignup({ email, password }: ConfirmSignupProps) {
  const { onLoad, setAuthenticated, theme } = useAppContext();
  const signupClasses = clsx(formClasses, { "bg-default": theme === "light" });
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    confirmationCode: "",
  });
  const navigate = useNavigate();

  async function handleConfirmationSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(email, fields.confirmationCode);
      await Auth.signIn(email, password);
      await createUser();
      await onLoad();
      setAuthenticated(true);
      navigate("/");
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'CodeMismatchException') setIsInvalidCode(true);
      }
    } finally {
        setIsLoading(false);
    }
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  return (
    <motion.div
      className="w-full h-full"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
      }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <form className={signupClasses} onSubmit={handleConfirmationSubmit}>
        <div className="px-4 py-8 mx-4 shadow sm:rounded-lg sm:px-10 sm:mx-auto sm:w-full sm:max-w-md bg-white dark:bg-slate-800 dark:text-white">
          <div className="flex items-center justify-between sm:mx-auto sm:w-full sm:max-w-md mb-2">
            <h1 className="text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
              확인코드 입력
            </h1>
            <img
              className="flex w-14 h-14"
              src="./android-chrome-192x192.png"
              alt="Todo App"
            />
          </div>

          <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                이메일 주소
              </label>
              <div className="mt-2">
                <input
                  name="confirmationCode"
                  id="confirmationCode"
                  type="text"
                  autoFocus
                  autoComplete="confirmationCode"
                  required
                  className={inputClasses}
                  onChange={handleFieldChange}
                />
              </div>
              <p
                  className={clsx(
                    "mt-1 text-sm leading-6 text-red-500",
                    isInvalidCode ? 'inline-flex' : 'hidden'
                  )}
                >
                  잘못된 코드 정보입니다.
                </p>
              <p className="mt-1 text-sm leading-6 text-gray-400">
                {email}로 받은 6자리 코드를 입력해주세요.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between w-full mt-2 gap-x-2">
            <Button
              text="확인"
              type="primary"
              buttonType="submit"
              containerClassNames="flex-grow"
              classNames="w-full"
              isLoading={isLoading}
              disabled={!validateConfirmationForm()}
            />
          </div>
        </div>
      </form>
    </motion.div>
  );
}
