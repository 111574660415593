import { useEffect } from 'react';
import { IGroup } from '../api/groups';
import { CalendarDay } from '../hooks/useCalendarDays';
import clsx from 'clsx';

export type TodoListProps = {
  group: IGroup;
  day: CalendarDay;
};

export default function TodoList({ group, day }: TodoListProps) {
  const { events, holidays } = day;
  const eventLength = events.filter((event) => event.completed !== true).length;
  const groupClasses = 'w-1.5 h-full border-3 rounded-md';
  const todoClasses = clsx(
    'flex items-center w-full h-16 rounded-md bg-gray-200/50 dark:bg-slate-700/50',
    'hover:bg-gray-300/50 dark:hover:bg-slate-600/50'
    )
  if (!day || eventLength + holidays.length === 0) {
    return (
      <div className="flex flex-col py-2 pb-4 gap-y-2 text-sm rounded-md text-gray-600 dark:text-gray-300">
        <div
          className={clsx(
            'flex items-center w-full h-16 rounded-md bg-gray-200/50 dark:bg-slate-700/50'
          )}
          data-type="empty"
        >
          <span
            className={clsx(groupClasses, ' bg-gray-500/50 border-gray-500/50')}
          ></span>
          <div className="ml-2 pointer-events-none">일정이 없습니다.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col py-2 pb-4 gap-y-2 text-sm rounded-md text-gray-900 dark:text-white">
      {events.filter((event) => event.completed !== true).map((event, index) => {
        return (
          <div
            key={event.todoId}
            className={todoClasses}
            data-type="todo"
            data-group-id={event.groupId}
            data-todo-id={event.todoId}
            data-date={day.date}
            data-title={event.title}
            data-content={event.content}
            data-completed={event.completed}
            data-created-at={event.createdAt}
            data-updated-at={event.updatedAt}
          > 
            <span
              className={clsx(groupClasses)}
              style={{
                background: `${
                  group.items.find((g) => g.groupId === event.groupId)?.color
                }`,
              }}
            ></span>
            <input
              type="checkbox"
              className={clsx(
                'ml-4 h-6 w-6 border rounded-full focus:ring-0 focus:ring-offset-0',
                'border-gray-300 bg-white',
                'dark:border-slate-500 dark:bg-slate-600'
              )}
              data-type="completed"
              data-todo-id={event.todoId}
              data-date={day.date}
              >
            </input>
            <div className="ml-2 pointer-events-none">{event.title}</div>
          </div>
        );
      })}
      {holidays.map((holiday, index) => {
        return (
          <div
            data-type="info"
            data-date={day.date}
            data-title={holiday.name}
            data-is-holiday={holiday.isHoliday}
            key={holiday.name}
            className={todoClasses}
          >
            <span
              className={clsx(
                groupClasses,
                holiday.isHoliday
                  ? 'bg-red-500 border-red-500'
                  : 'bg-gray-500 border-gray-500'
              )}
            ></span>
            <div className="ml-2 pointer-events-none">{holiday.name}</div>
          </div>
        );
      })}
    </div>
  );
}
