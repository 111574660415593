import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isToday from 'dayjs/plugin/isToday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import ko from 'dayjs/locale/ko';
import { CalendarDay, CalendarEvent, CalendarHoliday } from '../hooks/useCalendarDays';
import { AppMode } from '../context/useAppContext';

/**
 * - day js plugin 설정
 * - day js default time zone 설정
 */
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(isLeapYear);
dayjs.extend(isToday);
dayjs.extend(dayOfYear);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale(ko);

export enum DateFormat {
    DASH_DATE = 'YYYY-MM-DD',
    DOT_DATE = 'YYYY.MM.DD',
}

export function utilFormat(date: dayjs.ConfigType = new Date()) {
    const dateToISO = dayjs(date).toISOString();
    return dayjs(dateToISO).format('YYYY-MM-DD');
}
export function utilYear(date: dayjs.ConfigType = new Date()) {
    return dayjs(date).year();
}
export function utilMonth(date: dayjs.ConfigType = new Date()) {
    return dayjs(date).month() + 1;
}
export function utilDate(date: dayjs.ConfigType = new Date()) {
    return dayjs(date).date();
}
export function checkToday(date: dayjs.ConfigType = new Date()) {
    return dayjs(date).isToday();
}
export function isSaturday(date: dayjs.ConfigType = new Date()) {
    return dayjs(date).day() === 6 ? true : false;
}
export function isSunday(date: dayjs.ConfigType = new Date()) {
    return dayjs(date).day() === 0 ? true : false;
}
export function customDateFormat(date: dayjs.ConfigType, format: string): dayjs.Dayjs {
    return dayjs(date, format);
}

export function getDateFormat(date: dayjs.ConfigType, format: string) {
    if (!isValidDate(date)) return '';

    return dayjs(date).format(format);
}
export function getISOString(date: dayjs.ConfigType) {
    return dayjs(date).toISOString();
}
export function get9ClockISOString(date: dayjs.ConfigType): string {
    if (!isValidDate(date)) return '';
    const isoDate = `${dayjs(date).format('YYYY-MM-DD')}T09:00:00.000Z`;
    return dayjs(isoDate).toISOString();
}
export function getWeek(date: dayjs.ConfigType) {
    return dayjs(date).isoWeek();
}
export function getMonth(date: dayjs.ConfigType) {
    return dayjs(date).get('month');
}
export function getYear(date: dayjs.ConfigType) {
    return dayjs(date).get('year');
}
export function getStartDateOfYear(year: number) {
    return dayjs().year(year).startOf('year');
}
export function getEndDateOfYear(year: number) {
    return dayjs().year(year).endOf('year');
}
export function getDayOfYear(date: dayjs.ConfigType, day: number) {
    return dayjs(date).dayOfYear(day);
}
export function getStartOfDate(date: dayjs.ConfigType, unit: dayjs.ManipulateType) {
    return dayjs(date).startOf(unit).toISOString();
}
export function getEndOfDate(date: dayjs.ConfigType, unit: dayjs.ManipulateType) {
    return dayjs(date).endOf(unit).toISOString();
}
export function getAddDate(date: dayjs.ConfigType, value: number, unit: dayjs.ManipulateType) {
    return dayjs(date).add(value, unit);
}
export function getSubtractDate(date: dayjs.ConfigType, value: number, unit: dayjs.ManipulateType) {
    return dayjs(date).subtract(value, unit);
}
export function isBeforeDate(standardDate: dayjs.ConfigType, date: dayjs.ConfigType): boolean {
    if (!date || !standardDate) return false;

    return dayjs(standardDate).isBefore(dayjs(date));
}
export function isAfterDate(standardDate: dayjs.ConfigType, date: dayjs.ConfigType): boolean {
    if (!date || !standardDate) return false;

    return dayjs(standardDate).isAfter(dayjs(date));
}
export function isValidDate(date: dayjs.ConfigType): boolean {
    return dayjs(date, 'YYYY-MM-DD', true).isValid();
}
export function getCalcDate(standardDate: dayjs.ConfigType, calcDate: dayjs.ConfigType, unit: dayjs.OpUnitType): number {
    return dayjs(standardDate).diff(calcDate, unit)
}

export enum DayOfWeekEnum {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}

/**
 * - 특정 주에서 일자 정보를 받아올 때 사용
 * @param year 년도 정보
 * @param week 주차 정보(isoWeek)
 * @param day 0 ~ 6(0: 일요일, 1: 월요일, ... 6: 토요일)
 * @returns {dayjs.Dayjs} dayjs object
 */
export function getDayOfWeek(year: number, week: number, day: DayOfWeekEnum) {
    const date = dayjs().year(year);
    const dateOfWeek = date.isoWeek(week);

    return dayjs(dateOfWeek).isoWeekday(day.valueOf());
}

export function getDays(date: dayjs.ConfigType): CalendarDay[] {
    const days: CalendarDay[] = [];
    const day = dayjs(date);
    const firstDay = day.date(1).get('day');
    const startDate = dayjs(day.date(1)).subtract(firstDay, 'day');

    for (let i = 0; i < 42; i++) {
        days.push({
            date: getAddDate(startDate, i, 'day').format('YYYY-MM-DD'),
            isHoliday: false,
            events: [] as CalendarEvent[],
            holidays: [] as CalendarHoliday[]
        })
    }

    return days;
}

export function getWeekDays(date: dayjs.ConfigType): CalendarDay[] {
    const days: CalendarDay[] = [];
    const day = dayjs(date);
    const weekDay = day.day();
    const startDate = day.subtract(weekDay, 'day');

    for (let i = 0; i < 7; i++) {
        days.push({
            date: getAddDate(startDate, i, 'day').format('YYYY-MM-DD'),
            isHoliday: false,
            events: [] as CalendarEvent[],
            holidays: [] as CalendarHoliday[]
        })
    }

    return days;
}

export function isSameDate(date: dayjs.ConfigType, compareDate: dayjs.ConfigType): boolean {
    return getDateFormat(date, DateFormat.DASH_DATE) === getDateFormat(compareDate, DateFormat.DASH_DATE);
}

interface YearAndWeek {
    year: number;
    week: number;
}

export type { YearAndWeek };

export function getLastWeek(yearAndWeek: YearAndWeek): YearAndWeek {
    const isFirstWeek = yearAndWeek.week === 1;

    const yearOfLastWeek = isFirstWeek ? yearAndWeek.year - 1 : yearAndWeek.year;
    const weekOfLastWeek = isFirstWeek ? 52 : yearAndWeek.week - 1;

    return {
        year: yearOfLastWeek,
        week: weekOfLastWeek,
    };
}

export function getNextWeek(yearAndWeek: YearAndWeek): YearAndWeek {
    const isLastWeek = yearAndWeek.week === 52;

    const yearOfNextWeek = isLastWeek ? yearAndWeek.year + 1 : yearAndWeek.year;
    const weekOfNextWeek = isLastWeek ? 1 : yearAndWeek.week + 1;

    return {
        year: yearOfNextWeek,
        week: weekOfNextWeek,
    };
}

export function yearAndWeekToDayJs(yearAndWeek: YearAndWeek) {
    const date = dayjs().isoWeek(yearAndWeek.week).toDate();
    date.setFullYear(yearAndWeek.year);
    return dayjs(date);
}

export function utilFormatByTemplate(date: dayjs.ConfigType = new Date(), format: string = 'YYYY-MM-DD') {
    const dateToISO = dayjs(date).toISOString();
    return dayjs(dateToISO).format(format);
}
