import { AppMode, useAppContext } from "../context/useAppContext";
import { CalendarDayState } from "../hooks/useCalendarDays";
import { modeIcon } from "../utils/util";
import Icon from "./Icon"

export type TodoListHeaderProps = {
    calendar: CalendarDayState,
}

export default function TodoListHeader({ calendar }: TodoListHeaderProps) {
    const { mode, setMode, setSlideOpen, setSwiping } = useAppContext();
    const modeList = ['MONTH', 'DAY', 'WEEK', 'LIST', 'CHECK']
    const modeIndex = modeList.findIndex((m) => m === mode);
    return (
        <div className="flex justify-between items-stretch h-[50px] border-b border-gray-600 box-border px-2 py-3 bg-white text-gray-900 dark:bg-slate-900 dark:text-white">
            <div className="ml-2 flex gap-x-2 justify-between items-center text-lg font-bold">
                <div className="w-[100px]">
                    {calendar.selected.month}월
                </div>
            </div>
            <div className="flex gap-x-3 justify-between">
            <Icon
                name={modeIcon(mode)}
                className="text-gray-800 dark:text-white"
                size="md"
                onClick={() => setMode(modeList[modeIndex + 1 === 5 ? 0 : modeIndex + 1] as AppMode)}
            />
            <Icon name="menu" size="md" onClick={() => {
                setSwiping(false);
                setSlideOpen(true);
            }} />
            </div>
        </div>
    )
}