import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  Dispatch,
  SyntheticEvent,
} from 'react';
import { useAppContext } from '../context/useAppContext';
import { motion, AnimatePresence } from 'framer-motion';
import {
  HEADER_TEMPLATE,
  ROW_TEMPLATE,
  EMPTY_TEMPLATE,
  OPTIONS_TEMPLATE,
  EDIT_TEMPLATE,
} from '../templates/groupList';
import RealTouch from 'realgrid-touch';
import 'realgrid-touch/dist/realgrid-touch-style.css';
import { updateGroups, moveGroup, IGroup } from '../api/groups';
import LoadingSvg from './LoadingSvg';
import clsx from 'clsx';
import { useToast } from '../hooks/useToast';
import GroupColorModal from './GroupColorModal';
import Icon from './Icon';

export type TouchGroupListHandler = {};

export type TouchGroupListProps = {
  groupOpen: boolean;
  setGroupOpen: Dispatch<boolean>;
};

const RealTouchTodoList = forwardRef<
  TouchGroupListHandler,
  TouchGroupListProps
>(({ groupOpen, setGroupOpen }, ref) => {
  const { clientWindow, theme, group, updateGroup, dataLoading, setDataLoading } = useAppContext();
  const { toastPromise } = useToast();
  const touchRef = useRef<HTMLDivElement>(null);
  const selectedRow = useRef(0);
  const saveHandler = useRef<any>(() => void 0);
  const listData = useRef<RealTouch.RtListData | null>(null);
  const [list, setList] = useState<RealTouch.RtListControl | null>(null);
  const [open, setOpen] = useState(false);
  const groupId = useRef('');
  const name = useRef('');
  const selectedColor = useRef('');
  const [color, setColor] = useState('');
  const [isEditOpen, setIsEditOpen] = useState(false);

  function touchRefreshLayout() {
    if (list) list['$_c'].invalidateLayout();
  }

  const config = {
    props: {
      numberFormat: ',',
      templates: {
        header: HEADER_TEMPLATE,
        row: ROW_TEMPLATE,
        empty: EMPTY_TEMPLATE,
        edit: EDIT_TEMPLATE
      },
    },
    options: OPTIONS_TEMPLATE,
  };

  const rowClickHandler = (args) => {
    if (!args) return;

    selectedRow.current = args.row;
    const { control, row } = args;
    const selectGroupId = control.data.getValue(row, 'groupId');
    const selectName = control.data.getValue(row, 'name');
    const selectColor = control.data.getValue(row, 'color');

    groupId.current = selectGroupId;
    name.current = selectName;
    selectedColor.current = selectColor;
    setColor(selectColor);

    setIsEditOpen(true);
  }

  const rowMoveHandler = async (args) => {
    if (args) {
      const { data, to } = args;
      const moveGroupId = data.getValue(to, 'groupId');

      setDataLoading(true);
      const result = await toastPromise(
        moveGroup({ moveGroupId, targetGroupIndex: to }),
        '그룹 정보를 저장중입니다.',
        '그룹 정보가 저장되었습니다.'
      );
      updateGroup(result);
      setDataLoading(false);
    }
  };

  const rowSaveHandler = async (args) => {
    setDataLoading(true);
    const result: IGroup = await toastPromise(
      updateGroups({
        groupId: groupId.current,
        name: name.current,
        color: selectedColor.current
      }),
      '그룹 정보를 저장중입니다.',
      '그룹 정보가 저장되었습니다.'
    );
    updateGroup(result);
    setDataLoading(false);

    // Data Sync
    listData.current?.loadData(result.items.map(item => {
      return {
        groupId: item.groupId,
        name: item.name,
        color: item.color,
        default: item.default
      }
    }));

    args.control.closeEditPage(false);
    setIsEditOpen(false);
  }

  const rowCancelHandler = (args) => {
    args.control.closeEditPage(false);
    setIsEditOpen(false);
  }

  const colorClickHandler = (args) => {
    setOpen(true);
    saveHandler.current = (color: string) => {
      selectedColor.current = color;
    }
  }
  // const rowDeleteHandler = async (args) => {
  //   const groupId = args.control.data.getValue(selectedRow.current, 'groupId')

  //   setDataLoading(true);
  //   const result = await toastPromise(
  //     deleteGroup({ groupId }),
  //     '그룹 정보를 삭제중입니다.',
  //     '그룹 정보가 삭제되었습니다.'
  //   );
  //   updateGroup(result);
  //   listData.current?.deleteRow(selectedRow.current);
  //   args.control.closeEditPage(false);
  //   setDataLoading(false);
  // }

  useEffect(() => {
    touchRefreshLayout();
  }, [clientWindow.height])
  useEffect(() => {
    if (!listData.current || !list) return;

    list.onRowClick = rowClickHandler;
    // list.options.row.updateTemplate('edit', 'delete', 'renderer.onClick', rowDeleteHandler);

    listData.current.onRowMove = rowMoveHandler;
    list.options.row.updateTemplate('edit', 'color', 'renderer.onClick', colorClickHandler);
    list.options.row.updateTemplate('edit', 'save', 'renderer.onClick', rowSaveHandler);
    list.options.row.updateTemplate('edit', 'cancel', 'renderer.onClick', rowCancelHandler);
    list.options.row.updateTemplate('edit', 'name', 'editor.onChange', (args) => {
      name.current = (args.editValue);
    });
  }, [group]);

  // 첫 로드 시 설정
  useEffect(() => {
    const listControl = RealTouch.createListControl(
      document,
      touchRef.current!
    );

    const data = RealTouch.createListData(
      'data',
      {
        fields: [
          { name: 'name' },
          { name: 'groupId' },
          { name: 'color' },
          { name: 'default' },
        ],
      },
      {
        values: group.items.map((g) => {
          return {
            name: g.name,
            groupId: g.groupId,
            color: g.color,
            default: g.default,
          };
        }),
      }
    );

    listData.current = data;
    listControl.data = data;
    listControl.setConfig(config);
    setList(listControl);


    listControl.onRowClick = rowClickHandler;
    data.onRowMove = rowMoveHandler;
    listControl.options.row.updateTemplate('edit', 'color', 'renderer.onClick', colorClickHandler);
    listControl.options.row.updateTemplate('edit', 'save', 'renderer.onClick', rowSaveHandler);
    listControl.options.row.updateTemplate('edit', 'cancel', 'renderer.onClick', rowCancelHandler);
    listControl.options.row.updateTemplate('edit', 'name', 'editor.onChange', (args) => {
      name.current = (args.editValue);
    });
    // listControl.options.row.updateTemplate('edit', 'delete', 'renderer.onClick', rowDeleteHandler);

    listControl.options.header.style.background =
      theme === 'dark' ? '#1e293b' : '#f3f4f6';
    listControl.options.header.captionColor =
      theme === 'dark' ? 'white' : '#111827';
    listControl.options.row.style.background =
      theme === 'dark' ? '#0f172a' : 'white';
    listControl.options.row.style.color =
      theme === 'dark' ? 'white' : '#1f2937';

    return () => {
      if (list) {
        list.destroy();
      }

      /* touch destory가 돔을 없애주지 않는 현상이 있다. */
      if (touchRef.current) {
        const children = touchRef.current.children;
        for (let i = 0; i < children.length; i++) {
          children.item(i)?.remove();
        }
      }
    };
  }, []);

  function ExpanderStyle() {
    if (theme === 'dark')
      return (
        <style>
          {`
              [type='checkbox']:focus {
                outline: none !important;
                outline-offset: 0 !important;
                box-shadow: none !important;
              }
              .rtc-body {
                background: #0f172a;
              }
              .rtc-item-expander {
                stroke: white !important;
              }
              .-rtc-layout- {
                left: 0 !important;
              }
              .rtc-row[data-touched='1'] {
                background: #1e293b !important;
              }
              .rtc-toast {
                background: #334155 !important;
              }
              .rtc-row-edit-page {
                color: white;
                background: #0f172a;
              }
              .rtc-form-label {
                font-size: 14px;
                color: white;
              }
              .rtc-input-input {
                background: #1e293b;
                font-size: 14px !important;
              }
            `}
        </style>
      );
    else
      return (
        <style>
          {`
              [type='checkbox']:focus {
                outline: none !important;
                outline-offset: 0 !important;
                box-shadow: none !important;
              }
              .rtc-body {
                background: white;
              }
              .rtc-item-expander {
                stroke: #1f2937 !important;
              }
              .-rtc-layout- {
                left: 0 !important;
              }
              .rtc-toast {
                  background-color: #f3f4f6 !important;
              }
              .rtc-row-edit-page {
                background: white;
              }
              .rtc-form-label {
                font-size: 14px;
              }
              .rtc-input-input {
                font-size: 14px !important;
              }
            }
          `}
        </style>
      );
  }

  return (
    <>
      {ExpanderStyle()}
      <div className="flex flex-col w-full h-full">
        <div className="flex px-4 justify-start gap-8 items-center my-2">
          {isEditOpen ?
            <h1 className="text-base font-bold py-2 text-gray-900 dark:text-gray-200">
              할 일 그룹 편집
            </h1> : <>
              <Icon
                name="arrowLeft"
                size="md"
                className="text-gray-900 dark:text-white"
                onClick={() => setGroupOpen(false)} /><h1 className="text-base font-bold py-2 text-gray-900 dark:text-gray-200">
                할 일 그룹 관리
              </h1>
            </>}
        </div>
        {dataLoading && (
          <AnimatePresence mode={'wait'}>
            <motion.div
              className={clsx(
                'absolute opacity-50 flex w-full h-full justify-center items-center bg-gray-100/50 dark:bg-slate-800/60 z-20'
              )}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0.5, transition: { duration: 0.3 } }}
            >
              <LoadingSvg />
            </motion.div>
          </AnimatePresence>
        )}
        <motion.div
          id="realgrid-touch"
          ref={(node) => {
            if (touchRef) (touchRef.current as any) = node;
          }}
          className="w-full h-full dark:bg-[#4d4e52]"
        ></motion.div>
        <GroupColorModal open={open} setOpen={setOpen} color={color} control={list!} saveHandler={saveHandler.current} />
      </div>
    </>
  );
});

export default RealTouchTodoList;
