import { Dispatch, Fragment, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Icon from './Icon';
import GroupCreateModal from './GroupCreateModal';
import { AppMode, useAppContext } from '../context/useAppContext';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { useSwipeable } from 'react-swipeable';
import {
  AnimatePresence,
  motion,
  useAnimate,
  useMotionValue,
} from 'framer-motion';
import {
  CalendarAction,
  CalendarDayActionType,
  CalendarDayState,
} from '../hooks/useCalendarDays';
import { useToast } from '../hooks/useToast';
import { getYear } from '../utils/date';
import LoadingSvg from './LoadingSvg';
import Button from './Button';
import GroupUpdateModal from './GroupUpdateModal';
import TouchGroupList from './TouchGroupList';

export type SlideOversProps = {
  calendar: CalendarDayState;
  setCalendar: Dispatch<CalendarAction>;
  loadDay: (groups: string[]) => Promise<void>;
  refereshDays: (mode: AppMode) => Promise<void>;
};

export default function SlideOvers({
  calendar,
  setCalendar,
  loadDay,
  refereshDays
}: SlideOversProps) {
  const {
    dataLoading,
    setDataLoading,
    group,
    mode,
    setShowHoliday,
    searchHolidays,
    visibleGroups,
    setVisibleGroups,
    setMode,
    clientWindow,
    slideOpen,
    setSlideOpen,
    theme,
    themeSwitch,
    setAuthenticated,
    setIsLoading,
    setSwiping,
    setSwipeXY,
  } = useAppContext();
  const { toastPromise } = useToast();
  const [todoCollapsed, setTodoCollapsed] = useState(false);
  const [calendarCollapsed, setCalendarCollapsed] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [slideScope, slideAnimate] = useAnimate();
  const slideRight = useMotionValue(0);
  const slideClasses = clsx(`flex fixed inset-y-0 right-0 pointer-events-none`);
  const dialogClasses = clsx(
    `transition ease-in-out delay-150 pointer-events-auto max-w-md overflow-y-auto`
  );
  const modeClasses =
    'flex flex-col items-center justify-center text-center text-gray-800 dark:text-white';
  const holidayState = (localStorage.getItem('holiday') || 'true') === 'true';

  const slideSwipeHandlers = useSwipeable({
    onSwipeStart: (eventData) => {
      if (eventData.dir === 'Right') {
        setSwiping(true);
      }
    },
    onSwiping: (eventData) => {
      if (eventData.deltaX > 0) {
        setSwipeXY({ x: eventData.absX, y: 0 });
        slideRight.set(-eventData.deltaX);
      }
    },
    onSwiped: (eventData) => {
      if (eventData.deltaX > 100) setSlideOpen(false);
      else {
        slideAnimate(slideScope.current, {
          right: 0,
        });
      }
      setSwiping(false);
    },
  });

  async function handleLogout(event: React.SyntheticEvent) {
    event.preventDefault();

    setIsLoading(true);
    try {
      setSlideOpen(false);
      await Auth.signOut();
      setAuthenticated(false);
    } catch (error) {
      if (error instanceof Error) {
        console.log(error);
      }
    } finally {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }

  async function todoGroupChangeHandler(groupIds: string[]) {
    try {
      setDataLoading(true);

      /* 이미 있는 그룹에서 가져오는것이므로 동시처리 가능 */
      await Promise.all([
        await setVisibleGroups(groupIds),
        await loadDay(groupIds)
      ]);

    } catch (error) {
    } finally {
      setDataLoading(false);
    }
  }

  async function visibleGroupsHandler(
    event: React.ChangeEvent<HTMLInputElement>,
    groupId: string
  ) {
    let newVisibleGroups: string[] = [];

    if (event.target.checked) newVisibleGroups = visibleGroups.concat(groupId);
    else newVisibleGroups = visibleGroups.filter((group) => group !== groupId);

    await toastPromise(
      todoGroupChangeHandler(newVisibleGroups),
      '그룹 정보를 저장중입니다.',
      '그룹 정보가 저장되었습니다.'
    );
  }

  async function holidayChangeHandler(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setShowHoliday(event.target.checked);
    if (event.target.checked)
      setCalendar({
        type: CalendarDayActionType.SET_HOLIDAYS,
        value: await searchHolidays(getYear(calendar.currentDate)),
      });
    else setCalendar({ type: CalendarDayActionType.SET_HOLIDAYS });
  }

  function getTouchGroupList() {
    return (
      <TouchGroupList groupOpen={openUpdateModal} setGroupOpen={setOpenUpdateModal} />
    )
  }
  return (
    <Transition.Root show={slideOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setSlideOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-500"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <AnimatePresence>
              <motion.div
                ref={slideScope}
                className={slideClasses}
                initial={{ right: 0 }}
                exit={{ right: 0 }}
                style={{
                  right: slideRight,
                }}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel
                    {...slideSwipeHandlers}
                    className={dialogClasses}
                    style={{
                      width: clientWindow.width - 100,
                    }}
                  >
                    <form className="flex h-full pb-16 flex-col divide-y ease-out overflow-y-auto divide-gray-400 dark:divide-gray-600 bg-white dark:bg-[#363a45] shadow-xl">
                      <div className="p-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="flex items-center text-lg font-semibold leading-6 text-gray-800 dark:text-white">
                            <img
                              className="w-6 h-6 mr-1"
                              src="./android-chrome-192x192.png"
                              alt="Todo App"
                            />
                            Touch Todos
                          </Dialog.Title>
                          <div className="flex gap-2 items-center">
                            <button type="button" onClick={() => themeSwitch()}>
                              <Icon
                                name={theme === 'dark' ? 'sun' : 'moon'}
                                className="text-gray-800 dark:text-white"
                                size="md"
                              />
                            </button>
                            <button
                              type="button"
                              className=""
                              onClick={(e) => handleLogout(e)}
                            >
                              <Icon
                                name="outlineLogout"
                                className="text-gray-800 dark:text-white"
                                size="md"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="p-4">
                        <div className="flex justify-around">
                          <div
                            className={clsx(modeClasses, {
                              'stroke-2 font-bold': mode === 'MONTH',
                            })}
                            onClick={async () => {
                              setMode('MONTH');
                              if (mode === 'WEEK' || mode === 'LIST') await refereshDays('MONTH');
                            }}
                          >
                            <Icon
                              size="md"
                              name={
                                mode === 'MONTH'
                                  ? 'calendarDays'
                                  : 'outlineCalendarDays'
                              }
                            />
                            <span
                              className={clsx('text-sm', {
                                'text-gray-400': mode !== 'MONTH',
                              })}
                            >
                              월
                            </span>
                          </div>
                          <div
                            className={clsx(modeClasses, {
                              'stroke-2 font-bold': mode === 'DAY',
                            })}
                            onClick={async () => {
                              setMode('DAY');
                              if (mode === 'WEEK' || mode === 'LIST') await refereshDays('DAY');
                            }}
                          >
                            <Icon
                              size="md"
                              name={
                                mode === 'DAY'
                                  ? 'calendarTodo'
                                  : 'outlineCalendarTodo'
                              }
                            />
                            <span
                              className={clsx('text-sm', {
                                'text-gray-400': mode !== 'DAY',
                              })}
                            >
                              일
                            </span>
                          </div>
                          <div
                            className={clsx(modeClasses, {
                              'stroke-2 font-bold': mode === 'WEEK',
                            })}
                            onClick={async () => {
                              setMode('WEEK');
                              await refereshDays('WEEK');
                            }}
                          >
                            <Icon
                              size="md"
                              name={
                                mode === 'WEEK'
                                  ? 'calendarWeeks'
                                  : 'outlineCalendarWeeks'
                              }
                            />
                            <span
                              className={clsx('text-sm', {
                                'text-gray-400': mode !== 'WEEK',
                              })}
                            >
                              주
                            </span>
                          </div>
                          <div
                            className={clsx(modeClasses, {
                              'stroke-2 font-bold': mode === 'LIST',
                            })}
                            onClick={() => setMode('LIST')}
                          >
                            <Icon
                              size="md"
                              name={mode === 'LIST' ? 'list' : 'outlineList'}
                            />
                            <span
                              className={clsx('text-sm', {
                                'text-gray-400': mode !== 'LIST',
                              })}
                            >
                              목록
                            </span>
                          </div>
                          <div className="inline-block w-[1px] self-stretch bg-gray-400"></div>
                          <div
                            className={clsx(modeClasses, {
                              'stroke-2 font-bold': mode === 'CHECK',
                            })}
                            onClick={() => setMode('CHECK')}
                          >
                            <Icon size="md" name={mode === 'CHECK' ? 'circleCheck' : 'outlineCircleCheck'} />
                            <span className={clsx('text-sm', {
                              'text-gray-400': mode !== 'CHECK',
                            })}>할일</span>
                          </div>
                        </div>
                      </div>
                      <div className="p-4 text-gray-800 dark:text-white">
                        <div className="space-y-2 text-sm">
                          <div className="flex justify-between items-center pb-2 gap-x-2 text-gray-800 dark:text-gray-400">
                            <div className="flex items-center gap-x-2">
                              할 일 그룹
                              <Icon
                                name={
                                  todoCollapsed
                                    ? 'outlineArrowDown'
                                    : 'outlineArrowUp'
                                }
                                size="sm"
                                onClick={() =>
                                  setTodoCollapsed((prev) => !prev)
                                }
                              />
                            </div>
                            <Icon
                              name="outlinePlus"
                              size="sm"
                              onClick={() => setOpenGroupModal(true)}
                            />
                          </div>
                          <motion.div
                            className="relative flex flex-col gap-y-2 items-start"
                            variants={{
                              visible: { display: 'flex' },
                              hidden: { display: 'none' },
                            }}
                            animate={todoCollapsed ? 'hidden' : 'visible'}
                          >
                            {dataLoading && (
                              <AnimatePresence mode={'wait'}>
                                <motion.div
                                  className={clsx(
                                    'absolute flex w-full h-full justify-center items-center z-20'
                                  )}
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{
                                    opacity: 0.5,
                                    transition: { duration: 0.3 },
                                  }}
                                >
                                  <LoadingSvg />
                                </motion.div>
                              </AnimatePresence>
                            )}
                            {group.items.map((g) => {
                              const groupStyle = `focus:ring-0 focus:ring-offset-0`;
                              return (
                                <div
                                  key={g.groupId}
                                  className={clsx('flex h-6 items-center')}
                                >
                                  <input
                                    id={g.groupId}
                                    name={g.groupId}
                                    type="checkbox"
                                    checked={visibleGroups.includes(g.groupId)}
                                    onChange={(event) =>
                                      visibleGroupsHandler(event, g.groupId)
                                    }
                                    style={{
                                      color: g.color,
                                      border: `2px solid ${g.color}`,
                                    }}
                                    className={clsx(
                                      'h-6 w-6 rounded-lg',
                                      groupStyle
                                    )}
                                  />
                                  <label htmlFor={g.groupId} className="ml-3">
                                    {g.name}
                                  </label>
                                </div>
                              );
                            })}
                          </motion.div>
                        </div>
                      </div>
                      <div className="p-4 text-gray-800 dark:text-white">
                        <div className="space-y-2 text-sm">
                          <div className="flex justify-between items-center pb-2 gap-x-2 text-gray-800 dark:text-gray-400">
                            <div className="flex items-center gap-x-2">
                              구독 캘린더
                              <Icon
                                name={
                                  calendarCollapsed
                                    ? 'outlineArrowDown'
                                    : 'outlineArrowUp'
                                }
                                size="sm"
                                onClick={() =>
                                  setCalendarCollapsed((prev) => !prev)
                                }
                              />
                            </div>
                          </div>
                          <motion.div
                            className="relative flex flex-col gap-y-2 items-start"
                            variants={{
                              visible: { display: 'flex' },
                              hidden: { display: 'none' },
                            }}
                            animate={calendarCollapsed ? 'hidden' : 'visible'}
                          >
                            <div className="flex h-6 items-center">
                              <input
                                id="vacation"
                                name="vacation"
                                type="checkbox"
                                onChange={holidayChangeHandler}
                                defaultChecked={holidayState}
                                className={clsx(
                                  'h-6 w-6 rounded-lg border-2 border-red-500 text-red-500 focus:ring-0 focus:ring-offset-0'
                                )}
                              />
                              <label htmlFor="vacation" className="ml-3">
                                대한민국 기념일
                              </label>
                            </div>
                          </motion.div>
                        </div>
                      </div>
                      <div className="fixed flex w-full h-16 text-sm bottom-0 justify-between px-4 py-4 text-gray-800 dark:text-white bg-gray-100 dark:bg-[#16181f]">
                        <Button
                          type="none"
                          text="닫기"
                          onClick={() => setSlideOpen(false)}
                          disabled={dataLoading}
                        />
                        <Button
                          type="none"
                          text="그룹 관리"
                          onClick={() => setOpenUpdateModal(true)}
                          disabled={dataLoading}
                        />
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <GroupCreateModal open={openGroupModal} setOpen={setOpenGroupModal} />
        <GroupUpdateModal children={getTouchGroupList()} open={openUpdateModal} setOpen={setOpenUpdateModal} />
      </Dialog>
    </Transition.Root>
  );
}
