import { API } from "aws-amplify";

export type updateUserItems = {
    name: string;
    color: string;
}

export interface IUser {
    userId: string;
    visibleGroups: string[];
}


export function createUser() {
    return API.put("users", "/users", {});
}

export function getUser(): Promise<IUser> {
    return API.get("users", "/users", {});
}

export function updateUser(visibleGroups: string[]): Promise<IUser> {
    return API.post("users", "/users", {
        body: {
            visibleGroups
        }
    });
}