import clsx from 'clsx';
import { IconType } from 'react-icons/lib';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import {
  HiCheckCircle,
  HiOutlineCheckCircle,
  HiMenu,
  HiOutlineMenu,
  HiOutlineX,
  HiSun,
  HiOutlineSun,
  HiMoon,
  HiOutlineMoon,
  HiOutlineLogout,
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiPlus,
  HiOutlinePlus,
  HiArrowLeft,
  HiOutlineArrowLeft,
  HiArrowRight,
  HiOutlineArrowRight,
  HiChevronDown,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiCheck,
  HiOutlineCheck,
  HiCollection,
  HiOutlineCollection,
  HiCalendar,
  HiOutlineCalendar,
  HiDocumentText,
  HiOutlineDocumentText,
  HiChevronLeft,
  HiChevronRight
} from 'react-icons/hi';
import {
  HiCalendarDays,
  HiOutlineCalendarDays
} from 'react-icons/hi2'
import {
  RiCalendar2Line,
  RiCalendar2Fill,
  RiCalendarTodoLine,
  RiCalendarTodoFill,
  RiFileList2Line,
  RiFileList2Fill
} from 'react-icons/ri'

interface IIconProps {
  name: string;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  onClick?: () => void;
}

interface IIconList {
  [key: string]: IconType;
}
/**
 * 필요한 아이콘이 생기면 직접 import 후 name을 지정 후 추가해서 사용
 */
const iconList: IIconList = {
  circleCheck: HiCheckCircle,
  outlineCircleCheck: HiOutlineCheckCircle,
  outlineLoading: AiOutlineLoading3Quarters,
  outlineX: HiOutlineX,
  sun: HiSun,
  outlineSun: HiOutlineSun,
  moon: HiMoon,
  outlineMoon: HiOutlineMoon,
  outlineLogout: HiOutlineLogout,
  menu: HiMenu,
  outlineMenu: HiOutlineMenu,
  list: RiFileList2Fill,
  outlineList: RiFileList2Line,
  calendarWeeks: RiCalendar2Fill,
  outlineCalendarWeeks: RiCalendar2Line,
  calendarDays: HiCalendarDays,
  outlineCalendarDays: HiOutlineCalendarDays,
  calendarTodo: RiCalendarTodoFill,
  outlineCalendarTodo: RiCalendarTodoLine,
  outlineArrowUp: HiOutlineChevronUp,
  outlineArrowDown: HiOutlineChevronDown,
  plus: HiPlus,
  outlinePlus: HiOutlinePlus,
  arrowRight: HiArrowRight,
  outlineArrowRight: HiOutlineArrowRight,
  arrowLeft: HiArrowLeft,
  outlineArrowLeft: HiOutlineArrowLeft,
  chevronDown: HiChevronDown,
  chevronLeft: HiChevronLeft,
  chevronRight: HiChevronRight,
  check: HiCheck,
  outlineCheck: HiOutlineCheck,
  collection: HiCollection,
  outlineCollection: HiOutlineCollection,
  calendar: HiCalendar,
  outlineCalendar: HiOutlineCalendar,
  documentText: HiDocumentText,
  outlineDocumentText: HiOutlineDocumentText
};

export default function Icon({
  name,
  size = 'md',
  className = '',
  onClick,
}: IIconProps) {
  const iconStyles = clsx(
    className,
    {
      'w-3 h-3': size === 'xs',
      'w-4 h-4': size === 'sm',
      'w-6 h-6': size === 'md',
      'w-8 h-8': size === 'lg',
      'w-10 h-10': size === 'xl'
    },
    { 'cursor-pointer': onClick }
  );

  const Icon = iconList[name];

  if (!Icon) return null;

  return <Icon className={iconStyles} onClick={onClick} />;
}
