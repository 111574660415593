import { useEffect, useRef, useState } from 'react';
import Calendar from '../components/Calendar';
import {
  CalendarDayActionType,
  useCalendarDays,
} from '../hooks/useCalendarDays';
import {
  motion,
  useMotionValue,
  useAnimate,
  AnimatePresence,
} from 'framer-motion';
import DayHeader from '../components/DayHeader';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { AppMode, useAppContext } from '../context/useAppContext';
import Header from '../components/Header';
import LoadingSvg from '../components/LoadingSvg';
import { getDateFormat, getYear, isSameDate } from '../utils/date';
import SlideOvers from '../components/SlideOvers';
import Icon from '../components/Icon';
import FullModal from '../components/FullModal';
import TodoCreateForm from '../components/TodoCreateForm';
import { ITodoItem } from '../api/todos';
import clsx from 'clsx';
import TodoUpateForm from '../components/TodoUpdateForm';
import TodoInfoForm from '../components/TodoInfoForm';
import TodoListHeader from '../components/TodoListHeader';
import TouchSingleList, {
  TouchSingleListHandle,
} from '../components/TouchSingleList';
import TouchCheckList, { TouchCheckListHandler } from '../components/TouchCheckList';
import TouchTodoList, { TouchTodoListHandler } from '../components/TouchTodoList';
import { TodoItem } from '../hooks/useTodoDays';
import CheckListHeader from '../components/CheckListHeader';

export default function Home() {
  const {
    dataLoading,
    group,
    clientWindow,
    setSlideOpen,
    swiping,
    setSwiping,
    setSwipeXY,
    mode,
    setMode,
    setModalOpen,
  } = useAppContext();
  const { width, height } = clientWindow;
  const appSwiped = useRef(false);
  const { calendar, setCalendar, selectDay, loadDay, changeWeek, changeMonth, refereshDays } =
    useCalendarDays();
  const [isCalendarRef, setIsCalendarRef] = useState(false);
  const [containerScope, containerAnimate] = useAnimate();
  const [calendarScope, calendarAnimate] = useAnimate();
  const touchSingleRef = useRef<TouchSingleListHandle>(null);
  const touchListRef = useRef<TouchTodoListHandler>(null);
  const touchCheckRef = useRef<TouchCheckListHandler>(null);
  const [touchScope, touchAnimate] = useAnimate();
  const [dragDir, setDragDir] = useState('');

  // todo form state
  const [formType, setFormType] = useState<'create' | 'update' | 'info'>(
    'create'
  );
  const [updateForm, setUpdateForm] = useState({
    groupId: '',
    todoId: '',
    date: '',
    title: '',
    content: '',
    completed: 'false',
    createdAt: '',
    updatedAt: '',
    isHoliday: 'true',
    updateCallback: (date: string, item: any) => null,
    deleteCallback: (date: string, todoId: string) => null,
    deleteWithUpdate: true,
  });
  const [createCallback, setCreateCallback] = useState<any>({
    handler: (date: string, todoId: string) => null
  });

  // app position
  const [saveHeight, setSaveHeight] = useState(height);

  // calendar position
  const [calendarHeight, setCalendarHeight] = useState(height);
  const cHeight = useMotionValue(mode === 'MONTH' ? height : height * 0.4);
  const arrowL = useMotionValue(0);
  const arrowR = useMotionValue(0);

  // touch position
  const tHeight = useMotionValue(mode === 'MONTH' ? 0 : height - height * 0.4);

  const swipeRightEndhandlers = useSwipeable({
    preventScrollOnSwipe: false,
    onSwipeStart: (eventData) => {
      if (dataLoading) return;

      const { dir, initial } = eventData;

      if (
        calendarScope.current &&
        calendarScope.current.contains(eventData.event.target)
      ) {
        setIsCalendarRef(true);
      }

      setDragDir(dir);

      if (dir === 'Left') {
        if (width - 25 < initial[0]) {
          setSlideOpen(true);
          setSwiping(true);
          if (width > 548) setSwipeXY({ x: width - (width - 448), y: 0 });
          else setSwipeXY({ x: width - 100, y: 0 });
        }
      } else if (dir === 'Up' || dir === 'Down') {
        if (
          (document.querySelector('.rtc-body') as any).contains(
            eventData.event.target
          )
        ) {
          appSwiped.current = false;
        } else {
          appSwiped.current = true;
          setSwipeXY({ x: initial[0], y: initial[1] });
        }
      } else {
      }
    },
    onSwiping: (eventData) => {
      if (dataLoading) return;

      if (isCalendarRef && (dragDir === 'Left' || dragDir === 'Right')) {
        if (swiping) {
          if (width > 548) {
            if (eventData.absX < 448) {
              setSwipeXY({ x: width - (width - 448) - eventData.absX, y: 0 });
            }
          } else {
            if (eventData.absX < width - 100)
              setSwipeXY({ x: width - 100 - eventData.absX, y: 0 });
          }
        } else {
          if (dragDir === 'Left') arrowR.set(-1 * (eventData.deltaX / 100));
          if (dragDir === 'Right') arrowL.set(eventData.deltaX / 100);
        }
      } else {
        if (appSwiped.current) onDragSizeHandler(eventData);
      }
    },
    onSwiped: async (eventData) => {
      const { deltaX, deltaY } = eventData;
      // App Swiping
      if ((dragDir === 'Up' || dragDir === 'Down') && appSwiped.current) {
        if (dragDir === 'Up' && cHeight.get() < 200) {
          onAnimateHandler('WEEK');
          setMode('WEEK');
        }

        if (mode === 'MONTH') {
          if (deltaY < -200) {
            onAnimateHandler('WEEK');
            setMode('WEEK');
            await refereshDays('WEEK');
          } else if (deltaY < -40) {
            onAnimateHandler('DAY');
            setMode('DAY');
          } else {
            onAnimateHandler('MONTH');
          }
        } else if (mode === 'DAY') {
          if (deltaY > 40) {
            onAnimateHandler('MONTH');
            setMode('MONTH');
          } else if (deltaY < -40) {
            onAnimateHandler('WEEK');
            setMode('WEEK');
            await refereshDays('WEEK');
          } else {
            onAnimateHandler('DAY');
          }
        } else if (mode === 'WEEK') {
          if (deltaY > 120) {
            onAnimateHandler('MONTH');
            setMode('MONTH');
            await refereshDays('MONTH');
          } else if (deltaY > 40) {
            onAnimateHandler('DAY');
            setMode('DAY');
            await refereshDays('DAY');
          } else {
            onAnimateHandler('WEEK');
          }
        }
      }

      // Slide Swiping
      if (eventData.absX > 100) setSwipeXY({ x: 0, y: 0 });
      else setSlideOpen(false);

      // Calendar Swiping
      if (
        !swiping &&
        isCalendarRef &&
        ((dragDir === 'Left' && deltaX < -40) ||
          (dragDir === 'Right' && deltaX > 40))
      ) {
        // Load Calendar, Todo, Holiday
        arrowL.set(0);
        arrowR.set(0);
        if (mode === 'WEEK') await changeWeek(dragDir === 'Left' ? 'next' : 'prev');
        else await changeMonth(dragDir === 'Left' ? 'next' : 'prev');
      }

      setSwiping(false);
      setSaveHeight(cHeight.get());
      setCalendarHeight(cHeight.get());
      setIsCalendarRef(false);
      arrowL.set(0);
      arrowR.set(0);
      touchSingleRef.current?.refereshLayout();
    },
  });

  function getHeight(): number {
    if (height > 768) {
      return height * 0.4;
    }

    return height * 0.5;
  }
  const onAnimateHandler = (mode: AppMode, duration = 0.3) => {
    if (mode === 'MONTH') {
      containerAnimate(containerScope.current, {
        height: height,
      });
      touchAnimate(touchScope.current, {
        height: 0,
      });
      cHeight.set(height);
      tHeight.set(0);
    }

    if (mode === 'DAY') {
      containerAnimate(containerScope.current, {
        height: getHeight(),
      });
      cHeight.set(getHeight());
      tHeight.set(height - cHeight.get());
    }

    if (mode === 'WEEK') {
      containerAnimate(containerScope.current, {
        height: 115,
      });
      cHeight.set(115);
      tHeight.set(height - cHeight.get());
    }
  };

  const onDragSizeHandler = (event: SwipeEventData) => {
    if (dragDir === 'Left' || dragDir === 'Right') return;

    let newHeight = saveHeight + event.deltaY;
    if (newHeight > 115 && newHeight < height) {
      cHeight.set(newHeight);
      tHeight.set(height - cHeight.get());
      setCalendarHeight(newHeight);
      touchSingleRef.current?.refereshLayout();
    }
  };

  useEffect(() => {
    if (appSwiped.current) {
      appSwiped.current = false;
      return;
    }

    onAnimateHandler(mode, 0.5);

    if (mode === 'MONTH') {
      setCalendarHeight(height);
      touchSingleRef.current?.refereshLayout();
    } else if (mode === 'DAY') {
      setCalendarHeight(cHeight.get());
      touchSingleRef.current?.refereshLayout();
    } else {
      touchSingleRef.current?.refereshLayout();
    }

    setSaveHeight(cHeight.get());
  }, [mode]);

  useEffect(() => {
    if (mode === 'MONTH') {
      cHeight.set(height);
      tHeight.set(0);
      setCalendarHeight(height);
      touchSingleRef.current?.refereshLayout();
    } else if (mode === 'DAY') {
      cHeight.set(getHeight());
      tHeight.set(height - cHeight.get());
      setCalendarHeight(cHeight.get());
      touchSingleRef.current?.refereshLayout();
    } else {
      touchSingleRef.current?.refereshLayout();
    }
  }, [height]);

  function getTodoForm(type: 'create' | 'update' | 'info') {
    if (type === 'info') {
      return (
        <TodoInfoForm
          date={updateForm.date}
          title={updateForm.title}
          isHoliday={updateForm.isHoliday}
        />
      );
    }

    if (type === 'create') {
      if (!group.items) return <h1>그룹 정보가 없습니다.</h1>;
      return (
        <TodoCreateForm
          selectGroupId={group?.items[0]?.groupId}
          selectedDay={calendar.selected.day}
          createCallback={createCallback.handler}
        />
      );
    }

    if (type === 'update') {
      return (
        <TodoUpateForm
          selectGroupId={updateForm.groupId}
          selectedDay={updateForm.date}
          todoId={updateForm.todoId}
          title={updateForm.title}
          content={updateForm.content}
          completed={updateForm.completed === 'true' ? true : false}
          createdAt={updateForm.createdAt}
          updatedAt={updateForm.updatedAt}
          updateCallback={updateForm.updateCallback}
          deleteCallback={updateForm.deleteCallback}
          deleteWithUpdate={updateForm.deleteWithUpdate}
        />
      );
    }

    return <h1>선택한 정보가 없습니다.</h1>;
  }

  return (
    <div
      className="w-full h-full text-gray-800 dark:text-white dark:bg-slate-900"
      {...swipeRightEndhandlers}
    >
      {/* Check List View */}
      {mode === 'CHECK' && (
        <>
          <motion.div className="relative flex flex-col" ref={containerScope}>
            <CheckListHeader calendar={calendar} refereshDays={refereshDays} />
          </motion.div>
          <motion.div className="relative w-full h-[calc(100%_-_50px)]">
            {dataLoading && (
              <AnimatePresence mode={'wait'}>
                <motion.div
                  className={clsx(
                    'absolute opacity-50 flex w-full h-full justify-center items-center bg-gray-100/50 dark:bg-slate-800/60 z-20'
                  )}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0.5, transition: { duration: 0.3 } }}
                >
                  <LoadingSvg />
                </motion.div>
              </AnimatePresence>
            )}
            <TouchCheckList
              ref={touchCheckRef}
              calendar={calendar}
              setCalendar={setCalendar}
              setFormType={setFormType}
              setUpdateForm={setUpdateForm}
              setCreateCallback={setCreateCallback}
            />
          </motion.div>
        </>
      )}

      {/* List View */}
      {mode === 'LIST' && (
        <>
          <motion.div className="relative flex flex-col" ref={containerScope}>
            <TodoListHeader calendar={calendar} />
          </motion.div>
          <motion.div className="relative w-full h-[calc(100%_-_50px)]">
            {dataLoading && (
              <AnimatePresence mode={'wait'}>
                <motion.div
                  className={clsx(
                    'absolute opacity-50 flex w-full h-full justify-center items-center bg-gray-100/50 dark:bg-slate-800/60 z-20'
                  )}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0.5, transition: { duration: 0.3 } }}
                >
                  <LoadingSvg />
                </motion.div>
              </AnimatePresence>
            )}
            <TouchTodoList
              ref={touchListRef}
              calendar={calendar}
              setCalendar={setCalendar}
              setFormType={setFormType}
              setUpdateForm={setUpdateForm}
              setCreateCallback={setCreateCallback}
            />
          </motion.div>
        </>
      )}

      {/* DAY, MONTH View */}
      {(mode === 'DAY' || mode === 'MONTH' || mode === 'WEEK') && (
        <>
          <motion.div
            className="relative flex flex-col"
            ref={containerScope}
            style={{
              height: cHeight,
            }}
          >
            <Header calendar={calendar} changeWeek={changeWeek} changeMonth={changeMonth} refereshDays={refereshDays} />
            <DayHeader />
            <AnimatePresence mode={'wait'}>
              <motion.div
                ref={calendarScope}
                key="box"
                className="relative w-full h-full"
                variants={{
                  visible: { opacity: 1, transition: { duration: 1 } },
                  hidden: { opacity: 0, transition: { duration: 1 } },
                }}
                initial="visible"
                exit="hidden"
              >
                {dataLoading && (
                  <AnimatePresence mode={'wait'}>
                    <motion.div
                      className={clsx(
                        'absolute opacity-50 flex w-full h-full justify-center items-center z-20'
                      )}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0.5, transition: { duration: 0.3 } }}
                    >
                      <LoadingSvg />
                    </motion.div>
                  </AnimatePresence>
                )}
                <div className="absolute w-full h-full">
                  <motion.div
                    className="absolute left-8 w-full h-full flex justify-start items-center pointer-events-none z-10"
                    style={{
                      opacity: arrowL,
                    }}
                  >
                    <Icon
                      className="rounded-full p-1 text-gray-800 dark:text-white bg-gray-300/70 dark:bg-slate-600/70"
                      name="arrowLeft"
                      size="xl"
                    />
                  </motion.div>
                  <motion.div
                    className="absolute right-8 w-full h-full flex justify-end items-center pointer-events-none z-10"
                    style={{
                      opacity: arrowR,
                    }}
                  >
                    <Icon
                      className="rounded-full p-1 text-gray-800 dark:text-white bg-gray-300/70 dark:bg-slate-600/70"
                      name="arrowRight"
                      size="xl"
                    />
                  </motion.div>
                </div>
                {/* 높이정보를 항상 전달 (헤더높이를 빼고 전달) */}
                <Calendar
                  calendar={calendar}
                  selectDay={selectDay}
                  height={calendarHeight - 75}
                />
              </motion.div>
            </AnimatePresence>
          </motion.div>
          <motion.div
            className="relative"
            ref={touchScope}
            variants={{
              visible: { opacity: 1, transition: { duration: 0.3 } },
              hidden: { opacity: 0 },
            }}
            initial="visible"
            exit="hidden"
            style={{
              height: tHeight,
            }}
          >
            {mode === 'DAY' && dataLoading && (
              <motion.div className="absolute flex w-full h-full justify-center items-center bg-gray-100/50 dark:bg-slate-800/60 z-20">
                <LoadingSvg />
              </motion.div>
            )}
            <TouchSingleList
              ref={touchSingleRef}
              calendar={calendar}
              setCalendar={setCalendar}
              selectDay={selectDay}
              setFormType={setFormType}
              setUpdateForm={setUpdateForm}
              setCreateCallback={setCreateCallback}
            />
          </motion.div>
        </>
      )}
      <SlideOvers
        calendar={calendar}
        setCalendar={setCalendar}
        loadDay={loadDay}
        refereshDays={refereshDays}
      />
      {mode !== 'CHECK' && !isSameDate(calendar.today, calendar.selected.day) && (
        mode === 'LIST' ? <button
          className={clsx(
            'absolute bottom-6 left-0 right-0 m-auto w-16 px-4 py-1 z-10 border drop-shadow-2xl text-sm text-center rounded-full',
            'bg-white border-gray-200',
            'dark:bg-slate-700 dark:border-slate-600'
          )}
          onClick={async () => {
            await touchListRef.current?.setCurrentMonth();
          }}
        >
          금월
        </button> : <button
          className={clsx(
            'absolute bottom-6 left-0 right-0 m-auto w-16 px-4 py-1 z-10 border drop-shadow-2xl text-sm text-center rounded-full',
            'bg-white border-gray-200',
            'dark:bg-slate-700 dark:border-slate-600'
          )}
          onClick={async () => {
            await selectDay(new Date());
          }}
        >
          오늘
        </button>
      )}
      <div
        className={clsx(
          'absolute bottom-4 right-4 w-12 h-12 z-10 border drop-shadow-2xl flex justify-center items-center rounded-full',
          'bg-white border-gray-200 shadow-gray-200',
          'dark:bg-slate-700 dark:border-slate-600 dark:shadow-slate-600'
        )}
        onClick={() => {
          setFormType('create');

          if (mode === 'LIST') {
            touchListRef.current?.setCreateCallbackHandler();
          } else if (mode === 'CHECK') {
            setCreateCallback({
              handler: (date: string, item: ITodoItem) => {
                touchCheckRef.current?.refereshTodoCheckList(date, item);
              }
            });

          } else {
            setCreateCallback({
              handler: (date: string, item: any) => {
                setCalendar({
                  type: CalendarDayActionType.UPDATE_EVENTS,
                  date,
                  item,
                });
              }
            });
          }

          setModalOpen(true);
        }}
      >
        <Icon className="text-gray-800 dark:text-white" name="plus" size="md" />
      </div>
      <FullModal children={getTodoForm(formType)} />
    </div>
  );
}
