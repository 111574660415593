import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../context/useAppContext';
import { useFormFields } from '../hooks/useFormFields';
import { useToast } from '../hooks/useToast';
import { deleteTodo, updateTodo } from '../api/todos';
import { getDateFormat, isSameDate } from '../utils/date';
import clsx from 'clsx';
import Icon from './Icon';
import ListBox from './ListBox';
import {
  CalendarAction,
  CalendarDayActionType,
} from '../hooks/useCalendarDays';
import Button from './Button';

export type TodoUpdateForm = {
  selectGroupId: string;
  selectedDay: string;
  todoId: string;
  title: string;
  content: string;
  completed: boolean;
  createdAt: string;
  updatedAt: string;
  updateCallback: (date: string, item: any) => void;
  deleteCallback: (date: string, todoId: string) => void;
  deleteWithUpdate?: boolean;
};

export default function TodoUpateForm({
  selectGroupId,
  selectedDay,
  todoId,
  title,
  content,
  completed,
  createdAt,
  updatedAt,
  updateCallback,
  deleteCallback,
  deleteWithUpdate = true
}: TodoUpdateForm) {
  const { theme, group, setModalOpen } = useAppContext();
  const createRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState(selectGroupId);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useFormFields({
    groupId: selected,
    todoId: todoId,
    date: selectedDay,
    title,
    content,
    completed,
    createdAt,
    updatedAt,
    updateDate: selectedDay,
    updateCallback,
    deleteCallback,
  });
  const { toastPromise } = useToast();

  const inputContainerClasses = clsx('flex items-center border-box mb-4');
  const inputClasses = clsx(
    'text-md flex-grow border-l-0 border-t-0 border-r-0 border-b border-b-gray-600 bg-white dark:bg-slate-800 focus:ring-0 focus:ring-offset-0 co'
  );

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    const result = await toastPromise(
      updateTodo({
        groupId: selected,
        todoId: todoId,
        date: getDateFormat(selectedDay, 'YYYYMMDD'),
        updateDate: getDateFormat(fields.updateDate, 'YYYYMMDD'),
        title: fields.title,
        content: fields.content,
        completed: fields.completed,
      }),
      '할 일을 수정중입니다.',
      '할 일이 수정되었습니다.'
    );

    if (!isSameDate(selectedDay, fields.updateDate) && deleteWithUpdate) {
      deleteCallback(getDateFormat(selectedDay, 'YYYY-MM-DD'), todoId)
    }
    updateCallback(getDateFormat(fields.updateDate, 'YYYY-MM-DD'), result);

    setIsLoading(false);
    setModalOpen(false);
  }

  async function todoDeleteHandler(event: React.SyntheticEvent) {
    setIsLoading(true);

    await toastPromise(
      deleteTodo({
        todoId: todoId,
        date: getDateFormat(selectedDay, 'YYYYMMDD')
      }),
      '할 일을 삭제중입니다.',
      '할 일이 삭제되었습니다.'
    );

    deleteCallback(getDateFormat(fields.date, 'YYYY-MM-DD'), todoId)

    setIsLoading(false);
    setModalOpen(false);
  }

  useEffect(() => {
    // if (createRef.current) createRef.current.focus();
  }, []);

  return (
    <form className="flex flex-col flex-1 p-4 overflow-auto" onSubmit={handleSubmit}>
      <div className="flex justify-between items-center mb-4">
        <Icon
          name="arrowLeft"
          size="md"
          className="text-gray-900 dark:text-white"
          onClick={() => setModalOpen(false)}
        />
        <h1 className="text-base font-bold py-2 text-gray-900 dark:text-gray-200">
          할 일 편집
        </h1>
        <Button
          type="none"
          buttonType='submit'
          text="저장"
          classNames="text-base font-semibold"
          disabled={isLoading}
        />
      </div>
      <div className={inputContainerClasses}>
        <input
          id="title"
          name="title"
          type="text"
          className={inputClasses}
          value={fields.title}
          onChange={setFields}
          placeholder="제목 입력"
          ref={createRef}
          required
          autoFocus
        />
      </div>
      <div className={inputContainerClasses}>
        <div className="flex justify-center w-[40px]">
          <Icon
            name="calendar"
            size="sm"
            className="text-gray-900 dark:text-white"
          />
        </div>
        <input
          id="updateDate"
          name="updateDate"
          type="date"
          className={inputClasses}
          style={{
            colorScheme: theme === 'dark' ? 'dark' : 'light'
          }}
          value={fields.updateDate}
          onChange={setFields}
          required
          pattern="\d{4}-\d{2}-\d{2}"
        />
      </div>
      <div className={inputContainerClasses}>
        <div className="flex justify-center w-[40px]">
          <Icon
            name="collection"
            size="sm"
            className="text-gray-900 dark:text-white"
          />
        </div>
        <ListBox
          groups={group.items}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className={inputContainerClasses}>
        <div className="flex justify-center w-[40px]">
          <Icon
            name="documentText"
            size="sm"
            className="text-gray-900 dark:text-white"
          />
        </div>
        <textarea
          rows={4}
          name="content"
          id="content"
          className="w-full rounded-md border-0 py-1.5 min-h-40 max-h-40 bg-gray-200 dark:bg-slate-700 text-gray-900 dark:text-white shadow-sm focus:ring-0 focus:ring-offset-0"
          value={fields.content}
          onChange={setFields}
          placeholder="내용을 입력해주세요."
        />
      </div>
      <Button
        type='none'
        buttonType='button'
        classNames='w-full'
        containerClassNames="w-full mt-auto mb-2 text-red-500 border border-red-500 rounded-md py-2"
        text="할 일 삭제"
        size='lg'
        disabled={isLoading}
        onClick={todoDeleteHandler}
      />
    </form>
  );
}
