import { Auth } from 'aws-amplify';
import {
  useReducer,
  createContext,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { getAnniversaries, getHolidays, getDataGoHolidays, HolidaysGroup, CreateHolidayParmas, createHolidays, IHolidayItem } from '../api/holiday';
import { getYear } from '../utils/date';
import { IGroup, getGroup } from '../api/groups';
import { getUser, updateUser } from '../api/users';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

export enum AppActionType {
  ON_LOAD = 'onLoad', // 최초 상태 로드
  SET_AUTHENTICATED = 'setAuthenticated', // 인증 여부 상태
  SET_LOADING = 'setLoading', // 앱을 로드하는 상태
  SET_DATA_LOADING = 'setDataLoading', // 데이터를 로드하는 상태를 전역적으로 가진다.
  SET_GROUP = 'setGroup', // 할일 그룹 정보
  SET_VISIBLE_GROUPS = 'setVisibleGroups', // 사용자 쪽에서 설정한 그룹 정보
  SET_THEME = 'setTheme', // 테마 정보
  SET_SLIDE_OPEN = 'setSlideOpen', // 슬라이드 여부
  SET_CLIENT_WINDOW = 'setClientWindow', // 전체 앱 크기 정보
  SET_SWIPING = 'setSwiping', // 스와이프 여부
  SET_SWIPE_XY = 'setSwipeXY', // 스와이프 시작 끝에서 저장할 정보
  SET_SHOW_HOLIDAY = 'setShowHoliday', // 기념일 필터 정보
  SET_HOLIDAYS = 'setHolidays', // 년도별로 한번 로드후에 전역적으로 휴일정보를 가지고 있는다.
  SET_MODE = 'setMode', // 현재 사용중인 모드 (월, 일, 주, 할일)
  SET_MODAL = 'setModal' // 모달 여부
}

interface IAppValueState {
  isAuthenticated: boolean;
  isLoading: boolean;
  dataLoading: boolean;
  group: IGroup;
  theme: string;
  slideOpen: boolean;
  clientWindow: { width: number; height: number };
  swiping: boolean;
  swipeXY: { x: number, y: number };
  showHoliday: boolean;
  holidayGroups: HolidaysGroup[];
  mode: AppMode;
  visibleGroups: string[];
  modalOpen: boolean;
}

export type AppMode = 'MONTH' | 'DAY' | 'WEEK' | 'LIST' | 'CHECK';

type AppAction =
  {
    type: AppActionType.SET_AUTHENTICATED;
    value: boolean;
  } | {
    type: AppActionType.SET_LOADING;
    value: boolean;
  } | {
    type: AppActionType.SET_DATA_LOADING;
    value: boolean;
  } | {
    type: AppActionType.SET_GROUP;
    value: IGroup;
  } | {
    type: AppActionType.SET_VISIBLE_GROUPS;
    value: string[];
  } | {
    type: AppActionType.SET_THEME;
    value: string;
  } | {
    type: AppActionType.SET_SLIDE_OPEN;
    value: boolean;
  } | {
    type: AppActionType.SET_MODAL;
    value: boolean;
  } | {
    type: AppActionType.SET_CLIENT_WINDOW;
    value: { width: number; height: number };
  } | {
    type: AppActionType.SET_SWIPING;
    value: boolean;
  } | {
    type: AppActionType.SET_SWIPE_XY;
    value: { x: number; y: number };
  } | {
    type: AppActionType.SET_SHOW_HOLIDAY;
    value: boolean;
  } | {
    type: AppActionType.SET_HOLIDAYS;
    value: HolidaysGroup;
  } | {
    type: AppActionType.SET_MODE;
    value: AppMode;
  };

function reducer(state: IAppValueState, action: AppAction): IAppValueState {
  switch (action.type) {
    case AppActionType.SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.value,
      };
    case AppActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.value,
      };
    case AppActionType.SET_DATA_LOADING:
      return {
        ...state,
        dataLoading: action.value,
      };
    case AppActionType.SET_GROUP:
      return {
        ...state,
        group: action.value,
      };
    case AppActionType.SET_VISIBLE_GROUPS:
      return {
        ...state,
        visibleGroups: action.value,
      };
    case AppActionType.SET_THEME:
      return {
        ...state,
        theme: action.value,
      };
    case AppActionType.SET_SLIDE_OPEN:
      return {
        ...state,
        slideOpen: action.value,
      };
    case AppActionType.SET_MODAL:
      return {
        ...state,
        modalOpen: action.value,
      };
    case AppActionType.SET_CLIENT_WINDOW:
      return {
        ...state,
        clientWindow: { width: action.value.width, height: action.value.height }
      }
    case AppActionType.SET_SWIPING:
      return {
        ...state,
        swiping: action.value
      }
    case AppActionType.SET_SWIPE_XY:
      return {
        ...state,
        swipeXY: action.value
      }
    case AppActionType.SET_SHOW_HOLIDAY:
      return {
        ...state,
        showHoliday: action.value
      }
    case AppActionType.SET_HOLIDAYS:
      state.holidayGroups.push(action.value);

      return {
        ...state
      }
    case AppActionType.SET_MODE:
      return {
        ...state,
        mode: action.value
      }
    default:
      return state;
  }
}

type AppContextType = {
  onLoad: () => Promise<void>;
  isAuthenticated: boolean;
  setAuthenticated: (value: boolean) => void;
  group: IGroup;
  setGroup: () => Promise<void>;
  visibleGroups: string[];
  updateGroup: (group: IGroup) => void;
  setVisibleGroups: (ids: string[]) => Promise<void>;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  dataLoading: boolean;
  setDataLoading: (value: boolean) => void;
  slideOpen: boolean;
  setSlideOpen: (value: boolean) => void;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  theme: string;
  themeSwitch: () => void;
  clientWindow: { width: number; height: number };
  swiping: boolean;
  setSwiping: (value: boolean) => void;
  swipeXY: { x: number, y: number };
  setSwipeXY: (value: { x: number, y: number }) => void;
  showHoliday: boolean;
  setShowHoliday: (value: boolean) => void;
  searchHolidays: (year: number) => Promise<HolidaysGroup>;
  mode: AppMode;
  setMode: (mode: AppMode) => void;
};

const AppContext = createContext<AppContextType | null>(null);

const appDefaultValue: IAppValueState = {
  isAuthenticated: false,
  isLoading: true,
  dataLoading: true,
  group: { category: '', items: [] },
  theme: 'light',
  slideOpen: false,
  modalOpen: false,
  clientWindow: { width: window.innerWidth, height: window.innerHeight },
  swiping: false,
  swipeXY: { x: 0, y: 0 },
  showHoliday: localStorage.getItem('holiday') ? localStorage.getItem('holiday') === 'true' : true,
  holidayGroups: [],
  mode: 'MONTH',
  visibleGroups: []
};

export function AppContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, appDefaultValue);
  const [searchParams, setSearchParams] = useSearchParams();

  const userTheme = localStorage.getItem('theme') || 'light';
  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const userMode: AppMode = localStorage.getItem('mode') as AppMode || 'MONTH';

  /* API 호출 영역 */
  const loadHolidays = async (year: number): Promise<{ year: number, holidays: IHolidayItem[] }> => {
    return searchHolidays(year);
  }

  const searchHolidays = async (year: number): Promise<HolidaysGroup> => {
    const group = state.holidayGroups.find((group) => group.year === year);

    if (!group) {
      const holidays = await getHolidays(year);

      if (!holidays) {
        const [holiday, anniversary] = await Promise.all([getDataGoHolidays(year), getAnniversaries(year)])
        const mergeDataGoApi = holiday ? holiday.concat(anniversary) : [];
        const dataGoHolidays: CreateHolidayParmas = {
          year,
          holidays: mergeDataGoApi.map((data) => {
            const holidayDate = data.locdate.toString();
            const date = `${holidayDate.substring(0, 4)}-${holidayDate.substring(4, 6)}-${holidayDate.substring(6, 8)}`
            return {
              name: data.dateName,
              date,
              isHoliday: data.isHoliday === 'Y'
            }
          })
        }

        const result = await createHolidays(dataGoHolidays);
        dispatch({ type: AppActionType.SET_HOLIDAYS, value: holidays });
        return {
          year: Number(result.category),
          holidays: result.items
        };
      } else {
        const newGroup: HolidaysGroup = {
          year: Number(holidays.category),
          holidays: holidays.items
        }
        dispatch({ type: AppActionType.SET_HOLIDAYS, value: newGroup });
        return newGroup;
      }
    }

    return _.cloneDeep(group);
  }

  const setShowHoliday = (value: boolean) => {
    localStorage.setItem('holiday', value.toString());
    dispatch({ type: AppActionType.SET_SHOW_HOLIDAY, value });
  }

  const loadGroup = async () => {
    if (!localStorage.getItem('holiday')) {
      localStorage.setItem('holiday', 'true');
    }

    try {
      const [group, user] = await Promise.all([getGroup(), getUser()]);
      dispatch({ type: AppActionType.SET_GROUP, value: group ? group : appDefaultValue.group });
      dispatch({ type: AppActionType.SET_VISIBLE_GROUPS, value: user ? user.visibleGroups : [] });
    } catch (error) {
      if (error instanceof Error) throw new Error(error.message)
    }
  }

  const updateGroup = (group: IGroup) => {
    dispatch({ type: AppActionType.SET_GROUP, value: group });
  }

  const setVisibleGroups = async (visibleGroups: string[]) => {
    await updateUser(visibleGroups);
    dispatch({ type: AppActionType.SET_VISIBLE_GROUPS, value: visibleGroups });
  }

  /* Client Settings 영역 */
  const themeCheck = () => {
    if (userTheme === 'dark' || (!userTheme && systemTheme)) {
      document.documentElement.classList.add('dark');
      dispatch({ type: AppActionType.SET_THEME, value: 'dark' });
      return;
    }
  };

  const themeSwitch = () => {
    if (document.documentElement.classList.contains('dark')) {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
      dispatch({ type: AppActionType.SET_THEME, value: 'light' });
      return;
    }

    document.documentElement.classList.add('dark');
    localStorage.setItem('theme', 'dark');
    dispatch({ type: AppActionType.SET_THEME, value: 'dark' });
  };

  const modeCheck = () => {
    dispatch({ type: AppActionType.SET_MODE, value: userMode });
  };

  const resizeHandler = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    dispatch({
      type: AppActionType.SET_CLIENT_WINDOW,
      value: { width: window.innerWidth, height: window.innerHeight },
    });
  };

  const setAuthenticated = (value: boolean): void => {
    dispatch({ type: AppActionType.SET_AUTHENTICATED, value });
  };

  const setIsLoading = (value: boolean): void => {
    dispatch({ type: AppActionType.SET_LOADING, value });
  };

  const setDataLoading = (value: boolean): void => {
    dispatch({ type: AppActionType.SET_DATA_LOADING, value });
  }

  const setSlideOpen = (value: boolean) => {
    dispatch({ type: AppActionType.SET_SLIDE_OPEN, value });
  };

  const setModalOpen = (value: boolean) => {
    dispatch({ type: AppActionType.SET_MODAL, value });
  };

  const setSwiping = (value: boolean) => {
    dispatch({ type: AppActionType.SET_SWIPING, value });
  };

  const setSwipeXY = (value: { x: number, y: number }) => {
    dispatch({ type: AppActionType.SET_SWIPE_XY, value });
  };

  const setMode = (mode: AppMode) => {
    localStorage.setItem('mode', mode);
    dispatch({ type: AppActionType.SET_MODE, value: mode });
  };

  async function onLoad() {
    try {
      const today = new Date();
      const type = searchParams.get('type');

      if (type === 'example') {
        await Auth.signIn('wooritech@wooritech.com', 'woori1234');
      } else {
        await Auth.currentSession();
      }

      await loadHolidays(getYear(today));
      await loadGroup();
      setAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    } finally {
      setTimeout(() => setIsLoading(false), 500);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    themeCheck();
    modeCheck();
    onLoad();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        onLoad,
        isAuthenticated: state.isAuthenticated,
        setAuthenticated,
        isLoading: state.isLoading,
        setIsLoading,
        dataLoading: state.dataLoading,
        setDataLoading,
        group: state.group,
        setGroup: loadGroup,
        visibleGroups: state.visibleGroups,
        updateGroup,
        setVisibleGroups,
        themeSwitch,
        theme: state.theme,
        slideOpen: state.slideOpen,
        setSlideOpen,
        modalOpen: state.modalOpen,
        setModalOpen,
        clientWindow: state.clientWindow,
        swiping: state.swiping,
        setSwiping,
        swipeXY: state.swipeXY,
        setSwipeXY,
        showHoliday: state.showHoliday,
        setShowHoliday,
        searchHolidays,
        mode: state.mode,
        setMode
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error('Cannot find AppProvider');
  }

  return appContext;
}

export default AppContextProvider;
