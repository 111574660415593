import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../context/useAppContext";

export type AuthenticatedRouteProps = {
  children: React.ReactElement;
};

export default function AuthenticatedRoute({
  children,
}: AuthenticatedRouteProps) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  }

  return children;
}
