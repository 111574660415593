import { getDateFormat } from '../utils/date';

const theme = localStorage.getItem('theme') || 'light';

export const TODO_HEADERS_TEAMPLTE = {
  name: 'header',
  description: 'List header template',
  props: {},
  template: {
    layout: 'hlinear',
    width: '100%',
    itemsArrange: 'center',
    children: [
      {
        layout: 'vlinear',
        width: '100%',
        itemsArrange: 'center',
        children: [
          {
            width: '100%',
            height: 10,
            align: 'center',
            renderer: {
              type: 'html',
              html: '<div class="w-10 h-1 my-1 rounded-lg bg-gray-500"></div>',
            },
            style: {
              width: '100%',
              justifiyContent: 'center',
            },
          },
          {
            value: '${@caption;Header}',
            tag: 'caption',
            width: '50%',
            align: '${@caption-align}',
            style: {
              padding: '0 8px 4px 8px',
              fontWeight: 'bold',
              color: '${@caption-color;#333}',
            },
          },
        ],
      },
    ],
  },
};

export const TODO_ROW_TEAMPLTE = {
  vars: {
    nameStyle: { color: 'blue' },
    valueStyle: { color: '#333', fontSize: '17px', fontWeight: 'bold' },
    lineStyle: { paddingBottom: '2px' },
  },
  template: {
    layout: 'hlinear',
    width: '100%',
    height: '100%',
    itemsAlign: 'top',
    itemsArrange: 'start',
    children: [
      {
        id: 'calendar',
        width: '100%',
        renderer: {
          type: 'html',
          html: '<div class="w-full">${@date}</div>',
          style: {
            width: '100%',
          },
        },
      },
    ],
  },
  rowStyle: {
    width: '100%',
    height: '100%',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
};

export const TODO_OPTIONS_TEMPLATE = {
  orientation: 'horizontal',
  row: {
    template: 'row',
    style: {
      background: theme === 'dark' ? '#1e293b' : '#f3f4f6',
    },
  },
  header: {
    visible: true,
    caption: getDateFormat(new Date(), 'M월 DD일 dd요일'),
    captionAlign: 'left',
    captionColor: 'white',
    template: 'header',
    style: {
      background: theme === 'dark' ? '#1e293b' : '#f3f4f6',
    },
  },
  footer: {
    visible: false,
  },
  singleRow: {
    visible: true,
    maskEffect: true,
    fillHeight: true,
  },
  scrollBar: false,
  scrollIndicator: false,
};

export const TODO_FORM_TEMPLATE = {
  template: {
    layout: 'form',
    // minLabelWidth: '20%',
    labelStyle: { textAlign: 'right' },
    style: {
      background: theme === 'dark' ? '#1e293b' : '#f3f4f6',
    },
    children: [
      {
        renderer: {
          type: 'html',
          html: '<h1>할일 추가하기</h1>'
        }
      },
      {
        label: '제목',
        editor: {
          type: 'text'
        }
      }, {
        label: '내용',
        editor: {
        }
      }, {
        label: '일자',
        editor: {
          type: 'date'
        }
      }, {
        label: '그룹',
        renderer: {
          type: 'html',
          html: '<div>그룹입니다.</div>'
        }
      }],
  }
};
