import toast from "react-hot-toast";
import Icon from "../components/Icon";
import LoadingSvg from "../components/LoadingSvg";
import { useAppContext } from "../context/useAppContext";

export function useToast() {
  const { theme } = useAppContext();
   const toastPromise = (
    handler: Promise<any>,
    loadingMessage: string,
    successMessage: string,
    ) => toast.promise(
    handler,
     {
       loading: <div className="flex justify-start text-gray-800 dark:text-white text-sm">
        {loadingMessage}
       </div>,
       success: <div className="flex justify-start text-gray-800 dark:text-white text-sm">
        {successMessage}
       </div>,
       error: <div className="flex justify-start text-gray-800 dark:text-white text-sm">처리 도중 오류가 발생하였습니다.</div>,
     },
     {
      style: {
        border: theme === 'dark' ? '1px solid #0f172a' : '1px solid #f9fafb',
        background: theme === 'dark' ? '#475569' : 'white',
        boxShadow: '0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -4px rgba(0,0,0,0.1)',
        minWidth: '100px',
        justifyContent: 'start',
        textAlign: 'left'
      },
      success: {
        icon: <Icon name="check" size="sm" className="text-green-500"/>,
        duration: 2000
      },
      loading: {
        icon: <LoadingSvg size="sm" />
      }
     }
   );

   return {
    toastPromise
   }
}