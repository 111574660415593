import { ChangeEvent } from "react";
import { useState } from "react";

export type UseFormFields = {
    [key: string]: any
}
export function useFormFields(initialState: UseFormFields): [
    UseFormFields,
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
] {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            setValues({
                ...fields,
                [event.target.id]: event.target.value,
            });
        },
    ];
}