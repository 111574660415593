import { useState } from "react";
import { useAppContext } from "../context/useAppContext";
import { useFormFields } from "../hooks/useFormFields";
import { useNavigate } from "react-router-dom";
import { formClasses, inputClasses } from "../styles";
import clsx from "clsx";
import { motion } from "framer-motion";
import Button from "../components/Button";
import { Auth } from "aws-amplify";
import { ConfirmSignup } from "./ConfirmSignup";

export default function SignUp() {
  const { theme } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmationCode: "",
  });
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExistUserName, setIsExistUserName] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);

  const signupClasses = clsx(formClasses, { "bg-default": theme === "light" });

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);
    setIsExistUserName(false);
    setIsInvalidPassword(false);
    try {
      await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setNewUser(true);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "InvalidPasswordException") setIsInvalidPassword(true);
        if (error.name === "UsernameExistsException") setIsExistUserName(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  return (
    <motion.div
      className="w-full h-full"
      initial={{ opacity: 0 }}
      animate="visible"
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
      }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      {newUser ? (
        <ConfirmSignup email={fields.email} password={fields.password} />
      ) : (
        <form className={signupClasses} onSubmit={handleSubmit}>
          <div className="px-4 py-8 mx-4 shadow sm:rounded-lg sm:px-10 sm:mx-auto sm:w-full sm:max-w-md bg-white dark:bg-slate-800 dark:text-white">
            <div className="flex items-center justify-between sm:mx-auto sm:w-full sm:max-w-md mb-2">
              <h1 className="text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
                회원가입
              </h1>
              <img
                className="flex w-14 h-14"
                src="./android-chrome-192x192.png"
                alt="Todo App"
              />
            </div>

            <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  이메일 주소
                </label>
                <div className="mt-2">
                  <input
                    name="email"
                    id="email"
                    type="email"
                    autoFocus
                    autoComplete="email"
                    required
                    className={inputClasses}
                    placeholder="wooritech@wooritech.com"
                    onChange={handleFieldChange}
                  />
                </div>
                <p
                  className={clsx(
                    "mt-1 text-sm leading-6 text-red-500",
                    isExistUserName ? 'visible' : 'invisible'
                  )}
                >
                  이미 가입된 이메일입니다.
                </p>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  비밀번호
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="password"
                    className={inputClasses}
                    placeholder="영문과 숫자 조합으로 6글자 이상으로 입력해주세요."
                    onChange={handleFieldChange}
                  />
                </div>
                <p
                  className={clsx(
                    "mt-1 text-sm leading-6 text-red-500",
                    isInvalidPassword ? 'visible' : 'invisible'
                  )}
                >
                  비밀번호는 영문 숫자 조합으로 6글자 이상이여야 합니다.
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between w-full mt-2 gap-x-2">
              <Button
                text="취소"
                type="primary"
                containerClassNames="flex-grow"
                classNames="w-full"
                onClick={() => navigate("/login")}
              />
              <Button
                text="확인"
                type="primary"
                buttonType="submit"
                containerClassNames="flex-grow"
                classNames="w-full"
                isLoading={isLoading}
                disabled={!validateForm()}
              />
            </div>
          </div>
        </form>
      )}
    </motion.div>
  );
}
