import { AppMode } from "../context/useAppContext";

export function modeIcon(mode: AppMode): string {
    const iconName = {
        MONTH: 'calendarDays',
        DAY: 'calendarTodo',
        WEEK: 'calendarWeeks',
        LIST: 'list',
        CHECK: 'circleCheck'
    }

    return iconName[mode] || '';
}


export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}