import { Dispatch, useReducer } from 'react';
import { getDateFormat } from '../utils/date';
import dayjs from 'dayjs';
import { HolidaysGroup } from '../api/holiday';
import { getTodoMonth, ITodo, ITodoItem } from '../api/todos';
import { useAppContext } from '../context/useAppContext';

export enum TodoDayActionType {
  SET_TODO = 'setTodo',
  ADD_TODO = 'addTodo',
  UPDATE_TODO = 'updateTodo',
  REMOVE_TODO = 'removeTodo',
  REFRESH_TODO = 'refereshTodo',
  UPDATE_GROUP = 'updateGroup',
  SET_HOLIDAYS = 'setHolidays',
  ADD_HOLIDAYS = 'addHolidays',
}

export type TodoDayState = {
  yearMonth: string;
  items: TodoItem[];
  holidays: TodoItem[];
};

export type TodoItem = {
  yearMonth: string;
  date: string;
  groupId: string;
  todoId: string;
  title: string;
  content: string;
  isHoliday: boolean;
  firstLine: boolean;
  lastLine: boolean;
  state: 'none' | 'new' | 'update';
};

export type TodoAction =
  | {
    type: TodoDayActionType.SET_TODO;
    todos: ITodo[];
  }
  | {
    type: TodoDayActionType.ADD_TODO;
    todos: ITodo[];
  }
  | {
    type: TodoDayActionType.REFRESH_TODO;
    todoGroup: {
      yearMonth: string;
      todos: ITodo[];
    }[];
    todoId?: string;
    state?: 'new' | 'update';
  }
  | {
    type: TodoDayActionType.UPDATE_TODO;
    date: string;
    todo: ITodoItem;
  }
  | {
    type: TodoDayActionType.UPDATE_GROUP;
    groups: string[];
  }
  | {
    type: TodoDayActionType.REMOVE_TODO;
    todoId: string;
    date: string;
  }
  | {
    type: TodoDayActionType.SET_HOLIDAYS;
    holidays: HolidaysGroup[];
  }
  | {
    type: TodoDayActionType.ADD_HOLIDAYS;
    holidays: HolidaysGroup[];
  };

export enum TodoHandlerType {
  SET = 'SET',
  ADD = 'ADD',
}

function todoReducer(
  state: TodoDayState[],
  action: TodoAction
): TodoDayState[] {
  switch (action.type) {
    case TodoDayActionType.SET_TODO:
      state.forEach((s) => {
        s.items = [];
      });

      setTodo(state, action.todos);
      setSortState(state);

      return [...state];
    case TodoDayActionType.ADD_TODO:
      setTodo(state, action.todos);

      return [...state];
    case TodoDayActionType.REFRESH_TODO:
      action.todoGroup.forEach((group) => {
        const stateIndex = state.findIndex(
          (state) => state.yearMonth === group.yearMonth
        );

        // 기존 아이템을 초기화하고 세로 세팅
        if (stateIndex > -1) {
          state[stateIndex].items = [];
          setTodo(state, group.todos, action.todoId, action.state);
        }
      });

      setSortState(state);

      return [...state];
    case TodoDayActionType.UPDATE_TODO:
      const updateIndex = state.findIndex(
        (s) => s.yearMonth === getDateFormat(action.date, 'YYYYMM')
      );

      const updateItems = state[updateIndex].items;
      const updateItemIndex = updateItems.findIndex(
        (item) => item.todoId === action.todo.todoId
      );

      updateItems[updateItemIndex].title = action.todo.title;
      updateItems[updateItemIndex].content = action.todo.content;
      updateItems[updateItemIndex].groupId = action.todo.groupId;

      return state;
    case TodoDayActionType.REMOVE_TODO:
      const yearMonthIndex = state.findIndex(
        (s) => s.yearMonth === getDateFormat(action.date, 'YYYYMM')
      );

      state[yearMonthIndex].items = state[yearMonthIndex].items.filter(
        (item) => item.todoId !== action.todoId
      );

      setSortState(state);

      return [...state];
    case TodoDayActionType.UPDATE_GROUP:
      state.forEach((s) => {
        s.items = s.items.filter((item) => action.groups.includes(item.groupId));
      });

      setSortState(state);

      return [...state];
    case TodoDayActionType.SET_HOLIDAYS:
      state.forEach((s) => {
        s.holidays = [];
      });

      setHoliday(state, action.holidays);
      setSortState(state);

      return [...state];
    case TodoDayActionType.ADD_HOLIDAYS:
      setHoliday(state, action.holidays);
      setSortState(state);

      return [...state];
    default:
      return [...state];
  }
}

function setTodo(
  state: TodoDayState[],
  todos: ITodo[],
  todoId?: string,
  type?: 'update' | 'new'
) {
  todos.forEach((todo) => {
    let todoIndex = state.findIndex((s) => s.yearMonth === todo.yearMonth);

    if (todoIndex < 0) {
      todoIndex = state.push({
        yearMonth: todo.yearMonth,
        items: [],
        holidays: [],
      });
    }

    todo.items.forEach((item) => {
      const existTodoIndex = state[todoIndex].items.findIndex(
        (stateItem) => stateItem.todoId === item.todoId
      );
      if (existTodoIndex > -1) return;

      state[todoIndex].items.push({
        yearMonth: getDateFormat(todo.date, 'YYYYMM'),
        date: getDateFormat(todo.date, 'YYYY-MM-DD'),
        groupId: item.groupId,
        todoId: item.todoId,
        title: item.title,
        content: item.content,
        isHoliday: false,
        firstLine: false,
        lastLine: false,
        state: item.todoId === todoId ? type! : 'none',
      });
    });
  });
}

function setHoliday(state: TodoDayState[], holidays: HolidaysGroup[]) {
  holidays.forEach((holiday) => {
    holiday.holidays.forEach((h) => {
      const holidayDate = h.date;
      const yearMonth = getDateFormat(holidayDate, 'YYYYMM');
      const holidayIndex = state.findIndex((s) => s.yearMonth === yearMonth);

      if (holidayIndex > -1) {
        const existHolidayIndex = state[holidayIndex].holidays.findIndex(
          (holiday) => holiday.date === holidayDate && holiday.title === h.name
        );

        // 이미 존재하는 경우 추가를 방지한다.
        if (existHolidayIndex < 0)
          state[holidayIndex].holidays.push({
            yearMonth: yearMonth,
            date: holidayDate,
            todoId: 'holiday',
            groupId: 'holiday',
            title: h.name,
            content: '',
            isHoliday: h.isHoliday,
            firstLine: false,
            lastLine: false,
            state: 'none',
          });
      } else {
        state.push({
          yearMonth: yearMonth,
          holidays: [
            {
              yearMonth: yearMonth,
              date: holidayDate,
              todoId: 'holiday',
              groupId: 'holiday',
              title: h.name,
              content: '',
              isHoliday: h.isHoliday,
              firstLine: false,
              lastLine: false,
              state: 'none',
            },
          ],
          items: [],
        });
      }
    });
  });
}

function setSortState(state: TodoDayState[]) {
  state.forEach((s) => {
    // 달에 아무것도 없을경우 빈 일정추가
    if (s.items.length === 0 && s.holidays.length === 0) {
      s.items.push({
        yearMonth: s.yearMonth,
        date: `${s.yearMonth.substring(0, 4)}-${s.yearMonth.substring(
          4,
          6
        )}-01`,
        groupId: 'empty',
        todoId: 'empty',
        title: '일정이 존재하지 않습니다.',
        content: '',
        isHoliday: false,
        firstLine: true,
        lastLine: true,
        state: 'none',
      });
    } else if (s.holidays.length > 0) {
      s.items = s.items.filter((item) => item.todoId !== 'empty');
    }
  });

  // 소팅적용
  const sortState = state.sort(
    (a, b) => Number(a.yearMonth) - Number(b.yearMonth)
  );

  sortState.forEach((state) => {
    state.items = state.items.sort(
      (a, b) =>
        Number(getDateFormat(a.date, 'YYYYMMDD')) -
        Number(getDateFormat(b.date, 'YYYYMMDD'))
    );
    state.holidays = state.holidays.sort(
      (a, b) =>
        Number(getDateFormat(a.date, 'YYYYMMDD')) -
        Number(getDateFormat(b.date, 'YYYYMMDD'))
    );
  });

  // 첫번째 마지막 라인 지정 시작
  let dates: string[] = [];

  state.forEach((todo) => {
    todo.items.forEach((item) => {
      item.firstLine = false;
      item.lastLine = false;
      if (!dates.includes(item.date)) dates.push(item.date);
    });

    todo.holidays.forEach((holiday) => {
      holiday.firstLine = false;
      holiday.lastLine = false;
      if (!dates.includes(holiday.date)) dates.push(holiday.date);
    });
  });

  dates.forEach((date) => {
    const yearMonthIndex = state.findIndex(
      (todo) => todo.yearMonth === getDateFormat(date, 'YYYYMM')
    );

    const yearMonthState = state[yearMonthIndex];
    const itemIndex = yearMonthState.items.findIndex((i) => i.date === date);
    const holidayIndex = yearMonthState.holidays.findIndex(
      (h) => h.date === date
    );

    if (itemIndex > -1) {
      const itemFirstIndex = yearMonthState.items.findIndex(
        (item) => item.date === date
      );
      yearMonthState.items[itemFirstIndex].firstLine = true;
    } else {
      const holidayFirstIndex = yearMonthState.holidays.findIndex(
        (holiday) => holiday.date === date
      );
      yearMonthState.holidays[holidayFirstIndex].firstLine = true;
    }

    if (holidayIndex > -1) {

      const holidayLastIndex = findTodoLastIndex(yearMonthState.holidays, date);

      yearMonthState.holidays[holidayLastIndex].lastLine = true;
    } else {
      const itemLastIndex = findTodoLastIndex(yearMonthState.items, date);

      yearMonthState.items[itemLastIndex].lastLine = true;
    }
  });
}

function findTodoLastIndex(todoItems: TodoItem[], findItem: string): number {
  if (todoItems.length === 0) return -1;

  for (let i = todoItems.length - 1; i > -1; i--) {
    if (todoItems[i].date === findItem) return i
  }

  return -1
}

export type TodoDaysReducer = {
  todo: TodoDayState[];
  setTodo: Dispatch<TodoAction>;
  syncTodo: (startMonth, endMonth) => Promise<void>;
  addEvents: (
    startMonth: dayjs.Dayjs,
    endMonth: dayjs.Dayjs,
    type: TodoHandlerType
  ) => Promise<void>;
  refereshTodo: (
    yearMonths: string[],
    todoId?: string,
    type?: 'update' | 'new'
  ) => Promise<void>;
  updateGroup: () => void;
  addHolidays: (
    years: number[],
    startMonth: dayjs.Dayjs,
    endMonth: dayjs.Dayjs,
    type: TodoHandlerType
  ) => Promise<void>;
  refereshHolidays: (todoId?: string) => Promise<void>;
};

/**
 * @author {KIMSANGYEOB}
 * - 할일 관련 데이터를 표기하기 위한 Todo Hook
 */
export function useTodoDays(): TodoDaysReducer {
  const { visibleGroups, showHoliday, searchHolidays } = useAppContext();

  const [todo, setTodo] = useReducer(todoReducer, []);

  /**
   * - API관련 동작 처리
   */
  const addEvents = async (
    startMonth: dayjs.Dayjs,
    endMonth: dayjs.Dayjs,
    type: TodoHandlerType
  ) => {
    initYearMonth(todo, startMonth, endMonth);
    const todos: ITodo[] = await getTodoMonth({
      startMonth: startMonth.format('YYYYMM'),
      endMonth: endMonth.format('YYYYMM'),
    });

    // 완료된 아이템 제외, 필터링된 목록만 포함
    todos.forEach((todo) => {
      todo.items = todo.items
        .filter((item) => item.completed === false)
        .filter((item) => visibleGroups.includes(item.groupId));
    });

    if (type === TodoHandlerType.SET)
      setTodo({ type: TodoDayActionType.SET_TODO, todos });
    else if (type === TodoHandlerType.ADD)
      setTodo({ type: TodoDayActionType.ADD_TODO, todos });
  };

  // 서버데이터와 완전히 새로 일치시킨다.
  const syncTodo = async (
    startMonth: dayjs.Dayjs,
    endMonth: dayjs.Dayjs,
  ) => {
    // 기존 상태값 전부 제거
    todo.splice(0, todo.length);
    initYearMonth(todo, startMonth, endMonth);

    const todos: ITodo[] = await getTodoMonth({
      startMonth: startMonth.format('YYYYMM'),
      endMonth: endMonth.format('YYYYMM'),
    });

    // 완료된 아이템 제외, 필터링된 목록만 포함
    todos.forEach((todo) => {
      todo.items = todo.items
        .filter((item) => item.completed === false)
        .filter((item) => visibleGroups.includes(item.groupId));
    });
    setTodo({ type: TodoDayActionType.SET_TODO, todos });
  }
  const refereshTodo = async (
    yearMonths: string[],
    todoId?: string,
    type?: 'update' | 'new'
  ) => {
    const todos = await Promise.all(
      yearMonths.map(async (yearMonth) => {
        const result = await getTodoMonth({
          startMonth: yearMonth,
          endMonth: yearMonth,
        });

        return result;
      })
    );

    const todoGroup = todos.map((todo, index) => {
      todo.forEach((t) => {
        t.items = t.items
          .filter((item) => item.completed === false)
          .filter((item) => visibleGroups.includes(item.groupId));
      });

      return {
        yearMonth: yearMonths[index],
        todos: todo,
      };
    });

    setTodo({
      type: TodoDayActionType.REFRESH_TODO,
      todoGroup,
      todoId,
      state: type,
    });
  };

  const addHolidays = async (
    years: number[],
    startMonth: dayjs.Dayjs,
    endMonth: dayjs.Dayjs,
    type: TodoHandlerType
  ) => {
    initYearMonth(todo, startMonth, endMonth);

    if (!showHoliday) {
      todo.forEach((t) => {
        t.holidays = [];
      });

      setTodo({ type: TodoDayActionType.SET_HOLIDAYS, holidays: [] });
      return;
    }
    const holidays = await Promise.all(
      years.map(async (year) => {
        const result = await searchHolidays(year);

        return result;
      })
    );

    const todoHolidays: HolidaysGroup[] = [];

    holidays.forEach((holiday) => {
      const filterHoliday = holiday.holidays
        .filter((h) => {
          return dayjs(h.date).diff(dayjs(startMonth.date(1)), 'day') >= 0;
        })
        .filter((h) => {
          return dayjs(h.date).diff(dayjs(endMonth.endOf('M')), 'day') <= 0;
        });

      todoHolidays.push({
        year: holiday.year,
        holidays: filterHoliday,
      });
    });

    if (type === TodoHandlerType.SET)
      setTodo({ type: TodoDayActionType.SET_HOLIDAYS, holidays: todoHolidays });
    else if (type === TodoHandlerType.ADD)
      setTodo({ type: TodoDayActionType.ADD_HOLIDAYS, holidays: todoHolidays });
  };

  const refereshHolidays = async () => {
    const years: number[] = [];

    if (!showHoliday) {
      setTodo({ type: TodoDayActionType.SET_HOLIDAYS, holidays: [] });
      return;
    }

    todo.forEach((t) => {
      const year = t.yearMonth.substring(0, 4);
      if (!years.includes(Number(year))) years.push(Number(year));
    });

    const holidays = await Promise.all(
      years.map(async (year) => {
        const result = await searchHolidays(year);

        return result;
      })
    );

    const todoHolidays: HolidaysGroup[] = [];

    const firstMonth = todo[0].yearMonth;
    const lastMonth = todo[todo.length - 1].yearMonth;
    const startMonth = `${firstMonth.substring(0, 4)}-${firstMonth.substring(
      4,
      6
    )}-01`;
    const endMonth = `${lastMonth.substring(0, 4)}-${lastMonth.substring(
      4,
      6
    )}-01`;

    holidays.forEach((holiday) => {
      const filterHoliday = holiday.holidays
        .filter((h) => {
          return dayjs(h.date).diff(dayjs(startMonth), 'day') >= 0;
        })
        .filter((h) => {
          return dayjs(h.date).diff(dayjs(endMonth), 'day') <= 0;
        });

      todoHolidays.push({
        year: holiday.year,
        holidays: filterHoliday,
      });
    });

    setTodo({ type: TodoDayActionType.SET_HOLIDAYS, holidays: todoHolidays });
  };

  const updateGroup = () => {
    setTodo({ type: TodoDayActionType.UPDATE_GROUP, groups: visibleGroups });
  }

  function initYearMonth(
    state: TodoDayState[],
    startMonth: dayjs.Dayjs,
    endMonth: dayjs.Dayjs
  ) {
    const monthLength = endMonth.diff(startMonth, 'M');

    for (let i = 0; i <= monthLength; i++) {
      const yearMonth = startMonth.add(i, 'M').format('YYYYMM');

      const todoIndex = state.findIndex((t) => t.yearMonth === yearMonth);
      if (todoIndex < 0)
        state.push({
          yearMonth,
          items: [],
          holidays: [],
        });
    }
  }

  return {
    todo,
    setTodo,
    syncTodo,
    addEvents,
    refereshTodo,
    addHolidays,
    refereshHolidays,
    updateGroup
  };
}
