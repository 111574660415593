import { Dispatch, useReducer } from 'react';
import {
  getMonth,
  getYear,
  getISOString,
  getWeek,
  getDateFormat,
  getDays,
  getWeekDays,
} from '../utils/date';
import dayjs from 'dayjs';
import { HolidayResponse, HolidaysGroup, IHolidayItem } from '../api/holiday';
import { getTodo, ITodo, ITodoItem } from '../api/todos';
import { AppMode, useAppContext } from '../context/useAppContext';

export enum CalendarDayActionType {
  SET_DAYS = 'setDays',
  SET_SELECTED_DAY = 'setSelectedDay',
  SET_SELECTED_WEEK_DAY = 'setSelectedWeekDay',
  SET_EVENTS = 'setEvents',
  REMOVE_EVENTS = 'removeEvents',
  UPDATE_EVENTS = 'updateEvents',
  FILTER_GROUPS = 'filterGroups',
  SET_HOLIDAYS = 'setHolidays',
  CHANGE_WEEK = 'changeWeek',
  CHANGE_MONTH = 'changeMonth',
}

export type CalendarDay = {
  date: string;
  isHoliday: boolean;
  events: CalendarEvent[];
  holidays: CalendarHoliday[];
};

export type CalendarEvent = {
  groupId: string;
  todoId: string;
  title: string;
  content: string;
  completed: boolean;
  createdAt: string;
  updatedAt: string;
};

export type CalendarHoliday = {
  name: string;
  isHoliday: boolean;
};

export type CalendarDayState = {
  days: CalendarDay[];
  today: string | Date | number;
  startDate: string;
  endDate: string;
  currentDate: string;
  currentYear: string;
  currentMonth: string;
  currentDay: string;
  selected: {
    index: number;
    day: string | null;
    week: number | null;
    month: number | null;
    year: number | null;
  };
};

export type CalendarAction =
  | {
    type: CalendarDayActionType.SET_DAYS;
    value: dayjs.ConfigType;
    mode?: AppMode;
  }
  | {
    type: CalendarDayActionType.SET_EVENTS;
    value: ITodo | ITodo[];
    groups: string[];
  }
  | {
    type: CalendarDayActionType.UPDATE_EVENTS;
    date: string;
    item: ITodoItem;
  }
  | {
    type: CalendarDayActionType.REMOVE_EVENTS;
    date: string;
    value: string;
  }
  | {
    type: CalendarDayActionType.FILTER_GROUPS;
    groups: string[];
  }
  | {
    type: CalendarDayActionType.SET_HOLIDAYS;
    value?: HolidaysGroup;
  }
  | {
    type: CalendarDayActionType.SET_SELECTED_DAY;
    value: dayjs.ConfigType;
  }
  | {
    type: CalendarDayActionType.SET_SELECTED_WEEK_DAY;
    value: dayjs.ConfigType;
  }
  | {
    type: CalendarDayActionType.CHANGE_WEEK;
    value: dayjs.ConfigType;
  }
  | {
    type: CalendarDayActionType.CHANGE_MONTH;
    value: dayjs.ConfigType;
  };

function calendarReducer(
  state: CalendarDayState,
  action: CalendarAction
): CalendarDayState {
  switch (action.type) {
    case CalendarDayActionType.SET_DAYS:
      return {
        ...state,
        days: action.mode === 'WEEK' ? getWeekDays(action.value) : getDays(action.value),
      };
    case CalendarDayActionType.SET_SELECTED_DAY:
      if (state.currentMonth !== `${getMonth(action.value) + 1}`) {
        const days = getDays(action.value);
        return {
          ...state,
          days: days,
          startDate: getDateFormat(days[0].date, 'YYYY-MM-DD'),
          endDate: getDateFormat(days[days.length - 1].date, 'YYYY-MM-DD'),
          currentDate: dayjs(action.value).format('YYYY-MM-DD'),
          currentMonth: getDateFormat(action.value, 'M'),
          currentYear: getDateFormat(action.value, 'YYYY'),
          currentDay: getDateFormat(action.value, 'DD'),
          selected: {
            index: state.days.findIndex(
              (day) => day.date === getDateFormat(action.value, 'YYYY-MM-DD')
            ),
            day: getDateFormat(action.value, 'YYYY-MM-DD'),
            week: getWeek(action.value),
            month: getMonth(action.value) + 1,
            year: getYear(action.value),
          },
        };
      } else {
        return {
          ...state,
          selected: {
            index: state.days.findIndex(
              (day) => day.date === getDateFormat(action.value, 'YYYY-MM-DD')
            ),
            day: getDateFormat(action.value, 'YYYY-MM-DD'),
            week: getWeek(action.value),
            month: getMonth(action.value) + 1,
            year: getYear(action.value),
          },
        };
      }
    case CalendarDayActionType.SET_SELECTED_WEEK_DAY:
      return {
        ...state,
        selected: {
          index: state.days.findIndex(
            (day) => day.date === getDateFormat(action.value, 'YYYY-MM-DD')
          ),
          day: getDateFormat(action.value, 'YYYY-MM-DD'),
          week: getWeek(action.value),
          month: getMonth(action.value) + 1,
          year: getYear(action.value),
        },
      };
    case CalendarDayActionType.SET_EVENTS:
      state.days.forEach((d) => {
        d.events = [];
      });

      setEvents(state, action.value, action.groups);

      return {
        ...state,
      };
    case CalendarDayActionType.UPDATE_EVENTS:
      const dayIndex = state.days.findIndex((day) => day.date === action.date);

      if (dayIndex < 0) return { ...state };

      const existIndex = state.days[dayIndex].events.findIndex(
        (event) => event.todoId === action.item.todoId
      );

      if (existIndex >= 0) {
        state.days[dayIndex].events[existIndex] = {
          ...action.item,
        };
      } else {
        state.days[dayIndex].events.push({
          ...action.item,
        });
      }

      return {
        ...state,
      };
    case CalendarDayActionType.REMOVE_EVENTS:
      const removeDayIndex = state.days.findIndex(
        (day) => day.date === action.date
      );

      if (removeDayIndex < 0) return { ...state };

      const filterEvents = state.days[removeDayIndex].events.filter(
        (event) => event.todoId !== action.value
      );
      state.days[removeDayIndex].events = filterEvents;

      return {
        ...state,
      };
    case CalendarDayActionType.FILTER_GROUPS:
      state.days.forEach((day) => {
        day.events = day.events.filter((event) => action.groups.includes(event.groupId));
      });

      return {
        ...state,
      };
    case CalendarDayActionType.SET_HOLIDAYS:
      if (!action.value) {
        state.days.forEach((d) => {
          d.isHoliday = false;
          d.holidays = [];
        });

        return {
          ...state,
        };
      }

      setHolidays(state, action.value.holidays);

      return {
        ...state,
      };
    case CalendarDayActionType.CHANGE_WEEK:
      const changeWeekDate = dayjs(action.value);
      const firstWeekDate = changeWeekDate.subtract(changeWeekDate.day(), 'day');
      const weekDays = getWeekDays(changeWeekDate);

      return {
        ...state,
        days: weekDays,
        startDate: getDateFormat(weekDays[0].date, 'YYYY-MM-DD'),
        endDate: getDateFormat(weekDays[weekDays.length - 1].date, 'YYYY-MM-DD'),
        currentDate: dayjs(changeWeekDate).format('YYYY-MM-DD'),
        currentMonth: getDateFormat(changeWeekDate, 'M'),
        currentYear: getDateFormat(changeWeekDate, 'YYYY'),
        currentDay: getDateFormat(changeWeekDate, 'DD'),
        selected: {
          index: weekDays.findIndex(
            (day) => day.date === getDateFormat(firstWeekDate, 'YYYY-MM-DD')
          ),
          day: getDateFormat(firstWeekDate, 'YYYY-MM-DD'),
          week: getWeek(firstWeekDate),
          month: getMonth(firstWeekDate) + 1,
          year: getYear(firstWeekDate),
        },
      };
    case CalendarDayActionType.CHANGE_MONTH:
      const changeDate = dayjs(action.value);
      const firstDate = changeDate.date(1);
      const days = getDays(changeDate);

      return {
        ...state,
        days,
        startDate: getDateFormat(days[0].date, 'YYYY-MM-DD'),
        endDate: getDateFormat(days[days.length - 1].date, 'YYYY-MM-DD'),
        currentDate: dayjs(changeDate).format('YYYY-MM-DD'),
        currentMonth: getDateFormat(changeDate, 'M'),
        currentYear: getDateFormat(changeDate, 'YYYY'),
        currentDay: getDateFormat(changeDate, 'DD'),
        selected: {
          index: days.findIndex(
            (day) => day.date === getDateFormat(firstDate, 'YYYY-MM-DD')
          ),
          day: getDateFormat(firstDate, 'YYYY-MM-DD'),
          week: getWeek(firstDate),
          month: getMonth(firstDate) + 1,
          year: getYear(firstDate),
        },
      };
    default:
      return state;
  }
}

function setEvents(
  state: CalendarDayState,
  events: ITodo | ITodo[],
  groups: string[]
) {
  if (Array.isArray(events)) {
    events.forEach((todo) => {
      const dateIndex = state.days.findIndex(
        (day) => day.date === getDateFormat(todo.date, 'YYYY-MM-DD')
      );

      if (dateIndex > -1) {
        todo.items.forEach((todo) => {
          if (groups.includes(todo.groupId)) {
            state.days[dateIndex].events.push({
              ...todo,
            });
          }
        });
      }
    });
  } else {
    const todo = events as ITodo;
    if (!todo) return;

    const dateIndex = state.days.findIndex(
      (day) => day.date === getDateFormat(todo.date, 'YYYY-MM-DD')
    );

    if (dateIndex > -1) {
      todo.items.forEach((item) => {
        if (groups.includes(item.groupId)) {
          state.days[dateIndex].events.push({
            ...item,
          });
        }
      });
    }
  }
}

function setHolidays(state: CalendarDayState, holidays: IHolidayItem[]) {
  if ((localStorage.getItem('holiday') || 'true') === 'false') return;

  state.days.forEach((day, index) => {
    day.holidays = [];
    holidays.forEach((holiday) => {
      if (day.date === holiday.date) {
        if (day.holidays.findIndex((h) => h.name === holiday.name) < 0)
          if (holiday.isHoliday) day.isHoliday = true;
        day.holidays.push({
          name: holiday.name,
          isHoliday: holiday.isHoliday,
        });
      }
    });
  });
}

function getStartEndDate(changeDate: dayjs.Dayjs) {
  const firstDay = changeDate.date(1).get('day');
  const startDate = dayjs(changeDate.date(1)).subtract(firstDay, 'day');
  const endDate = startDate.add(41, 'day');

  return {
    startDate: getDateFormat(startDate, 'YYYYMMDD'),
    endDate: getDateFormat(endDate, 'YYYYMMDD'),
  };
}

export type CalendarDaysReducer = {
  calendar: CalendarDayState;
  setCalendar: Dispatch<CalendarAction>;
  selectDay: (value: dayjs.ConfigType) => Promise<CalendarDayState>;
  changeWeek: (value: 'prev' | 'next') => Promise<void>;
  changeMonth: (value: 'prev' | 'next') => Promise<void>;
  loadDay: (groups: string[]) => Promise<void>;
  refereshDays: (mode: AppMode) => Promise<void>;
};

/**
 * @author {KIMSANGYEOB}
 * - 달력 관련 데이터를 표현하기 위한 Calendar Hook
 */
export function useCalendarDays(
  date: dayjs.ConfigType = new Date(),
  selectedDate: dayjs.ConfigType = new Date(),
): CalendarDaysReducer {
  const { visibleGroups, setDataLoading, searchHolidays, mode } = useAppContext();

  /* 초기 달력 세팅 */
  const days = mode === 'WEEK' ? getWeekDays(date) : getDays(date);
  const today = new Date();
  const selectDate = getDateFormat(
    selectedDate ? selectedDate : today,
    'YYYY-MM-DD'
  );

  const selectedIndex = days.findIndex((day) => day.date === selectDate);
  const [calendar, setCalendar] = useReducer(calendarReducer, {
    today: today.toISOString(),
    days: days,
    startDate: getDateFormat(days[0].date, 'YYYY-MM-DD'),
    endDate: getDateFormat(days[days.length - 1].date, 'YYYY-MM-DD'),
    currentDate: dayjs(today).date(1).format('YYYY-MM-DD'),
    currentMonth: getDateFormat(today, 'M'),
    currentYear: getDateFormat(today, 'YYYY'),
    currentDay: getDateFormat(today, 'DD'),
    selected: {
      index: selectedIndex,
      day: selectDate,
      week: getWeek(selectedDate ? selectedDate : today),
      month: getMonth(selectedDate ? selectedDate : today) + 1,
      year: getYear(selectedDate ? selectedDate : today),
    },
  });

  /**
   * Calendar Handler 제공
   * - 달력을 바꾸는 동작은 calendarReducer 에서 처리
   * - API를 다루는 부분은 handler 에서 처리
   */
  const selectDay = async (value: dayjs.ConfigType) => {
    if (calendar.currentMonth !== `${getMonth(value) + 1}`) {
      setDataLoading(true);

      const { startDate, endDate } = getStartEndDate(dayjs(value));
      setCalendar({ type: CalendarDayActionType.SET_SELECTED_DAY, value });
      const year = getYear(value);

      const [todos, holidays] = await Promise.all([
        getTodo({ startDate, endDate }),
        searchHolidays(year),
      ]);

      setCalendar({
        type: CalendarDayActionType.SET_EVENTS,
        value: todos,
        groups: visibleGroups,
      });
      setCalendar({
        type: CalendarDayActionType.SET_HOLIDAYS,
        value: holidays,
      });

      setDataLoading(false);
    } else {
      setCalendar({ type: CalendarDayActionType.SET_SELECTED_DAY, value });
    }

    return calendar;
  };

  const refereshDays = async (mode: AppMode) => {
    setDataLoading(true);

    const selectedDay = calendar.selected.day || dayjs();
    setCalendar({ type: CalendarDayActionType.SET_DAYS, value: selectedDay, mode });

    const { startDate, endDate } = getStartEndDate(dayjs(selectedDay));
    setCalendar({ type: CalendarDayActionType.SET_SELECTED_DAY, value: selectedDay });
    const year = getYear(selectedDay);

    const [todos, holidays] = await Promise.all([
      getTodo({ startDate, endDate }),
      searchHolidays(year),
    ]);

    setCalendar({
      type: CalendarDayActionType.SET_EVENTS,
      value: todos,
      groups: visibleGroups,
    });
    setCalendar({
      type: CalendarDayActionType.SET_HOLIDAYS,
      value: holidays,
    });

    setDataLoading(false);
  }

  const loadDay = async (value: string[]) => {
    setDataLoading(true);

    const startDate = getDateFormat(calendar.startDate, 'YYYYMMDD');
    const endDate = getDateFormat(calendar.endDate, 'YYYYMMDD');

    const todos = await getTodo({ startDate, endDate });

    setCalendar({
      type: CalendarDayActionType.SET_EVENTS,
      value: todos,
      groups: value,
    });

    setDataLoading(false);
  }

  const changeWeek = async (value: 'prev' | 'next') => {
    setDataLoading(true);

    const changeWeek =
      value === 'prev'
        ? dayjs(calendar.currentDate).subtract(1, 'w')
        : dayjs(calendar.currentDate).add(1, 'w');
    const year = getYear(changeWeek);
    const { startDate, endDate } = getStartEndDate(changeWeek);

    setCalendar({
      type: CalendarDayActionType.CHANGE_WEEK,
      value: changeWeek,
    });

    const [todos, holidays] = await Promise.all([
      getTodo({ startDate, endDate }),
      searchHolidays(year),
    ]);

    setCalendar({
      type: CalendarDayActionType.SET_EVENTS,
      value: todos,
      groups: visibleGroups,
    });
    setCalendar({ type: CalendarDayActionType.SET_HOLIDAYS, value: holidays });

    setDataLoading(false);
  };

  const changeMonth = async (value: 'prev' | 'next') => {
    setDataLoading(true);

    const changeMonth =
      value === 'prev'
        ? dayjs(calendar.currentDate).subtract(1, 'M')
        : dayjs(calendar.currentDate).add(1, 'M');
    const year = getYear(changeMonth);
    const { startDate, endDate } = getStartEndDate(changeMonth);

    setCalendar({
      type: CalendarDayActionType.CHANGE_MONTH,
      value: changeMonth,
    });

    const [todos, holidays] = await Promise.all([
      getTodo({ startDate, endDate }),
      searchHolidays(year),
    ]);

    setCalendar({
      type: CalendarDayActionType.SET_EVENTS,
      value: todos,
      groups: visibleGroups,
    });
    setCalendar({ type: CalendarDayActionType.SET_HOLIDAYS, value: holidays });

    setDataLoading(false);
  };

  return {
    calendar,
    setCalendar,
    selectDay,
    changeWeek,
    changeMonth,
    loadDay,
    refereshDays
  };
}
