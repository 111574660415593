import { AppMode, useAppContext } from "../context/useAppContext";
import { CalendarDayState } from "../hooks/useCalendarDays";
import { modeIcon } from "../utils/util";
import Icon from "./Icon"

export type HeaderProps = {
    calendar: CalendarDayState,
    changeWeek: (type: 'prev' | 'next') => Promise<void>;
    changeMonth: (type: 'prev' | 'next') => Promise<void>;
    refereshDays: (mode: AppMode) => Promise<void>;
}

export default function Header({ calendar, changeWeek, changeMonth, refereshDays }: HeaderProps) {
    const { mode, setMode, dataLoading, setSlideOpen, setSwiping } = useAppContext();
    const modeList = ['MONTH', 'DAY', 'WEEK', 'LIST', 'CHECK']
    const modeIndex = modeList.findIndex((m) => m === mode);
    return (
        <div className="flex justify-between items-stretch h-[50px] box-border px-2 py-3 bg-white text-gray-900 dark:bg-slate-900 dark:text-white">
            <div className="flex gap-x-2 justify-between items-center text-lg font-bold">
                <Icon
                    name="chevronLeft"
                    className="text-gray-800 dark:text-white"
                    size="md"
                    onClick={async () => {
                        if (dataLoading) return;
                        if (mode === 'WEEK') await changeWeek('prev');
                        else await changeMonth('prev');
                    }}
                />
                <div className="w-[100px] text-base text-center">
                    {mode === 'WEEK' ? `${calendar.currentYear}년 ${calendar.selected.week}주` : `${calendar.currentYear}년 ${calendar.currentMonth}월`}

                </div>
                <Icon
                    name="chevronRight"
                    className="text-gray-800 dark:text-white"
                    size="md"
                    onClick={async () => {
                        if (dataLoading) return;
                        if (mode === 'WEEK') await changeWeek('next');
                        else await changeMonth('next');
                    }}
                />
            </div>
            <div className="flex gap-x-3 justify-between">
                <Icon
                    name={modeIcon(mode)}
                    className="text-gray-800 dark:text-white"
                    size="md"
                    onClick={async () => {
                        setMode(modeList[modeIndex + 1 === 5 ? 0 : modeIndex + 1] as AppMode)
                        if (mode === 'DAY') await refereshDays('WEEK');
                    }}
                />
                <Icon name="menu" size="md" onClick={() => {
                    setSwiping(false);
                    setSlideOpen(true);
                }} />
            </div>
        </div>
    )
}