import { AppMode, useAppContext } from "../context/useAppContext";
import { CalendarDayState } from "../hooks/useCalendarDays";
import { modeIcon } from "../utils/util";
import Icon from "./Icon"

export type CheckListHeaderProps = {
    calendar: CalendarDayState,
    refereshDays: (mode: AppMode) => Promise<void>;
}

export default function CheckListHeader({ calendar, refereshDays }: CheckListHeaderProps) {
    const { mode, setMode, setSlideOpen, setSwiping } = useAppContext();
    const modeList = ['MONTH', 'DAY', 'WEEK', 'LIST', 'CHECK']
    const modeIndex = modeList.findIndex((m) => m === mode);
    return (
        <div className="flex justify-between items-stretch h-[50px] box-border px-2 py-3 bg-white text-gray-900 dark:bg-slate-900 dark:text-white">
            <div className="ml-2 flex gap-x-2 justify-between items-center text-sm font-bold">
                <h1>할 일 체크 리스트</h1>
                {/* <input
                        type="text"
                        name="phone-number"
                        id="phone-number"
                        className="w-40 h-6 rounded-md border-0 py-1.5 text-gray-800 dark:text-white dark:bg-slate-800"
                        placeholder="검색어 입력"
                    /> */}
            </div>
            <div className="flex gap-x-3 justify-between">
                <Icon
                    name={modeIcon(mode)}
                    className="text-gray-800 dark:text-white"
                    size="md"
                    onClick={async () => {
                        if (mode === 'CHECK') await refereshDays(modeList[modeIndex + 1 === 5 ? 0 : modeIndex + 1] as AppMode);
                        setMode(modeList[modeIndex + 1 === 5 ? 0 : modeIndex + 1] as AppMode)
                    }}
                />
                <Icon name="menu" size="md" onClick={() => {
                    setSwiping(false);
                    setSlideOpen(true);
                }} />
            </div>
        </div>
    )
}