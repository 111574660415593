import clsx from "clsx"
import { motion, useAnimation } from 'framer-motion';
import Icon from "./Icon";

export type ButtonProps = {
    text: string
    type: 'primary' | 'cancel' | 'delete' | 'none'
    buttonType?: "button" | "submit" | "reset"
    size?: 'sm' | 'md' | 'lg'
    containerClassNames?: string
    classNames?: string
    disabled?: boolean
    isLoading?: boolean
    onClick?: (...args: any) => void;
}

export default function Button({
    text,
    type = 'primary',
    buttonType = 'button',
    size = 'md',
    containerClassNames = '',
    classNames = '',
    disabled = false,
    isLoading = false,
    onClick,
}: ButtonProps) {
    const containerClasses = clsx(
        'flex justify-center',
        containerClassNames
    );

    const buttonClasses = clsx(
        'flex justify-center items-center',
        classNames,
        {
            'text-gray-800 dark:text-white': type !== 'none',
            'bg-primary-500 hover:bg-primary-400 focus-visible:outline focus-visible:outline-primary-500': type === 'primary',
            'bg-gray-500 hover:bg-gray-400 focus-visible:outline focus-visible:outline-gray-500': type === 'cancel',
            'rounded-md text-md px-3.5 py-2.5': size === 'md',
            'opacity-40 cursor-not-allowed': disabled
        }
    );

    return (
        <motion.div whileTap={{ scale: 0.9 }} className={containerClasses}>
            <button
                type={buttonType}
                className={buttonClasses}
                disabled={disabled}
                onClick={onClick}
            >
                {isLoading && <Icon name="outlineLoading" size="sm" className="animate-spin mr-2" />}
                {text}
            </button>
        </motion.div>
    )
}