
export default function DayHeader() {
    return (
        <div className="h-[25px] flex-grow text-center border-b border-gray-300 dark:border-gray-600 grid grid-cols-7 text-xs leading-6 bg-white text-gray-800 dark:bg-slate-900 dark:text-white">
            <div className="text-red-500">일</div>
            <div>월</div>
            <div>화</div>
            <div>수</div>
            <div>목</div>
            <div>금</div>
            <div>토</div>
        </div>
    )
}