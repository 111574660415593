import { useAppContext } from '../context/useAppContext';
import clsx from 'clsx';
import Icon from './Icon';

export type TodoInfoFormProps = {
  date: string;
  title: string;
  isHoliday: string;
};

export default function TodoInfoForm({
  date,
  title,
  isHoliday
}: TodoInfoFormProps) {
  const { setModalOpen } = useAppContext();

  const inputContainerClasses = clsx('flex items-center border-box mb-4');
  const inputClasses = clsx(
    'text-md flex-grow border-l-0 border-t-0 border-r-0 border-b border-b-gray-600 bg-white dark:bg-slate-800 focus:ring-0 focus:ring-offset-0'
  );

  return (
    <form className="flex-1 p-4 overflow-auto">
      <div className="flex justify-between items-center mb-4">
        <Icon
          name="arrowLeft"
          size="md"
          className="text-gray-900 dark:text-white"
          onClick={() => setModalOpen(false)}
        />
        <h1 className="text-base font-bold py-2 text-gray-900 dark:text-gray-200">
          기념일 정보
        </h1>
        <div>
        </div>
      </div>
      <div className={inputContainerClasses}>
        <input
          id="title"
          name="title"
          type="text"
          className={inputClasses}
          defaultValue={title}
          readOnly
          required
          autoFocus
        />
      </div>
      <div className={inputContainerClasses}>
        <div className="flex justify-center w-[40px]">
          <Icon
            name="calendar"
            size="sm"
            className={isHoliday === 'true' ? 'text-red-500' : 'text-gray-900 dark:text-white'}
          />
        </div>
        <input
          id="date"
          name="date"
          type="date"
          className={inputClasses}
          defaultValue={date}
          required
          readOnly
          pattern="\d{4}-\d{2}-\d{2}"
        />
      </div>
    </form>
  );
}
