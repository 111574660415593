import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  Dispatch,
} from 'react';
import { useAppContext } from '../context/useAppContext';
import {
  CalendarAction,
  CalendarDayActionType,
  CalendarDayState,
} from '../hooks/useCalendarDays';
import useHeaderTemplates from '../hooks/useHeaderTemplate';
import useOptionsTemplate from '../hooks/useOptionsTemplate';
import {
  MASTER_TEAMPLTE,
  DETAIL_TEMPLATE,
  TODO_HEADERS_TEAMPLTE,
  TODO_OPTIONS_TEMPLATE,
  EMPTY_TEMPLATE,
  DETAIL_FIELD,
} from '../templates/todoList';
import {
  getAddDate,
  getDateFormat,
  getSubtractDate,
  getYear,
} from '../utils/date';
import RealTouch from 'realgrid-touch';
import 'realgrid-touch/dist/realgrid-touch-style.css';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import { ITodoItem, completeTodo } from '../api/todos';
import { useToast } from '../hooks/useToast';
import {
  TodoDayActionType,
  TodoHandlerType,
  useTodoDays,
} from '../hooks/useTodoDays';
import { sleep } from '../utils/util';

export type Details = {
  yearMonth: string;
  date: string;
  day: string;
  dd: string;
  groupId: string;
  todoId: string;
  title: string;
  content: string;
  color: string;
  firstLine: boolean;
  lastLine: boolean;
};

export type TouchTodoListHandler = {
  setCurrentMonth: () => Promise<void>;
  setCreateCallbackHandler: () => void;
};

export type TouchTodoListProps = {
  calendar: CalendarDayState;
  setCalendar: Dispatch<CalendarAction>;
  selectDay?: (value: dayjs.ConfigType) => Promise<CalendarDayState>;
  setFormType: React.Dispatch<'create' | 'update' | 'info'>;
  setUpdateForm: React.Dispatch<any>;
  setCreateCallback: React.Dispatch<any>;
};

const RealTouchTodoList = forwardRef<TouchTodoListHandler, TouchTodoListProps>(
  ({ calendar, setCalendar, selectDay, setFormType, setUpdateForm, setCreateCallback }, ref) => {
    const touchRef = useRef<HTMLDivElement>(null);
    const {
      clientWindow,
      theme,
      showHoliday,
      setDataLoading,
      group,
      visibleGroups,
      setModalOpen,
    } = useAppContext();
    const { width, height } = clientWindow;
    const { todo, setTodo, addEvents, refereshTodo, addHolidays, refereshHolidays, syncTodo } =
      useTodoDays();
    const isFirstLoad = useRef(true);
    const [startMonth, setStartMonth] = useState(dayjs());
    const [endMonth, setEndMonth] = useState(dayjs());
    const [findId, setFindId] = useState('');
    const [list, setList] = useState<RealTouch.RtListControl | null>(null);
    const master = useRef<RealTouch.RtListData | null>(null);
    const detail = useRef<RealTouch.RtListData | null>(null);

    const { toastPromise } = useToast();

    useImperativeHandle(ref, () => ({
      setCreateCallbackHandler: () => {
        createCallbackHandler();
      },
      setCurrentMonth: async () => {
        list?.stopFling();
        setTimeout(async () => await setCurrentMonthIndex(), 50);
      },
    }));

    function createCallbackHandler() {
      setCreateCallback({
        handler: async (date: string, item: ITodoItem) => {
          if (dayjs(date).diff(startMonth, 'day') >= 0 && dayjs(endMonth).diff(date, 'day') >= 0) {
            const yearMonth = getDateFormat(date, 'YYYYMM');
            await refereshTodo([yearMonth], item.todoId, 'new');
            setFindId(item.todoId);
          } else return;
        }
      })
    }

    async function setCurrentMonthIndex() {
      const today = dayjs();
      const yearMonth = getDateFormat(today, 'YYYYMM');

      if (dayjs(today).diff(startMonth, 'day') >= 0 && dayjs(endMonth).diff(today, 'day') >= 0) {
        const details = detail.current?.getAllValues();

        if (!details) return;
        const dayLength = details.filter((detail) => {
          return detail.yearMonth < yearMonth;
        }).length;

        const monthIndex = master.current?.findRow({
          yearMonth,
        });

        if (!list || monthIndex === -1) return;

        list.scrollIndex = dayLength + monthIndex;
      } else {
        const selectStartMonth = getSubtractDate(today, 6, 'M');
        const selectEndMonth = getAddDate(today, 6, 'M');

        setStartMonth(selectStartMonth.date(1));
        setEndMonth(selectEndMonth.endOf('M'));

        setFindId('')
        await syncTodo(selectStartMonth, selectEndMonth);
        await refereshHolidays();
      }

      setCalendar({ type: CalendarDayActionType.SET_SELECTED_DAY, value: today });
    }

    const [headerTemplate, setHeaderTemplate] = useHeaderTemplates({
      initialProp: TODO_HEADERS_TEAMPLTE,
    });

    const { optionsTemplate, setOptions } = useOptionsTemplate({
      initialProp: TODO_OPTIONS_TEMPLATE,
    });

    const config = {
      props: {
        numberFormat: ',',
        templates: {
          header: headerTemplate,
          master: MASTER_TEAMPLTE,
          detail: DETAIL_TEMPLATE,
          empty: EMPTY_TEMPLATE,
        },
      },
      options: optionsTemplate,
    };

    useEffect(() => {
      if (todo.length > 0 && list) {
        master.current?.loadData(
          todo.map((t) => {
            return {
              yearMonth: t.yearMonth,
              yearMonthDate:
                t.yearMonth.substring(0, 4) +
                '년 ' +
                t.yearMonth.substring(4, 6) +
                '월',
            };
          })
        );

        const details: Details[] = [];

        todo.forEach((t) => {
          t.items.forEach((todo) => {
            const color =
              group.items.find((g) => g.groupId === todo.groupId)?.color ||
              'gray';
            details.push({
              ...todo,
              day:
                todo.groupId === 'empty' ? '' : getDateFormat(todo.date, 'D'),
              dd:
                todo.groupId === 'empty' ? '' : getDateFormat(todo.date, 'dd'),
              color,
              firstLine: todo.firstLine,
              lastLine: todo.lastLine,
            });
          });

          t.holidays.forEach((holiday) => {
            details.push({
              ...holiday,
              day: getDateFormat(holiday.date, 'D'),
              dd: getDateFormat(holiday.date, 'dd'),
              color: holiday.isHoliday ? 'red' : '#6b7280',
              firstLine: holiday.firstLine,
              lastLine: holiday.lastLine,
            });
          });
        });

        const sortDetails = details.sort(
          (a, b) =>
            Number(getDateFormat(a.date, 'YYYYMMDD')) -
            Number(getDateFormat(b.date, 'YYYYMMDD'))
        );

        detail.current?.loadData(sortDetails);

        master.current?.createView('master').sort(['yearMonth'], true).build();
        detail.current?.createView('detail').sort(['date'], true).build();

        const data = RealTouch.createDataLink('main', master.current!, {
          data: detail.current!,
          keyFields: ['yearMonth'],
        });

        list.onScroll = async (args) => {
          if (isFirstLoad.current) {
            isFirstLoad.current = false;
            return;
          }

          if (args && args?.top > -1) {
            if (args.top === 0) {
              setDataLoading(true);

              setFindId('');
              setStartMonth(startMonth.subtract(6, 'M'));
              const findStartMonth = startMonth.subtract(6, 'M').date(1);
              const findEndMonth = startMonth.subtract(1, 'M').endOf('M');

              await addEvents(
                findStartMonth,
                findEndMonth,
                TodoHandlerType.ADD
              );

              const startYear = getYear(findStartMonth);
              const endYear = getYear(findEndMonth);
              const years = [startYear];

              if (startYear !== endYear) years.push(endYear);
              await addHolidays(
                years,
                findStartMonth,
                findEndMonth,
                TodoHandlerType.ADD
              );

              await sleep(200);
              setDataLoading(false);

              list.showToast({
                message: '',
                html: `<div class="text-sm text-gray-800 bg-gray-100 dark:text-white dark:bg-slate-700">
                      <span class="font-bold">
                        ${findStartMonth.format(
                  'YYYY년 MM월'
                )} ~ ${findEndMonth.format('YYYY년 MM월')}
                      </span>
                      <br>
                      일정을 불러왔습니다.
                    </div>`,
                position: 'top' as any,
                margin: 30,
                duration: 2000,
              });
            }

            const topDate = list?.data.getValue(list.topRow, 'date');
            if (topDate)
              setCalendar({
                type: CalendarDayActionType.SET_SELECTED_DAY,
                value: topDate,
              });
          }
        };

        list.options.row.updateTemplate(
          'detail',
          'check',
          'editor.onClick',
          async (args) => {
            const { row, control } = args;
            const todoId = control.data.getValue(row, 'todoId');
            const date = control.data.getValue(row, 'date');

            if (!todoId) return;
            try {
              setDataLoading(true);
              await toastPromise(
                completeTodo({
                  todoId: todoId,
                  date: getDateFormat(date, 'YYYYMMDD'),
                  completed: true,
                }),
                '완료 처리중입니다.',
                '할 일이 완료되었습니다.'
              );

              setFindId('');
              setCalendar({
                type: CalendarDayActionType.SET_SELECTED_DAY,
                value: date,
              });
              setTodo({ type: TodoDayActionType.REMOVE_TODO, todoId, date });
            } catch (error) {
              console.log(error);
            } finally {
              setDataLoading(false);
            }
          }
        );
        list.options.row.updateTemplate(
          'detail',
          'check',
          'editor.styleCallback',
          (args) => {
            if (args.value === 'holiday' || args.value === 'empty') {
              return {
                display: 'none',
              };
            } else {
              return {
                display: 'block',
              };
            }
          }
        );

        list.onRowClick = async (args) => {

          if (!args) return;

          const { row } = args;
          const todoId = data.getValue(row, 'todoId');

          if (!todoId) return;

          // 일정 X
          if (todoId === 'empty') {
            setFormType('create');
            setCalendar({ type: CalendarDayActionType.SET_SELECTED_DAY, value: data.getValue(row, 'date') });
            createCallbackHandler();
            setModalOpen(true);
            return;
          }

          // 기념일
          if (todoId === 'holiday') {
            setFormType('info');
            setUpdateForm({
              date: data.getValue(row, 'date'),
              title: data.getValue(row, 'title'),
              isHoliday: data.getValue(row, 'isHoliday'),
            });
            setModalOpen(true);
          } else {
            setFormType('update');
            setUpdateForm({
              groupId: data.getValue(row, 'groupId'),
              todoId: data.getValue(row, 'todoId'),
              date: data.getValue(row, 'date'),
              title: data.getValue(row, 'title'),
              content: data.getValue(row, 'content'),
              completed: false,
              updateCallback: async (date: string, item: ITodoItem) => {
                if (date === data.getValue(row, 'date')) {
                  setTodo({ type: TodoDayActionType.UPDATE_TODO, date, todo: item });
                  const findRow = detail.current?.findRow({ todoId: item.todoId }) || -1;

                  const color = group.items.find((g) => g.groupId === item.groupId)?.color ||
                    'gray';

                  detail.current?.updateRow(findRow, {
                    title: item.title,
                    content: item.content,
                    groupId: item.groupId,
                    color,
                    state: 'update'
                  }, false, true)
                } else {
                  setDataLoading(true);
                  const refereshYearMonths = [data.getValue(row, 'yearMonth')];

                  // 지금 불러와진 데이터 범위 안이라면
                  if (dayjs(date).diff(startMonth, 'day') >= 0 && dayjs(endMonth).diff(date, 'day') >= 0) {
                    if (refereshYearMonths[0] !== getDateFormat(date, 'YYYYMM')) refereshYearMonths.push(getDateFormat(date, 'YYYYMM'));
                  }

                  await refereshTodo(refereshYearMonths, item.todoId, 'update');

                  if (refereshYearMonths.length > 1) setFindId(item.todoId);
                  else setFindId('');

                  setDataLoading(false);
                }
              },
              deleteCallback: (date: string, todoId: string) => {
                setFindId('');
                setTodo({
                  type: TodoDayActionType.REMOVE_TODO,
                  date,
                  todoId
                });
              }
            });
            setModalOpen(true);
          }
        };

        list.onLastRowRevealed = async (args) => {
          setDataLoading(true);

          setFindId('none');
          setEndMonth(endMonth.add(6, 'M'));
          const findStartMonth = endMonth.add(1, 'M').date(1);
          const findEndMonth = endMonth.add(6, 'M').endOf('M');

          await addEvents(findStartMonth, findEndMonth, TodoHandlerType.ADD);

          const startYear = getYear(findStartMonth);
          const endYear = getYear(findEndMonth);
          const years = [startYear];

          if (startYear !== endYear) years.push(endYear);
          await addHolidays(
            years,
            findStartMonth,
            findEndMonth,
            TodoHandlerType.ADD
          );

          await sleep(200);
          setDataLoading(false);

          list.showToast({
            message: '',
            html: `<div class="text-sm text-gray-800 bg-gray-100 dark:text-white dark:bg-slate-700">
                  <span class="font-bold">
                    ${findStartMonth.format(
              'YYYY년 MM월'
            )} ~ ${findEndMonth.format('YYYY년 MM월')}
                  </span>
                  <br>
                  일정을 불러왔습니다.
                </div>`,
            position: 'bottom' as any,
            margin: 60,
            duration: 2000,
          });
        };

        list.data = data;
        list.dataGroupBy({});

        const today = calendar.selected.day
          ? dayjs(calendar.selected.day)
          : dayjs();
        const dayLength = details.filter((detail) => {
          return detail.yearMonth < today.format('YYYYMM');
        }).length;

        const monthIndex = data.findRow(
          {
            yearMonth: today.format('YYYYMM'),
          },
          0,
          data.rowCount
        );

        if (findId === 'none') {

        } else if (findId === '') {
          list.scrollIndex = dayLength + monthIndex;
        } else {
          if (!detail.current && !master.current) return;
          const detailRow = detail.current?.findRow({ todoId: findId });
          if (!detailRow) return;

          const masterRow = master.current?.findRow({ yearMonth: detail.current?.getValue(detailRow, 'yearMonth') });
          if (!masterRow) return;

          list.scrollIndex = detailRow + masterRow + 1;
        }
      }
    }, [todo]);

    useEffect(() => {
      async function refereshEvents() {
        await addEvents(startMonth, endMonth, TodoHandlerType.SET);
      }

      refereshEvents();
    }, [visibleGroups]);

    useEffect(() => {
      async function refereshHoliday() {
        setDataLoading(true);
        await refereshHolidays();
        await sleep(200);
        setDataLoading(false);
      }

      if (detail.current) {
        refereshHoliday();
      }
    }, [showHoliday]);

    // 높이 변경 대응
    useEffect(() => {
      setTimeout(() => touchRefreshLayout(), 0);
    }, [height]);

    // 테마 변경 대응
    useEffect(() => {
      if (!list) return;

      list.options.header.style.background =
        theme === 'dark' ? '#1e293b' : '#f3f4f6';
      list.options.header.captionColor = theme === 'dark' ? 'white' : '#111827';
      list.options.row.style.background =
        theme === 'dark' ? '#0f172a' : 'white';
      list.options.row.style.color = theme === 'dark' ? 'white' : '#1f2937';
    }, [theme]);

    // 첫 로드 시 설정
    useEffect(() => {
      async function getTodos() {
        setDataLoading(true);

        await addEvents(selectStartMonth, selectEndMonth, TodoHandlerType.SET);

        const startYear = getYear(selectStartMonth);
        const endYear = getYear(selectEndMonth);
        const years = [startYear];

        if (startYear !== endYear) years.push(endYear);
        await addHolidays(
          years,
          selectStartMonth,
          selectEndMonth,
          TodoHandlerType.SET
        );

        await sleep(500);
        setDataLoading(false);
      }

      const selectedDay = calendar?.selected.day
        ? calendar?.selected.day
        : new Date();

      const selectStartMonth = getSubtractDate(selectedDay, 6, 'M');
      const selectEndMonth = getAddDate(selectedDay, 6, 'M');

      setStartMonth(selectStartMonth.date(1));
      setEndMonth(selectEndMonth.endOf('M'));

      const initialData = Array.from(Array(13).keys()).map((d, i) => {
        return {
          yearMonth: selectStartMonth.add(i, 'M').format('YYYYMM'),
          yearMonthDate: selectEndMonth.add(i, 'M').format('YYYY년 MM월'),
        };
      });

      const masterData = RealTouch.createListData(
        'master',
        {
          fields: [
            { name: 'yearMonth' },
            { name: 'yearMonthDate' }
          ]
        },
        {
          values: initialData,
        }
      );
      master.current = masterData;

      const detailData = RealTouch.createListData(
        'detail',
        {
          fields: DETAIL_FIELD,
        },
        {
          values: initialData.map((data) => {
            return {
              yearMonth: data.yearMonth,
              date: '',
              day: '',
              dd: '',
              groupId: 'empty',
              todoId: 'empty',
              title: '일정이 존재하지 않습니다.',
              content: '',
              isHoliday: false,
              color: '#4b5563',
              firstLine: true,
              lastLine: true,
              state: ''
            };
          }),
        }
      );
      detail.current = detailData;

      const listControl = RealTouch.createListControl(
        document,
        touchRef.current!
      );

      master.current?.createView('master').sort(['yearMonth'], true).build();
      detail.current?.createView('detail').sort(['date'], true).build();

      listControl.setConfig(config);
      setList(listControl);

      listControl.options.header.style.background =
        theme === 'dark' ? '#1e293b' : '#f3f4f6';
      listControl.options.header.captionColor =
        theme === 'dark' ? 'white' : '#111827';
      listControl.options.row.style.background =
        theme === 'dark' ? '#0f172a' : 'white';
      listControl.options.row.style.color =
        theme === 'dark' ? 'white' : '#1f2937';

      getTodos();

      return () => {
        if (list) {
          list.destroy();
        }

        if (master.current) {
          master.current.destroy();
          master.current = null;
        }

        if (detail.current) {
          detail.current.destroy();
          detail.current = null;
        }

        /* touch destory가 돔을 없애주지 않는 현상이 있다. */
        if (touchRef.current) {
          const children = touchRef.current.children;
          for (let i = 0; i < children.length; i++) {
            children.item(i)?.remove();
          }
        }
      };
    }, []);

    function touchRefreshLayout() {
      if (list) list['$_c'].invalidateLayout();
    }

    function ExpanderStyle() {
      if (theme === 'dark')
        return (
          <style>
            {`
            [type='checkbox']:focus {
              outline: none !important;
              outline-offset: 0 !important;
              box-shadow: none !important;
            }
            .rtc-body {
              background: #0f172a;
            }
            .rtc-item-expander {
              stroke: white !important;
            }
            .-rtc-layout- {
              left: 0 !important;
            }
            .rtc-row[data-touched='1'] {
              background: #1e293b !important;
            }
            .rtc-toast {
              background: #334155 !important;
            }
          `}
          </style>
        );
      else
        return (
          <style>
            {`
            [type='checkbox']:focus {
              outline: none !important;
              outline-offset: 0 !important;
              box-shadow: none !important;
            }
            .rtc-body {
              background: white;
            }
            .rtc-item-expander {
              stroke: #1f2937 !important;
            }
            .-rtc-layout- {
              left: 0 !important;
            }
            .rtc-toast {
                background-color: #f3f4f6 !important;
            }
          }
        `}
          </style>
        );
    }

    return (
      <>
        {ExpanderStyle()}
        <motion.div
          id="realgrid-touch"
          ref={(node) => {
            if (touchRef) (touchRef.current as any) = node;
          }}
          className="w-full h-full dark:bg-[#4d4e52]"
        ></motion.div>
      </>
    );
  }
);

export default RealTouchTodoList;
