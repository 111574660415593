import { getDateFormat } from '../utils/date';

const theme = localStorage.getItem('theme') || 'light';

export const DETAIL_FIELD = [
  { name: 'yearMonth' },
  { name: 'date' },
  { name: 'day' },
  { name: 'dd' },
  { name: 'groupId' },
  { name: 'todoId' },
  { name: 'title' },
  { name: 'content' },
  { name: 'isHoliday' },
  { name: 'color' },
  { name: 'firstLine' },
  { name: 'lastLine' },
  { name: 'state' }
]

export const TODO_HEADERS_TEAMPLTE = {
  name: 'header',
  description: 'List header template',
  props: {},
  template: {
    layout: 'hlinear',
    width: '100%',
    itemsArrange: 'center',
    children: [
      {
        layout: 'vlinear',
        width: '100%',
        itemsArrange: 'center',
        children: [
          {
            value: '${@caption;Header}',
            tag: 'caption',
            width: '50%',
            align: '${@caption-align}',
            style: {
              padding: '0 8px 4px 8px',
              fontWeight: 'bold',
              color: '${@caption-color;#333}',
            },
          },
        ],
      },
    ],
  },
};

export const MASTER_TEAMPLTE = {
  template: {
    layout: 'hlinear',
    // style: {
    //   background: theme === 'dark' ? '#0f172a' : 'white',
    //   color: theme === 'dark' ? 'white' : '#1f2937'
    // },
    children: [{
      field: 'yearMonthDate',
      left: '0%',
      style: {
        fontSize: '16px',
        fontWeight: '800',
      },
    }]
  },
  rowStyle: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    borderTop: '1px solid #4b5563',
    borderBottom: '1px solid #4b5563',
    checked: {
      backgroundColor: '#0088ff20'
    }
  }
};

export const DETAIL_TEMPLATE = {
  template: {
    layout: 'hlinear',
    width: '100%',
    left: '0%',
    grow: 1,
    itemGap: 8,
    style: {
      borderBottom: '${border-bottom}'
    },
    children: [{
      renderer: {
        type: 'html',
        html: `<div style="visibility: \${visible};" class="flex flex-col justify-center items-center w-10 h-full border-3 text-xs rounded-md \${top__visible}">
          <span>\${@dd}</span>
          <span class="text-base \${today}">\${@day}</span>
        </div>`,
      },
    }, {
      renderer: {
        type: 'html',
        html: `<div
              class="h-16 w-1.5 border-3 rounded-md"
              style="background: \${@color}"
              >
              </div>`,
      },
    }, {
      id: 'check',
      field: 'todoId',
      editor: {
        type: 'check',
        clickable: true,
        style: {
          outline: 'none',
          borderRadius: '100%',
        },
      }
    },
    {
      layout: 'hlinear',
      width: '100%',
      left: '0%',
      itemsArrange: 'spaceBetween',
      grow: 1,
      children: [
        {
          field: 'title',
          style: {
            fontSize: '14px',
            marginLeft: '4px',
          }
        },
        {
          renderer: {
            type: 'html',
            html: `
            <span class="inline-flex items-center gap-x-1.5 px-2 py-2 text-sm font-bold \${state}">
              \${@state}
            </span>
            `
          }
        }
      ]
    }]
  },
  params: {
    'today': (args) => {
      if (args.values.date === getDateFormat(new Date(), 'YYYY-MM-DD')) return 'w-6 h-6 text-center bg-gray-800 text-white dark:bg-white dark:text-gray-800 rounded-full'
      return ''
    },
    'visible': (args) => {
      if (args.values.firstLine === 'true') return 'visible'
      return 'hidden'
    },
    'top__visible': (args) => {
      if (args.values.firstLine === 'true') return 'top__visible'
      return ''
    },
    'todo__visible': (args) => {
      if (args.values.todoId === '') return 'none'
      return 'block'
    },
    'state': (args) => {
      if (args.values.state === 'update') return 'text-green-500';
      if (args.values.state === 'new') return 'text-primary-500';
      return 'hidden'
    }
  },
  rowStyle: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
  }
};

export const EMPTY_TEMPLATE = {
  template: {
    layout: "vlinear",
    itemsAlign: "center",
    itemsArrange: 'center',
    itemGap: 20,
    children: [{
      renderer: {
        type: 'html',
        html: '<div class="w-full h-full text-gray-800 dark:text-white bg-white dark:bg-slate-900">데이터가 존재하지 않습니다.</div>'
      }
    }],
    style: {
      padding: '10px'
    }
  }
};

export const TODO_OPTIONS_TEMPLATE = {
  orientation: 'vertical',
  row: {
    template: 'detail',
    borderLine: {
      style: 'none'
    },
    style: {
      background: theme === 'dark' ? '#0f172a' : 'white',
      color: theme === 'dark' ? 'white' : '#1f2937',
    }
  },
  rowInfos: {
    'master': { template: 'master' }
  },
  header: {
    visible: false,
    caption: getDateFormat(new Date(), 'M월 DD일 dd요일'),
    captionAlign: 'left',
    captionColor: 'white',
    template: 'header',
    style: {
      background: theme === 'dark' ? '#0f172a' : 'white',
    },
  },
  emptyPage: {
    template: 'empty'
  },
  footer: {
    visible: false,
  },
  scrollBar: true,
  scrollIndicator: false,
};
