import { getDateFormat } from '../utils/date';

const theme = localStorage.getItem('theme') || 'light';

export const HEADER_TEMPLATE = {
  name: 'header',
  description: 'List header template',
  props: {},
  template: {
    layout: 'hlinear',
    width: '100%',
    itemsArrange: 'center',
    children: [
      {
        layout: 'vlinear',
        width: '100%',
        itemsArrange: 'start',
        children: [
          {
            value: '할 일 그룹',
            tag: 'caption',
            style: {
              color: 'gray',
              paddingLeft: '16px'
            },
          },
        ],
      },
    ],
  },
};

export const EDIT_TEMPLATE = {
  template: {
    layout: 'vlinear',
    height: '100%',
    style: {
      padding: '12px',
    },
    children: [{
      layout: 'form',
      width: '100%',
      itemGap: 16,
      children: [{
        id: 'name',
        field: 'name',
        label: '그룹 명',
        editor: {
          type: 'text',
          // readonly: true,
          onChange: args => {
            console.log(args.editValue);
          }
        }
      }, {
        layout: 'hlinear',
        label: '할 일 그룹색상',
        width: '100%',
        children: [{
          id: 'color',
          field: 'color',
          value: `\${@color}`,
          renderer: {
            type: 'html',
            html: `<svg id="color" class="h-4 w-4" style="fill: \${color}" viewBox="0 0 6 6" aria-hidden="true">
                    <circle cx=3 cy=3 r=3 />
                  </svg>`,
            onClick: (args) => {
              console.log(args);
              console.log(args.editValue);
            },
            clickable: true
          }
        }]
      }]
    }, {
      space: 24
    }, {
      layout: 'vlinear',
      height: '100%',
      width: '100%',
      itemsArrange: 'start',
      style: {
        paddingRight: '4px'
      },
      children: [
        {
          layout: 'hlinear',
          width: '100%',
          itemsArrange: 'start',
          children: [
            {
              id: 'cancel',
              width: '100%',
              renderer: {
                type: 'button',
                label: '취소',
                onClick: (args) => {
                  args.control.closeEditPage(false);
                },
                style: {
                  width: '100%',
                  height: '40px',
                  border: '1px solid #6b7280',
                  borderRadius: '4px'
                }
              }
            }, {
              id: 'save',
              width: '100%',
              renderer: {
                type: 'button',
                label: '저장',
                onClick: (args) => {
                },
                style: {
                  width: '100%',
                  height: '40px',
                  border: '1px solid #06b6d4',
                  borderRadius: '4px'
                }
              }
            }
          ]
        },
        // {
        //   layout: 'hlinear',
        //   grow: 1,
        //   width: '100%',
        //   height: '100%',
        //   itemsArrange: 'end',
        //   children: [
        //     {
        //       id: 'delete',
        //       field: 'default',
        //       width: '100%',
        //       bottom: 0,
        //       renderer: {
        //         type: 'button',
        //         label: '할 일 그룹 삭제',
        //         onClick: (args) => {
        //         },
        //         style: {
        //           width: '100%',
        //           color: '#ef4444',
        //           border: '1px solid #ef4444',
        //           borderRadius: '4px'
        //         }
        //       }
        //     }
        //   ]
        // }
      ]
    }]
  }
};

export const ROW_TEMPLATE = {
  template: {
    layout: 'hlinear',
    // style: {
    //   background: theme === 'dark' ? '#0f172a' : 'white',
    //   color: theme === 'dark' ? 'white' : '#1f2937'
    // },
    style: {
      borderBottom: 'none'
    },
    children: [
      {
        renderer: {
          type: 'spacer',
          space: 8
        }
      },
      {
        width: 16,
        renderer: {
          type: 'html',
          html: `<svg class="h-3 w-3" style="fill: \${@color}" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx=3 cy=3 r=3 />
      </svg>`
        }
      }, {
        renderer: {
          type: 'spacer',
          space: 4
        }
      }, {
        field: 'name',
        left: '0%',
        style: {
          fontSize: '16px',
          fontWeight: '800',
          alignItems: 'center'
        },
        renderer: {
          wrap: true
        }
      }]
  },
  rowStyle: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    checked: {
      backgroundColor: '#0088ff20'
    }
  }
};

export const EMPTY_TEMPLATE = {
  template: {
    layout: "vlinear",
    itemsAlign: "center",
    itemsArrange: 'center',
    itemGap: 20,
    children: [{
      renderer: {
        type: 'html',
        html: '<div class="w-full h-full text-gray-800 dark:text-white bg-white dark:bg-slate-900">데이터가 존재하지 않습니다.</div>'
      }
    }],
    style: {
      padding: '10px'
    }
  }
};

export const OPTIONS_TEMPLATE = {
  orientation: 'vertical',
  row: {
    template: 'row',
    borderLine: {
      style: 'none'
    },
    style: {
      background: theme === 'dark' ? '#0f172a' : 'white',
      color: theme === 'dark' ? 'white' : '#1f2937',
    },
    clickAction: 'edit'
  },
  actionBar: {
    visible: true,
    action: 'move'
  },
  header: {
    visible: false,
    template: 'header',
    style: {
      background: theme === 'dark' ? '#0f172a' : 'white',
    },
  },
  emptyPage: {
    template: 'empty'
  },
  editPage: {
    header: {
      visible: false
    },
    style: {
      background: theme === 'dark' ? '#0f172a' : 'white',
      color: theme === 'dark' ? 'white' : '#1f2937',
    },
    template: 'edit',
    templateParams: {
      "color": (args) => {
        const color = args.control.data.getValue(args.row, 'color') || 'gray';
        return color;
      },
    }
  },
  footer: {
    visible: false,
  },
  scrollBar: true,
  scrollIndicator: false,
};
