import { Fragment, useState, Dispatch } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Icon from './Icon';
import clsx from 'clsx';
import { IGroupItem } from '../api/groups';

export type ListBoxProps = {
  selected: string;
  setSelected: Dispatch<any>;
  groups: IGroupItem[];
};
export default function ListBox({
  selected,
  setSelected,
  groups,
}: ListBoxProps) {
  const select = groups.find((g) => g.groupId === selected);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-md text-gray-900 dark:text-white bg-white dark:bg-slate-700 py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500 text-sm">
              <span className="flex items-center">
                <span
                  className="h-4 w-4 flex-shrink-0 rounded-full"
                  style={{
                    background: select?.color || 'bg-gray-500',
                  }}
                />
                <span className="ml-3 block truncate">{select?.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <Icon
                  name="chevronDown"
                  size="md"
                  className="text-gray-900 dark:text-white"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={clsx(
                  'absolute min-w-[160px] text-xs z-10 mt-1 max-h-56 w-full overflow-auto rounded-md border dark:border-slate-600',
                  'bg-white dark:bg-slate-700 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none'
                )}>
                {groups.map((g) => (
                  <Listbox.Option
                    key={g.groupId}
                    className={({ active }) =>
                      clsx(
                        active ? 'text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={g.groupId}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="w-full text-sm flex items-center text-gray-900 dark:text-white">
                          <span
                            className="h-4 w-4 flex-shrink-1 rounded-full"
                            style={{
                              background: groups.find((group) => group.groupId === g.groupId)?.color || 'bg-gray-500',
                            }}
                          />
                          <span
                            className={clsx(
                              selected ? 'font-semibold' : 'font-normal',
                              'mx-3 block truncate'
                            )}
                          >
                            {g.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={clsx(
                              active ? 'text-white' : 'text-primary-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <Icon name="check" size="sm" className="text-green-500" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
