import './App.css';
import { Container } from './containers/Container';
import { AppContextProvider } from './context/useAppContext';
import { Toaster } from 'react-hot-toast';

function App() {

  return (
    <AppContextProvider>
      <Container />
      <Toaster
        position='bottom-center'
        containerStyle={{
          bottom: 60,
          padding: 0,
          alignItems: 'flex-start'
        }}
      />
    </AppContextProvider>
  );
}

export default App;
