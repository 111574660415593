import { API } from 'aws-amplify';
import axios from 'axios';

export interface IHoliday {
    category: string;
    items: IHolidayItem[]
    createdAt: string;
    updatedAt: string;
}

export interface IHolidayItem {
    date: string;
    name: string;
    isHoliday: boolean;
}

export type CreateHolidayParmas = {
    year: number;
    holidays: IHolidayItem[];
}

export type HolidayResponse = {
    dataKind: string;
    dateName: string;
    isHoliday: "N" | "Y";
    locdate: number;
    seq: number;
}

export type HolidaysGroup = {
    year: number;
    holidays: IHolidayItem[]
}

export async function getHolidays(year: number): Promise<IHoliday> {
    return API.get("holidays", `/holidays/${year}`, {});
}

export async function createHolidays(params: CreateHolidayParmas): Promise<IHoliday> {
    return API.put("holidays", `/holidays`, {
        body: {
            ...params
        }
    });
}

export async function getAnniversaries(year: number): Promise<HolidayResponse[]> {
    try {
        const { data } = await axios.get('https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getAnniversaryInfo', {
            params: {
                serviceKey: process.env.REACT_APP_DATA_GO_SERVICE_KEY,
                pageNo: 1,
                numOfRows: 100,
                solYear: year
            }
        });

        if (!data.response?.body) return [];

        return data.response.body.items.item;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export async function getDataGoHolidays(year: number): Promise<HolidayResponse[]> {
    try {
        const { data } = await axios.get('https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getRestDeInfo', {
            params: {
                serviceKey: process.env.REACT_APP_DATA_GO_SERVICE_KEY,
                solYear: year,
                pageNo: 1,
                numOfRows: 100,
            }
        });

        if (!data.response?.body) return [];

        return data.response.body.items.item;
    } catch (error) {
        console.log(error);
        return [];
    }
}
