import { useEffect, useState } from "react";
import set from 'lodash/fp/set';
import { useAppContext } from "../context/useAppContext";

export type UseRowTemplateProps = {
    initialProp?: { [key: string]: any }
}

export default function useRowTemplate({ initialProp }: UseRowTemplateProps): [
    UseRowTemplateProps,
    (key: string, value: any) => void
] {
    const { theme } = useAppContext();
    const defaultRowTemplate = {
        template: {
            layout: "vlinear",
            children: [],
        },
        rowStyle: {
            paddingTop: "11px",
            paddingBottom: "11px",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
    };

    useEffect(() => {
        setRowTemplate((prevOptions) =>
          set(
            'header.style.background',
            theme === 'dark' ? '#1e293b' : 'white',
            prevOptions
          )
        );
    }, [theme]);

    const [rowTemplate, setRowTemplate] = useState<any>(initialProp ? initialProp : defaultRowTemplate);

    return [
        rowTemplate,
        (key, value) => {
            setRowTemplate(
                set(key, value, rowTemplate)
            )
        }
    ];
}