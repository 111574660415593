import { API } from "aws-amplify";

export interface ITodo {
    userId: string;
    category: string;
    yearMonth: string;
    date: string;
    items: ITodoItem[];
}

export interface ITodoItem {
    groupId: string;
    todoId: string;
    title: string;
    content: string;
    completed: boolean;
    createdAt: string;
    updatedAt: string;
}

export type GetTodoParmas = {
    startDate: string;
    endDate: string;
}

export type GetTodoMonthParams = {
    startMonth: string;
    endMonth: string;
}

export type CreateTodoParams = {
    groupId: string;
    title: string;
    date: string;
    content: string;
}

export type UpdateTodoParmas = {
    groupId: string;
    todoId: string;
    date: string;
    updateDate: string;
    title: string;
    content: string;
    completed: boolean;
}

export type CompleteTodoParams = {
    todoId: string;
    date: string;
    completed: boolean;
}

export type deleteTodoParams = {
    todoId: string;
    date: string;
}

export async function createTodo(params: CreateTodoParams): Promise<ITodo> {
    return API.put("todos", "/todos", {
        body: params
    });
}

export async function getTodo(params: GetTodoParmas): Promise<ITodo | ITodo[]> {
    return API.get("todos", "/todos", {
        queryStringParameters: {
            ...params
        }
    });
}

export async function getTodoAll(): Promise<ITodo[]> {
    return API.get("todos", "/todos/all", {});
}

export async function getTodoMonth(params: GetTodoMonthParams): Promise<ITodo[]> {
    return API.get("todos", "/todos/month", {
        queryStringParameters: {
            ...params
        }
    });
}

export async function updateTodo(params: UpdateTodoParmas): Promise<ITodo> {
    return API.post("todos", "/todos", {
        body: params
    });
}

export async function completeTodo(params: CompleteTodoParams): Promise<ITodo> {
    return API.post("todos", "/todos/complete", {
        body: params
    });
}

export async function deleteTodo(params: deleteTodoParams): Promise<ITodo> {
    return API.del("todos", "/todos", {
        body: params
    });
} 