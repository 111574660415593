import { Dispatch, useState } from 'react';
import set from 'lodash/fp/set';
import { useAppContext } from '../context/useAppContext';
import RealTouch from 'realgrid-touch';

export type UseOptionsTemplateProps = {
  initialProp?: { [key: string]: any };
};

export type UseOptionsTemplate = {
  optionsTemplate: any;
  setOptionsTemplate: Dispatch<any>;
  setOptions: (key: string, value: any) => void;
}

export default function useOptionsTemplate({
  initialProp,
}: UseOptionsTemplateProps): 
UseOptionsTemplate
{
  const { theme } = useAppContext();

  const defaultOptionsTemplate = {
    rowBar: {
      visible: true,
      display: 'order',
      order: {
        suffix: '.',
        style: { fontSize: '19px', color: '#777' },
      },
    },
    header: {
      visible: true,
      caption: '',
      captionAlign: 'center',
      style: {
        background: theme === 'dark' ? '#4d4e52' : 'white',
      },
      template: 'header',
    },
    footer: {
      visible: true,
      template: 'footer',
      buttons: [
        {
          name: 'delete',
          label: '삭제',
          position: 'tail',
          visible: false,
        },
      ],
      layoutParams: {
        checkVisible: (args: any) => {
          return args.control?.state === 'edit';
        },
      },
    },
    commandBox: {
      mode: 'overlap',
    },
  };

  const setOptions = (key: string, value: any) => {
    const newOptions = set(key, value, optionsTemplate);
    setOptionsTemplate(newOptions);
  };

  const [optionsTemplate, setOptionsTemplate] = useState<any>(
    initialProp ? initialProp : defaultOptionsTemplate
  );

  return {
    optionsTemplate,
    setOptionsTemplate,
    setOptions
  };
}
