import { useCallback } from 'react';
import clsx from 'clsx';
import {
  CalendarDay,
  CalendarDayState,
} from '../hooks/useCalendarDays';
import { getDateFormat, isSameDate } from '../utils/date';
import { useAppContext } from '../context/useAppContext';
import dayjs from 'dayjs';

export type CalendarProps = {
  calendar: CalendarDayState;
  selectDay: (value: dayjs.ConfigType) => Promise<CalendarDayState>;
  height: number;
};

export default function Calendar({
  calendar,
  selectDay,
  height,
}: CalendarProps) {
  const { clientWindow, group, mode, setMode } = useAppContext();
  const { width } = clientWindow;
  const todoMaxDisplayLength = Math.floor((height - 144) / 120) - 1;
  const dotMaxDisplayLength = Math.floor(((width / 7) - 8) / 8);

  const getEventList = useCallback(
    (day: CalendarDay) => {
      const { events: dayEvents, holidays } = day;
      const events = dayEvents.filter((event) => event.completed !== true);
      if (height >= 408 && (holidays.length > 0 || events.length > 0) && mode !== 'WEEK') {
        /* 일정을 표시 계산 */
        const eventLength = events.length;
        const remainLength = todoMaxDisplayLength - eventLength;
        const holidayLength = remainLength > 0 ? remainLength : 0;
        const holidayRemainLength = holidayLength === 0 ? 0 : remainLength;

        /* 점 일정 표시 계산 */
        let dotEventLength = 0;
        let dotHolidayLength = 0;

        /* event가 남아있을 경우 */
        if (remainLength < 0) {
          const absRemainLength = Math.abs(remainLength);
          dotEventLength = dotMaxDisplayLength < absRemainLength ? todoMaxDisplayLength + dotMaxDisplayLength : todoMaxDisplayLength + absRemainLength;
        }

        // 휴일 남은 수 + 그릴수 있는 만큼
        if (dotMaxDisplayLength - dotEventLength > 0) {
          dotHolidayLength = holidayRemainLength + (dotMaxDisplayLength - dotEventLength);
        }

        return (
          <ol className="mt-0.5 flex flex-col">
            {events.slice(0, todoMaxDisplayLength).map((event) => (
              <li key={event.todoId} className="h-[20px] flex items-center">
                <p
                  className={clsx(
                    'flex-auto truncate font-medium px-1 mx-0.5 lg:mx-2 rounded-sm text-white dark:text-white'
                  )}
                  style={{
                    background: `${group.items.find((g) => g.groupId === event.groupId)?.color}`,
                  }}
                >
                  {event.title}
                </p>
              </li>
            ))}
            {holidays.slice(0, holidayLength).map((holiday) => (
              <li key={holiday.name} className="h-[20px] flex items-center">
                <p
                  className={clsx(
                    'flex-auto truncate font-medium px-1 mx-0.5 lg:mx-2 rounded-sm text-white dark:text-white',
                    holiday.isHoliday
                      ? 'bg-red-500 group-hover:text-red-600'
                      : 'bg-gray-500 group-hover:text-gray-600'
                  )}
                >
                  {holiday.name}
                </p>
              </li>
            ))}
            {/* 남을 할일 점으로 표시 */}
            <div className="h-[20px] flex mx-0.5 gap-x-0.5 justify-center items-center">
              {events.slice(todoMaxDisplayLength, dotEventLength).map((event) => (
                <div
                  key={event.todoId}
                  className={clsx('flex rounded-full w-1.5 h-1.5')}
                  style={{
                    background: `${group.items.find((g) => g.groupId === event.groupId)
                      ?.color
                      }`,
                  }}
                ></div>
              ))}
              {holidays
                .slice(holidayRemainLength, dotHolidayLength)
                .map((holiday) => (
                  <div
                    key={holiday.name}
                    className={clsx(
                      'flex rounded-full w-1.5 h-1.5',
                      holiday.isHoliday ? 'bg-red-500' : 'bg-gray-500'
                    )}
                  ></div>
                ))}
            </div>
          </ol>
        );
      } else {
        /* 점만 표시하는 경우 */
        const maxLength = dotMaxDisplayLength > 6 ? 6 : dotMaxDisplayLength;
        const remainLength = maxLength - events.length;
        const holidayLength = remainLength > 0 ? remainLength : 0;
        return (
          <div className="flex gap-x-0.5 justify-center">
            {events.slice(0, maxLength).map((event) => (
              <div
                key={event.todoId}
                className={clsx('rounded-full w-1.5 h-1.5')}
                style={{
                  background: `${group.items.find((g) => g.groupId === event.groupId)?.color}`,
                }}
              ></div>
            ))}
            {holidays.slice(0, holidayLength).map((holiday) => (
              <div
                key={holiday.name}
                className={clsx(
                  'rounded-full w-1.5 h-1.5',
                  holiday.isHoliday ? 'bg-red-500' : 'bg-gray-500'
                )}
              ></div>
            ))}
          </div>
        );
      }
    },
    [height, group, mode]
  );

  return (
    <div className="flex h-full flex-col bg-white dark:bg-slate-900">
      <div className="flex flex-auto flex-col">
        <div className="flex flex-auto bg-gray-200 text-xs text-gray-900 dark:text-white">
          <div className="w-full grid grid-cols-7 grid-rows-6 text-center bg-white dark:bg-slate-900">
            {calendar.days.map((day, index) => {
              const isSunday = parseInt(getDateFormat(day.date, 'd')) === 0;
              const isToday = isSameDate(day.date, calendar.today);
              return (
                <div
                  key={day.date}
                  className={clsx(
                    'relative overflow-hidden bg-white dark:bg-slate-900'
                  )}
                  onClick={async () => {
                    if (mode === 'MONTH') setMode('DAY');
                    await selectDay(day.date);
                  }}
                >
                  <time
                    dateTime={day.date}
                    className={clsx(
                      'my-0.5 flex items-center justify-center text-center text-gray-900 dark:text-white'
                    )}
                  >
                    <span
                      className={clsx(
                        'flex items-center justify-center text-center w-6 h-6',
                        {
                          'rounded-full bg-red-500 text-gray-900':
                            isToday && (isSunday || day.isHoliday),
                        },
                        {
                          'text-red-500':
                            !isToday && (isSunday || day.isHoliday),
                        },
                        {
                          'rounded-full border border-slate-900 dark:border-white':
                            isSameDate(day.date, calendar.selected.day) &&
                            !isToday,
                        },
                        {
                          'rounded-full bg-slate-900 dark:bg-white text-white dark:text-gray-900':
                            isToday && !(isSunday || day.isHoliday),
                        },
                        {
                          'opacity-30':
                            mode !== 'WEEK' && `${getDateFormat(day.date, 'M')}` !==
                            calendar.currentMonth,
                        }
                      )}
                    >
                      {getDateFormat(day.date, 'DD')}
                    </span>
                  </time>
                  {/* 최소 288px 이상 그후로는 120px 늘어날때마다 추가 가능하다. */}
                  {getEventList(day)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
