import {
  SyntheticEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  Dispatch,
  PointerEvent,
  useCallback,
} from 'react';
import { renderToString } from 'react-dom/server';
import { useAppContext } from '../context/useAppContext';
import {
  CalendarAction,
  CalendarDayActionType,
  CalendarDayState,
} from '../hooks/useCalendarDays';
import useHeaderTemplates from '../hooks/useHeaderTemplate';
import useRowTemplate from '../hooks/useRowTemplate';
import useOptionsTemplate from '../hooks/useOptionsTemplate';
import TodoList from './TodoList';
import {
  TODO_HEADERS_TEAMPLTE,
  TODO_ROW_TEAMPLTE,
  TODO_OPTIONS_TEMPLATE,
} from '../templates/singeList';
import { getDateFormat, getYear } from '../utils/date';
import RealTouch from 'realgrid-touch';
import 'realgrid-touch/dist/realgrid-touch-style.css';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import { completeTodo, getTodo } from '../api/todos';
import { useToast } from '../hooks/useToast';

export type TouchSingleListHandle = {
  refereshLayout: () => void;
  getOptionsTemplate: () => { [key: string]: any };
  setOptionsTemplate: (key: string, value: any) => void;
  showFormPanel: () => void;
};

export type TouchSingleListProps = {
  calendar: CalendarDayState;
  setCalendar: Dispatch<CalendarAction>;
  selectDay: (value: dayjs.ConfigType) => Promise<CalendarDayState>;
  setFormType: React.Dispatch<'create' | 'update' | 'info'>;
  setUpdateForm: React.Dispatch<any>;
  setCreateCallback: React.Dispatch<any>;
};

const RealTouchTodoList = forwardRef<
  TouchSingleListHandle,
  TouchSingleListProps
>(
  (
    {
      calendar,
      setCalendar,
      selectDay,
      setFormType,
      setUpdateForm,
      setCreateCallback,
    },
    ref
  ) => {
    const touchRef = useRef<HTMLDivElement>(null);
    const calendarDayRef = useRef<CalendarDayState>(calendar);
    const {
      clientWindow,
      theme,
      setDataLoading,
      searchHolidays,
      visibleGroups,
      group,
      setModalOpen,
    } = useAppContext();
    const { width, height } = clientWindow;
    const [isDragging, setIsDragging] = useState(false);
    const [preventMove, setPreventMove] = useState(false);
    const [list, setList] = useState<RealTouch.RtListControl | null>(null);
    const data = useRef<RealTouch.RtListData | null>(null);
    const touchTopPosition = touchRef.current?.offsetTop;

    const { toastPromise } = useToast();

    useImperativeHandle(ref, () => ({
      refereshLayout() {
        if (list) list['$_c'].invalidateLayout();
      },
      getOptionsTemplate() {
        return optionsTemplate;
      },
      setOptionsTemplate(key: string, value: any) {
        setOptions(key, value);
      },
      showFormPanel() {
        if (list) list.showFormPanel(true);
      },
    }));

    const [headerTemplate, setHeaderTemplate] = useHeaderTemplates({
      initialProp: TODO_HEADERS_TEAMPLTE,
    });
    const [rowTemplate, setRowTemplate] = useRowTemplate({
      initialProp: TODO_ROW_TEAMPLTE,
    });
    const { optionsTemplate, setOptionsTemplate, setOptions } =
      useOptionsTemplate({
        initialProp: TODO_OPTIONS_TEMPLATE,
      });

    const onScrollHandler = useCallback(
      async (args) => {
        args.control.options.row.updateTemplate(
          'row',
          'calendar',
          'renderer.html',
          renderToString(
            <TodoList
              group={group}
              day={calendarDayRef.current.days[args.top]}
            />
          )
        );
        const days = await selectDay(
          calendarDayRef.current.days[args.top].date
        );
        calendarDayRef.current = days;
      },
      [selectDay]
    );

    const config = {
      props: {
        numberFormat: ',',
        templates: {
          header: headerTemplate,
          row: rowTemplate,
        },
        onScroll: onScrollHandler,
      },
      options: optionsTemplate,
    };

    useEffect(() => {
      calendarDayRef.current = calendar;
      // 캘린더 정보가 바뀌면 선택정보도 변경해준다.
      if (data instanceof RealTouch.RtListData && data.current) {
        data.current.deleteAll(true);
        data.current = data.loadData(
          calendar.days.map((day) => {
            return {
              date: day.date,
            };
          })
        );
      }

      if (list) {
        // calendar 정보가 변경되면 list onScroll 의 핸들러 정보도 변경해준다.
        list.onScroll = onScrollHandler;
        const calendarIndex = calendarDayRef.current.days.findIndex(
          (day) => day.date === calendar.selected.day
        );

        if (calendarIndex < 0) return;

        const currentDate = calendar.days[calendarIndex].date;

        /* Touch Header Sync */
        const headerDate = getDateFormat(currentDate, 'M월 DD일 dd요일');
        setOptions('header.caption', headerDate);
        list.options.header.caption = headerDate;

        if (
          calendar.days[calendarIndex]?.isHoliday ||
          parseInt(getDateFormat(currentDate, 'd')) === 0
        ) {
          list.options.header.captionColor = '#ef4444';
        } else {
          list.options.header.captionColor =
            theme === 'dark' ? 'white' : '#111827';
        }

        /* Touch Row Sync */
        list.options.row.updateTemplate(
          'row',
          'calendar',
          'renderer.html',
          renderToString(
            <TodoList
              group={group}
              day={calendarDayRef.current.days[calendarIndex]}
            />
          )
        );

        list.scrollIndex = calendarIndex;
        setTimeout(() => touchRefreshLayout(), 0);
      }
    }, [calendar]);

    // 높이 변경 대응
    useEffect(() => {
      setTimeout(() => touchRefreshLayout(), 0);
    }, [height]);

    useEffect(() => {
      touchThemeApply();
    }, [theme]);

    // 첫 로드 시 설정
    useEffect(() => {
      setDataLoading(true);

      const initData = async () => {
        const selectDay = calendar.selected.day
          ? calendar.selected.day
          : new Date();
        const [holidays, todos] = await Promise.all([
          await searchHolidays(getYear(selectDay)),
          await getTodo({
            startDate: getDateFormat(calendar.startDate, 'YYYYMMDD'),
            endDate: getDateFormat(calendar.endDate, 'YYYYMMDD'),
          }),
        ]);

        setCalendar({
          type: CalendarDayActionType.SET_HOLIDAYS,
          value: holidays,
        });
        setCalendar({
          type: CalendarDayActionType.SET_EVENTS,
          value: todos,
          groups: visibleGroups,
        });

        setDataLoading(false);
      };

      initData();

      const listData = RealTouch.createListData(
        '',
        {
          fields: [{ name: 'date' }],
        },
        {
          values: calendar.days.map((day) => {
            return {
              date: day.date,
            };
          }),
        }
      );
      data.current = listData;

      const calendarIndex = calendar.days.findIndex(
        (day) => day.date === calendar.selected.day
      );

      const listControl = RealTouch.createListControl(
        document,
        touchRef.current!
      );

      listControl.setConfig(config);
      listControl.data = listData;
      listControl.scrollIndex = calendarIndex;

      listControl.options.header.style.background =
        theme === 'dark' ? '#1e293b' : '#f3f4f6';
      listControl.options.header.captionColor =
        theme === 'dark' ? 'white' : '#111827';
      listControl.options.row.style.background =
        theme === 'dark' ? '#1e293b' : '#f3f4f6';

      setList(listControl);

      return () => {
        if (list) {
          list.destroy();
        }

        if (data.current) {
          data.current.destroy();
          data.current = null;
        }
        /* touch destory가 돔을 없애주지 않는 현상이 있다. */
        if (touchRef.current) {
          const children = touchRef.current.children;
          for (let i = 0; i < children.length; i++) {
            children.item(i)?.remove();
          }
        }
      };
    }, []);

    async function todoClickHandler(event: SyntheticEvent<HTMLElement>) {
      if (!list) return;

      const type = (event.target as HTMLElement).getAttribute('data-type');

      if (!type) return;

      const dataSets = (event.target as HTMLElement).dataset;

      if (type === 'empty') {
        setFormType('create');
        setCreateCallback({
          handler: (date: string, item: any) => {
            setCalendar({
              type: CalendarDayActionType.UPDATE_EVENTS,
              date,
              item,
            });
          },
        });
        setModalOpen(true);
      }

      if (type === 'info') {
        setFormType('info');
        setUpdateForm({
          date: dataSets.date,
          title: dataSets.title,
          isHoliday: dataSets.isHoliday,
        });
        setModalOpen(true);
      }

      if (type === 'completed') {
        if (!dataSets.todoId || !dataSets.date) return;

        setDataLoading(true);

        try {
          const result = await toastPromise(
            completeTodo({
              todoId: dataSets.todoId,
              date: getDateFormat(dataSets.date, 'YYYYMMDD'),
              completed: true,
            }),
            '완료 처리중입니다.',
            '할 일이 완료되었습니다.'
          );
          setCalendar({
            type: CalendarDayActionType.UPDATE_EVENTS,
            date: getDateFormat(dataSets.date, 'YYYY-MM-DD'),
            item: result,
          });
        } catch (error) {
          console.log(error);
        } finally {
          setDataLoading(false);
        }
      }

      if (type === 'todo') {
        setFormType('update');
        setUpdateForm({
          groupId: dataSets.groupId,
          todoId: dataSets.todoId,
          date: dataSets.date,
          title: dataSets.title,
          content: dataSets.content,
          completed: dataSets.completed,
          createdAt: dataSets.createdAt,
          updatedAt: dataSets.updatedAt,
          updateCallback: (date: string, item: any) => {
            setCalendar({
              type: CalendarDayActionType.UPDATE_EVENTS,
              date: date,
              item,
            });
          },
          deleteCallback: (date: string, todoId: string) => {
            setCalendar({
              type: CalendarDayActionType.REMOVE_EVENTS,
              value: todoId,
              date: date,
            });
          },
        });
        setModalOpen(true);
      }
    }

    function touchRefreshLayout() {
      if (list) list['$_c'].invalidateLayout();
    }

    function touchRowScrollHandler(event: PointerEvent) {
      list && list['$_c'].scrollBy(-event.movementY);
    }

    function touchThemeApply() {
      if (list) {
        list.options.header.style.background =
          theme === 'dark' ? '#1e293b' : '#f3f4f6';
        list.options.header.captionColor =
          theme === 'dark' ? 'white' : '#111827';
        list.options.row.style.background =
          theme === 'dark' ? '#1e293b' : '#f3f4f6';
      }
    }

    /**
     * RealTouch 에서 지원하지 않는 문제
     * - 화면의 오른쪽 끝 부근에서 슬라이드 했을 때 Row Swipe를 방지한다.
     */
    function pontinerDownCapture(event: SyntheticEvent) {
      if ((event as PointerEvent).clientX > width - 25) {
        setPreventMove(true);
      } else {
        setIsDragging(true);
      }
    }
    function pointerMoveCapture(event: PointerEvent) {
      if (preventMove) {
        if (event.cancelable) event.preventDefault();
        event.stopPropagation();
      } else {
        if (touchTopPosition && 0 > event.clientY && isDragging)
          setIsDragging(false);
        if (isDragging) {
          touchRowScrollHandler(event);
        }
      }
    }
    function pointerUpCapture(event: SyntheticEvent) {
      if (isDragging) event.preventDefault();
      setPreventMove(false);
      setIsDragging(false);
    }

    return (
      <>
        <motion.div
          id="realgrid-touch"
          ref={(node) => {
            if (touchRef) (touchRef.current as any) = node;
          }}
          className="w-full h-full dark:bg-[#4d4e52]"
          onClick={todoClickHandler}
          onPointerDownCapture={pontinerDownCapture}
          onPointerMoveCapture={pointerMoveCapture}
          onPointerUpCapture={pointerUpCapture}
        ></motion.div>
      </>
    );
  }
);

export default RealTouchTodoList;
