import { Fragment, useRef, Dispatch, SyntheticEvent, useState, useCallback, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from './Button';
import Icon from './Icon';
import { RtListControl } from 'realgrid-touch';

export type GroupColorModalprops = {
    open: boolean;
    setOpen: Dispatch<boolean>;
    color: string;
    saveHandler: (color: string) => void;
    control: RtListControl;
};

export default function GroupColorModal({ open, setOpen, color, saveHandler, control }: GroupColorModalprops) {
    const colors = [
        '#3b82f6',
        '#06b6d4',
        '#84cc16',
        '#10b981',
        '#6366f1',
        '#c084fc',
        '#ec4899',
        '#f43f5e',
        '#f97316',
        '#f59e0b',
        '#eab308',
        '#64748b',
    ];

    const intialColorIndex = colors.findIndex(c => c === color);
    const [colorIndex, setColorIndex] = useState(intialColorIndex);
    async function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();
        const element = document.getElementById('color');
        if (!element) return;

        element.style['fill'] = colors[colorIndex];
        saveHandler(colors[colorIndex]);
        setOpen(false);
    }

    useEffect(() => {
        setColorIndex(intialColorIndex);
    }, [open])
    const colorsMap = () => {
        return colors.map((color, index) => {
            return (
                <div
                    key={index}
                    className="col-span-1"
                    onClick={() => setColorIndex(index)}
                >
                    <span
                        className="inline-flex justify-center items-center h-8 w-8 rounded-full"
                        style={{
                            background: color,
                        }}
                    >
                        {colorIndex === index && (
                            <Icon
                                className="text-white"
                                name="check"
                                size="md"
                            />
                        )}
                    </span>
                </div>
            );
        })
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto text-gray-800 dark:text-white overflow-hidden">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative p-4 flex-1 transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 text-left shadow-xl transition-all">
                                <form
                                    className="flex-1 p-4 overflow-auto"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="text-center">
                                        <Dialog.Title
                                            as="h1"
                                            className="text-base font-semibold mb-4 leading-6 text-gray-800 dark:text-white"
                                        >
                                            할 일 그룹 색상 선택
                                        </Dialog.Title>
                                        <div className="grid grid-cols-4 w-full h-full gap-4 mb-8">
                                            {colorsMap()}
                                        </div>
                                    </div>
                                    <div className="flex gap-x-2">
                                        <Button
                                            type="cancel"
                                            containerClassNames="flex-1"
                                            classNames="w-full text-white"
                                            text="취소"
                                            size="md"
                                            onClick={() => setOpen(false)}
                                        />
                                        <Button
                                            type="primary"
                                            buttonType="submit"
                                            containerClassNames="flex-1"
                                            classNames="w-full text-white"
                                            text="저장"
                                            size="md"
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
