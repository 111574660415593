import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../context/useAppContext';
import { useFormFields } from '../hooks/useFormFields';
import { useToast } from '../hooks/useToast';
import { createTodo } from '../api/todos';
import { getDateFormat } from '../utils/date';
import clsx from 'clsx';
import Icon from './Icon';
import ListBox from './ListBox';
import {
  CalendarAction,
} from '../hooks/useCalendarDays';
import Button from './Button';

export type TodoCreateFormProps = {
  selectGroupId: string;
  selectedDay: string;
  setCalendar: React.Dispatch<CalendarAction>;
  createCallback: (date: string, item: any) => void;
};

export default function TodoCreateForm({
  selectGroupId,
  selectedDay,
  createCallback
}) {
  const { theme, group, setModalOpen } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const createRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState(selectGroupId);
  const [fields, setFields] = useFormFields({
    title: '',
    date: selectedDay,
    content: '',
    groupId: selected,
  });
  const { toastPromise } = useToast();

  const inputContainerClasses = clsx('flex items-center border-box mb-4');
  const inputClasses = clsx(
    'text-md flex-grow border-l-0 border-t-0 border-r-0 border-b border-b-gray-600 bg-white dark:bg-slate-800 focus:ring-0 focus:ring-offset-0'
  );

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    const result = await toastPromise(
      createTodo({
        groupId: selected,
        title: fields.title,
        date: getDateFormat(fields.date, 'YYYYMMDD'),
        content: fields.content,
      }),
      '할 일을 등록중입니다.',
      '할 일이 등록되었습니다.'
    );

    createCallback(getDateFormat(fields.date, 'YYYY-MM-DD'), result);
  
    setIsLoading(false);
    setModalOpen(false);
  }

  useEffect(() => {
    if (createRef.current) createRef.current.focus();
  }, []);

  return (
    <form className="flex-1 p-4 overflow-auto" onSubmit={handleSubmit}>
      <div className="flex justify-between items-center mb-4">
        <Icon
          name="arrowLeft"
          size="md"
          className="text-gray-900 dark:text-white"
          onClick={() => setModalOpen(false)}
        />
        <h1 className="text-base font-bold py-2 text-gray-900 dark:text-gray-200">
          할 일 추가
        </h1>
        <Button
          type="none"
          buttonType="submit"
          text="저장"
          classNames="text-base font-semibold"
          disabled={isLoading}
          />
      </div>
      <div className={inputContainerClasses}>
        <input
          id="title"
          name="title"
          type="text"
          className={inputClasses}
          onChange={setFields}
          placeholder="제목 입력"
          ref={createRef}
          required
          autoFocus
        />
      </div>
      <div className={inputContainerClasses}>
        <div className="flex justify-center w-[40px]">
          <Icon
            name="calendar"
            size="sm"
            className="text-gray-900 dark:text-white"
          />
        </div>
        <input
          id="date"
          name="date"
          type="date"
          className={inputClasses}
          style={{
            colorScheme: theme === 'dark' ? 'dark' : 'light'
          }}
          value={fields.date}
          onChange={setFields}
          required
          pattern="\d{4}-\d{2}-\d{2}"
        />
      </div>
      <div className={inputContainerClasses}>
        <div className="flex justify-center w-[40px]">
          <Icon
            name="collection"
            size="sm"
            className="text-gray-900 dark:text-white"
          />
        </div>
        <ListBox
          groups={group.items}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className={inputContainerClasses}>
        <div className="flex justify-center w-[40px]">
          <Icon
            name="documentText"
            size="sm"
            className="text-gray-900 dark:text-white"
          />
        </div>
        <textarea
          rows={4}
          name="content"
          id="content"
          className="flex-grow rounded-md border-0 py-1.5 min-h-40 max-h-40 bg-gray-200 dark:bg-slate-700 text-gray-900 dark:text-white shadow-sm focus:ring-0 focus:ring-offset-0"
          onChange={setFields}
          defaultValue={''}
          placeholder="내용을 입력해주세요."
        />
      </div>
    </form>
  );
}
