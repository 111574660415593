import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  Dispatch,
  SyntheticEvent,
} from 'react';
import { useAppContext } from '../context/useAppContext';
import {
  CalendarAction,
  CalendarDayActionType,
  CalendarDayState,
} from '../hooks/useCalendarDays';
import useHeaderTemplates from '../hooks/useHeaderTemplate';
import useOptionsTemplate from '../hooks/useOptionsTemplate';
import {
  TODO_HEADERS_TEAMPLTE,
  TODO_ROW_TEMPLATE,
  TODO_OPTIONS_TEMPLATE,
  EMPTY_TEMPLATE,
  TODO_ROW_FIELDS,
} from '../templates/checkList';
import {
  getAddDate,
  getDateFormat,
  getSubtractDate,
  getYear,
} from '../utils/date';
import RealTouch, { RtListControl } from 'realgrid-touch';
import 'realgrid-touch/dist/realgrid-touch-style.css';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import { ITodo, ITodoItem, completeTodo, getTodoAll } from '../api/todos';
import { useToast } from '../hooks/useToast';
import { sleep } from '../utils/util';

export type TouchCheckListHandler = {
  refereshTodoCheckList: (date: string, item: ITodoItem) => Promise<void>
};

export type TouchCheckListProps = {
  calendar: CalendarDayState;
  setCalendar: Dispatch<CalendarAction>;
  selectDay?: (value: dayjs.ConfigType) => Promise<CalendarDayState>;
  setFormType: React.Dispatch<'create' | 'update' | 'info'>;
  setUpdateForm: React.Dispatch<any>;
  setCreateCallback: React.Dispatch<any>;
};

const RealTouchCheckList = forwardRef<
  TouchCheckListHandler,
  TouchCheckListProps
>(
  (
    {
      calendar,
      setCalendar,
      selectDay,
      setFormType,
      setUpdateForm,
      setCreateCallback,
    },
    ref
  ) => {
    const touchRef = useRef<HTMLDivElement>(null);
    const { clientWindow, theme, setDataLoading, setModalOpen } = useAppContext();
    const [filterType, setFilterType] = useState<'todo' | 'completed'>('todo');
    const filterTypeRef = useRef<'todo' | 'completed'>('todo');
    const { width, height } = clientWindow;
    const isFirstLoad = useRef(true);
    const [list, setList] = useState<RealTouch.RtListControl | null>(null);
    const listRef = useRef<RealTouch.RtListControl | null>(null);
    const listData = useRef<RealTouch.RtListData | null>(null);
    const listView = useRef<RealTouch.RtDataView | null>(null);

    const { toastPromise } = useToast();

    useImperativeHandle(ref, () => ({
      refereshTodoCheckList: async (date: string, item: ITodoItem) => {
        listData.current?.appendRow({
          groupId: item.groupId,
          todoId: item.todoId,
          orgDate: date,
          date: getDateFormat(date, 'YYYY. M. DD dd요일'),
          title: item.title,
          completed: item.completed,
        })
      }
    }));

    const config = {
      props: {
        numberFormat: ',',
        templates: {
          header: TODO_HEADERS_TEAMPLTE,
          row: TODO_ROW_TEMPLATE,
          empty: EMPTY_TEMPLATE,
        },
      },
      options: TODO_OPTIONS_TEMPLATE,
    };

    // 서버에서 갱신 처리
    async function refetchTodo() {
      setDataLoading(true);

      const result = await getTodoAll();
      const items: any[] = [];

      result.forEach((todo) => {
        todo.items.forEach((item) => {
          items.push({
            groupId: item.groupId,
            todoId: item.todoId,
            orgDate: todo.date,
            date: getDateFormat(todo.date, 'YYYY. M. DD dd요일'),
            title: item.title,
            completed: item.completed,
          });
        });
      });

      listData.current?.loadData(items);

      if (filterTypeRef.current === 'completed') {
        listView.current?.enableFilter('todo', false);
        listView.current = listView.current?.enableFilter('completed', true)!;
        list?.options.row.updateTemplate(
          'header',
          'todo-count',
          'renderer.html',
          `<div class="flex text-gray-400 text-sm p-1">
            완료한 일 ${listData.current!.findRows({ completed: 'true' }).length
          }개
          </div>`
        );
      } else {
        listView.current?.enableFilter('todo', true);
        listView.current = listView.current?.enableFilter('completed', false)!;
        list?.options.row.updateTemplate(
          'header',
          'todo-count',
          'renderer.html',
          `<div class="flex text-gray-400 text-sm p-1">
            할 일 ${listData.current!.findRows({ completed: 'false' }).length}개
          </div>`
        );
      }
      setDataLoading(false);
    }

    // 높이 변경 대응
    useEffect(() => {
      setTimeout(() => touchRefreshLayout(), 0);
    }, [height]);

    useEffect(() => {
      async function refereshTodo() {
        setDataLoading(true);

        const result = await getTodoAll();
        const items: any[] = [];

        result.forEach((todo) => {
          todo.items.forEach((item) => {
            items.push({
              groupId: item.groupId,
              todoId: item.todoId,
              orgDate: todo.date,
              date: getDateFormat(todo.date, 'YYYY. M. DD dd요일'),
              title: item.title,
              completed: item.completed,
            });
          });
        });
        filterTypeRef.current = filterType;
        listData.current?.loadData(items);

        if (filterType === 'completed') {
          listView.current?.enableFilter('todo', false);
          listView.current = listView.current?.enableFilter('completed', true)!;
          list?.options.row.updateTemplate(
            'header',
            'todo-count',
            'renderer.html',
            `<div class="flex text-gray-400 text-sm p-1">
              완료한 일 ${listData.current!.findRows({ completed: 'true' }).length
            }개
            </div>`
          );
        } else {
          listView.current?.enableFilter('todo', true);
          listView.current = listView.current?.enableFilter('completed', false)!;
          list?.options.row.updateTemplate(
            'header',
            'todo-count',
            'renderer.html',
            `<div class="flex text-gray-400 text-sm p-1">
              할 일 ${listData.current!.findRows({ completed: 'false' }).length}개
            </div>`
          );
        }
        setDataLoading(false);
      }

      refereshTodo();
      // 완료 처리관련 동작 최신화
      updateTodoClick(list!);

      list?.options.row.updateTemplate(
        'header',
        'todo',
        'renderer.styleCallback',
        () => {
          if (filterType === 'completed') {
            return {
              color: theme === 'dark' ? 'white' : '#1f2937',
              background: 'transparent',
            };
          } else {
            return {
              color: theme === 'dark' ? '#1f2937' : 'white',
              background: theme === 'dark' ? 'white' : '#1f2937',
            };
          }
        }
      );
      list?.options.row.updateTemplate(
        'header',
        'completed',
        'renderer.styleCallback',
        () => {
          if (filterType === 'todo') {
            return {
              color: theme === 'dark' ? 'white' : '#1f2937',
              background: 'transparent',
            };
          } else {
            return {
              color: theme === 'dark' ? '#1f2937' : 'white',
              background: theme === 'dark' ? 'white' : '#1f2937',
            };
          }
        }
      );
    }, [filterType]);

    // 테마 변경 대응
    useEffect(() => {
      if (!list) return;

      list.options.row.updateTemplate(
        'header',
        'todo',
        'renderer.styleCallback',
        () => {
          if (filterType === 'completed') {
            return {
              color: theme === 'dark' ? 'white' : '#1f2937',
              background: 'transparent',
            };
          } else {
            return {
              color: theme === 'dark' ? '#1f2937' : 'white',
              background: theme === 'dark' ? 'white' : '#1f2937',
            };
          }
        }
      );
      list.options.row.updateTemplate(
        'header',
        'completed',
        'renderer.styleCallback',
        () => {
          if (filterType === 'todo') {
            return {
              color: theme === 'dark' ? 'white' : '#1f2937',
              background: 'transparent',
            };
          } else {
            return {
              color: theme === 'dark' ? '#1f2937' : 'white',
              background: theme === 'dark' ? 'white' : '#1f2937',
            };
          }
        }
      );
      list.options.row.updateTemplate('header', 'todo', 'renderer.style.color', '#111827');
      list.options.header.style.background =
        theme === 'dark' ? '#0f172a' : 'white';
      list.options.header.captionColor = theme === 'dark' ? 'white' : '#111827';
      list.options.row.style.background =
        theme === 'dark' ? '#0f172a' : 'white';
      list.options.row.style.color = theme === 'dark' ? 'white' : '#0f172a';
    }, [theme]);

    // 첫 로드 시 설정
    useEffect(() => {
      async function getTodos(list: RtListControl) {
        setDataLoading(true);

        const result = await getTodoAll();
        const items: any[] = [];

        result.forEach((todo) => {
          todo.items.forEach((item) => {
            items.push({
              groupId: item.groupId,
              todoId: item.todoId,
              orgDate: todo.date,
              date: getDateFormat(todo.date, 'YYYY. M. DD dd요일'),
              title: item.title,
              completed: item.completed,
            });
          });
        });
        const data = RealTouch.createListData(
          'master',
          {
            fields: TODO_ROW_FIELDS,
          },
          {
            values: items,
          }
        );

        listData.current = data;

        listView.current = listData.current
          .createView('data')
          .sort({
            field: 'date',
            dir: 'ascending' as any,
          })
          .addFilterSet({
            filters: [
              {
                name: 'todo',
                label: '할 일',
                enabled: true,
                filter: (row, values) => values['completed'] === 'false',
              },
              {
                name: 'completed',
                label: '완료한 일',
                enabled: false,
                filter: (row, values) => values['completed'] === 'true',
              },
            ],
          })
          .build();

        listControl.data = listView.current;
        listControl.options.row.updateTemplate(
          'header',
          'todo-count',
          'renderer.html',
          `<div class="flex text-gray-400 text-sm p-1">
            할 일 ${listData.current.findRows({ completed: 'false' }).length}개
          </div>`
        );
        setDataLoading(false);
      }

      const listControl = RealTouch.createListControl(
        document,
        touchRef.current!
      );

      listControl.setConfig(config);
      setList(listControl);
      listRef.current = listControl;

      listControl.options.row.updateTemplate(
        'header',
        'todo',
        'renderer.onClick',
        () => {
          setFilterType('todo');
        }
      );
      listControl.options.row.updateTemplate(
        'header',
        'completed',
        'renderer.onClick',
        () => {
          setFilterType('completed');
        }
      );

      // 완료 처리관련 동작 최신화
      updateTodoClick(listControl);

      listControl.options.header.style.background =
        theme === 'dark' ? '#0f172a' : '#f3f4f6';
      listControl.options.header.captionColor =
        theme === 'dark' ? 'white' : '#111827';
      listControl.options.row.style.background =
        theme === 'dark' ? '#0f172a' : 'white';
      listControl.options.row.style.color =
        theme === 'dark' ? 'white' : '#1f2937';

      getTodos(listControl);

      listControl.onRowClick = async (args) => {

        if (!args) return;

        const { control, row } = args;
        const { data } = control;
        const completed = data.getValue(row, 'completed');

        setFormType('update');
        setUpdateForm({
          groupId: data.getValue(row, 'groupId'),
          todoId: data.getValue(row, 'todoId'),
          date: getDateFormat(data.getValue(row, 'orgDate'), 'YYYY-MM-DD'),
          title: data.getValue(row, 'title'),
          content: data.getValue(row, 'content'),
          completed: completed,
          updateCallback: async (date: string, item: ITodoItem) => {
            listView.current?.updateRow(row, {
              groupId: item.groupId,
              todoId: item.todoId,
              orgDate: date,
              date: getDateFormat(date, 'YYYY. M. DD dd요일'),
              title: item.title,
              completed: item.completed,
            });
            updateTodoTotal();
            await refetchTodo();
          },
          deleteCallback: (date: string, todoId: string) => {
            listView.current?.deleteRow(row, true);
            updateTodoTotal();
          },
          deleteWithUpdate: false,
        });
        setModalOpen(true);
      };

      return () => {
        if (list) {
          list.destroy();
        }

        if (listData.current) {
          listData.current.destroy();
          listData.current = null;
        }

        /* touch destory가 돔을 없애주지 않는 현상이 있다. */
        if (touchRef.current) {
          const children = touchRef.current.children;
          for (let i = 0; i < children.length; i++) {
            children.item(i)?.remove();
          }
        }
      };
    }, []);

    function updateTodoClick(list: RtListControl) {
      if (!list) return;
      list.options.row.updateTemplate(
        'row',
        'todoCheck',
        'editor.onClick',
        async (args) => {
          const { control, row } = args;
          const todoId = control.data.getValue(row, 'todoId');
          const orgDate = control.data.getValue(row, 'orgDate');

          if (!todoId) return;
          try {
            setDataLoading(true);

            await toastPromise(
              completeTodo({
                todoId: todoId,
                date: getDateFormat(orgDate, 'YYYYMMDD'),
                completed: true,
              }),
              '완료 처리중입니다.',
              '할 일이 완료되었습니다.'
            );

            listView.current?.deleteRow(row, true);
            updateTodoTotal();
          } catch (error) {
            console.log(error);
          } finally {
            setDataLoading(false);
          }
        }
      )
      list.options.row.updateTemplate(
        'row',
        'completeCheck',
        'editor.onClick',
        async (args) => {
          const { control, row } = args;
          const todoId = control.data.getValue(row, 'todoId');
          const orgDate = control.data.getValue(row, 'orgDate');

          if (!todoId) return;
          try {
            setDataLoading(true);
            await toastPromise(
              completeTodo({
                todoId: todoId,
                date: getDateFormat(orgDate, 'YYYYMMDD'),
                completed: false,
              }),
              '완료 취소 처리중입니다.',
              '할 일 완료가 취소되었습니다.'
            );

            listView.current?.deleteRow(row);
            updateTodoTotal();
          } catch (error) {
            console.log(error);
          } finally {
            setDataLoading(false);
          }
        }
      )
    }
    function updateTodoTotal() {
      if (!listRef.current) return;

      if (filterTypeRef.current === 'todo') {
        listRef.current.options.row.updateTemplate(
          'header',
          'todo-count',
          'renderer.html',
          `<div class="flex text-gray-400 text-sm p-1">
          할 일 ${listData.current!.findRows({ completed: 'false' }).length}개
        </div>`
        );
      } else {
        listRef.current.options.row.updateTemplate(
          'header',
          'todo-count',
          'renderer.html',
          `<div class="flex text-gray-400 text-sm p-1">
          완료한 일 ${listData.current!.findRows({ completed: 'true' }).length
          }개
        </div>`
        );
      }
    }
    function touchRefreshLayout() {
      if (list) list['$_c'].invalidateLayout();
    }

    function ExpanderStyle() {
      if (theme === 'dark')
        return (
          <style>
            {`
            [type='checkbox']:focus {
              outline: none !important;
              outline-offset: 0 !important;
              box-shadow: none !important;
            }
            .rtc-header {
              background: #0f172a !important;
              border: none !important;
            }
            .rtc-body {
              background: #0f172a;
            }
            .rtc-item-expander {
              stroke: white !important;
            }
            .-rtc-layout- {
              left: 0 !important;
            }
            .rtc-row[data-touched='1'] {
              background: #1e293b !important;
            }
            .rtc-toast {
              background: #334155 !important;
            }
          `}
          </style>
        );
      else
        return (
          <style>
            {`
            [type='checkbox']:focus {
              outline: none !important;
              outline-offset: 0 !important;
              box-shadow: none !important;
            }
            .rtc-header {
              background: white !important;
              border: none !important;
            }
            .rtc-body {
              background: white;
            }
            .rtc-item-expander {
              stroke: #1f2937 !important;
            }
            .-rtc-layout- {
              left: 0 !important;
            }
            .rtc-toast {
                background-color: #f3f4f6 !important;
            }
          }
        `}
          </style>
        );
    }

    return (
      <>
        <style>
          {`.rtc-button-button {
              padding: 4px 12px;
              font-size: 12px;
          }`}
        </style>
        {ExpanderStyle()}
        <motion.div
          id="realgrid-touch"
          ref={(node) => {
            if (touchRef) (touchRef.current as any) = node;
          }}
          className="w-full h-full dark:bg-[#4d4e52]"
        ></motion.div>
      </>
    );
  }
);

export default RealTouchCheckList;
