import dayjs from 'dayjs';
import { getDateFormat } from '../utils/date';

const theme = localStorage.getItem('theme') || 'light';

export const TODO_HEADERS_TEAMPLTE = {
  name: 'header',
  description: 'List header template',
  props: {},
  template: {
    layout: 'vlinear',
    width: '100%',
    height: 64,
    itemGap: 8,
    itemsArrange: 'START',
    children: [
      {
        layout: 'hlinear',
        width: '100%',
        itemsArrange: 'start',
        children: [
          {
            id: 'todo',
            renderer: {
              type: 'button',
              label: "할 일",
              onClick: (args) => {
                console.log(args);
              },
              style: {
                color: theme === 'dark' ? '#1f2937' : 'white',
                background: theme === 'dark' ? 'white' : '#1f2937',
                border: '1px solid #9ca3af',
                borderRadius: '1rem',
              }
            },
            align: 'center',
          },
          {
            id: 'completed',
            renderer: {
              type: 'button',
              label: "완료한 일",
              onClick: (args) => {
                console.log(args);
              },
              style: {
                color: theme === 'dark' ? 'white' : '#1f2937',
                border: '1px solid #9ca3af',
                borderRadius: '1rem',
              }
            },
          },
        ],
      },
      {
        layout: 'hlinear',
        width: '100%',
        itemsArrange: 'start',
        children: [
          {
            id: 'todo-count',
            renderer: {
              type: 'html',
              html: `<div class="flex text-gray-400 text-sm p-1">할 일 0개</div>`
            },
            align: 'center',
          },
        ],
      },
    ],
    style: {
      fontSize: '12px',
      padding: '4px 8px'
    }
  },
};

export const TODO_ROW_FIELDS = [
  { name: 'groupId' },
  { name: 'todoId' },
  { name: 'date' },
  { name: 'orgDate' },
  { name: 'title' },
  { name: 'completed' },
];

export const TODO_ROW_TEMPLATE = {
  template: {
    layout: 'hlinear',
    width: '*',
    left: '0%',
    itemGap: 8,
    style: {
      borderBottom: '${border-bottom}'
    },
    children: [{
      id: 'stack',
      layout: 'stack',
      activeChild: 'todo',
      activeCallback: args => {
        const isCompleted = args.ctx.data().getValue(args.row, 'completed') === 'true'
        return isCompleted ? 'completed' : 'todo';
      },
      grow: 1,
      children: [{
        id: 'todo',
        layout: 'vlinear',
        itemGap: 2,
        children: [{
          layout: 'hlinear',
          grow: 1,
          shrink: 1,
          children: [
            {
              id: 'todoCheck',
              field: 'todoId',
              width: '24',
              top: 4,
              editor: {
                type: 'check',
                clickable: true,
                style: {
                  outline: 'none',
                  borderRadius: '100%',
                },
              }
            },
            {
              renderer: {
                type: 'html',
                html: `<span class="w-full text-gray-800 dark:text-white">
                  \${@title}
                </span>`
              }
            }
          ],
        }, {
          layout: 'hlinear',
          height: '100%',
          children: [
            {
              renderer: {
                type: 'spacer',
                space: 24
              }
            },
            {
              renderer: {
                type: 'html',
                html: `<span class="flex items-center w-6 h-6 text-xs text-gray-600 dark:text-gray-400">
                  기한
                </span>`
              }
            },
            {
              field: 'orgDate',
              renderer: {
                type: 'text',
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  height: '1.5rem',
                  fontSize: '0.75rem',
                  lineHeight: '1rem'
                },
                textFormatter: (value) => {
                  return getDateFormat(value, 'YYYY. M. DD dd요일');
                },
                styleCallback: (args) => {
                  if (dayjs().diff(args.value, 'day') > 0) {
                    return {
                      color: '#ef4444'
                    }
                  } else {
                    return {
                      color: 'white'
                    }
                  }
                }
              }
            }
          ]
        }]
      }, {
        id: 'completed',
        layout: 'vlinear',
        itemGap: 2,
        children: [{
          layout: 'hlinear',
          grow: 1,
          shrink: 1,
          children: [
            {
              id: 'completeCheck',
              field: 'completed',
              value: true,
              width: '24',
              top: 4,
              editor: {
                type: 'check',
                clickable: true,
                trueValue: 'true',
                style: {
                  color: '#06b6d4',
                  outline: 'none',
                  borderRadius: '100%',
                },
              }
            },
            {
              renderer: {
                type: 'html',
                html: `<span class="w-full line-through text-gray-500 dark:text-gray-400">
                  \${@title}
                </span>`
              }
            }
          ],
        }, {
          layout: 'hlinear',
          height: '100%',
          children: [
            {
              renderer: {
                type: 'spacer',
                space: 24
              }
            },
            {
              renderer: {
                type: 'html',
                html: `<span class="flex items-center w-6 h-6 text-xs text-gray-500 dark:text-gray-400">
                  기한
                </span>`
              }
            },
            {
              field: 'orgDate',
              renderer: {
                type: 'text',
                style: {
                  display: 'flex',
                  color: '#6b7280',
                  alignItems: 'center',
                  height: '1.5rem',
                  fontSize: '0.75rem',
                  lineHeight: '1rem'
                },
                textFormatter: (value) => {
                  return getDateFormat(value, 'YYYY. M. DD dd요일');
                }
              }
            }
          ]
        }]
      }],
    }]
  },
  params: {
  },
  rowStyle: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '8px',
  }
};

export const EMPTY_TEMPLATE = {
  template: {
    layout: "vlinear",
    itemsAlign: "center",
    itemsArrange: 'center',
    itemGap: 20,
    children: [{
      renderer: {
        type: 'html',
        html: '<div class="w-full h-full text-gray-800 dark:text-white bg-white dark:bg-slate-900">할 일이 존재하지 않습니다.</div>'
      }
    }],
    style: {
      padding: '10px'
    }
  }
};

export const TODO_OPTIONS_TEMPLATE = {
  orientation: 'vertical',
  row: {
    template: 'row',
    borderLine: {
      style: 'none'
    },
    style: {
      background: theme === 'dark' ? '#0f172a' : 'white',
      color: theme === 'dark' ? 'white' : '#1f2937',
    }
  },
  header: {
    visible: true,
    template: 'header',
    height: 64,
    style: {
      background: theme === 'dark' ? '#0f172a' : 'white',
    },
  },
  emptyPage: {
    template: 'empty'
  },
  footer: {
    visible: false,
  },
  scrollBar: true,
  scrollIndicator: false,
};
