import { useEffect, useState } from 'react';
import Button from '../components/Button';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../context/useAppContext';
import { NavLink } from 'react-router-dom';
import { useFormFields } from '../hooks/useFormFields';
import { inputClasses } from '../styles';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ConfirmSignup } from './ConfirmSignup';

export default function Login() {
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
  });
  const { setGroup, isAuthenticated, setAuthenticated, theme } = useAppContext();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [needConfirm, setNeedConfirm] = useState(false);
  const [needValidate, setNeedValidate] = useState(false);
  const [validateMessage, setValidateMessage] = useState('');

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  useEffect(() => {
    // if (isAuthenticated) navigate('/');
  }, []);

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    try {
      setIsAuthenticating(true);
      await Auth.signIn(fields.email, fields.password);
      await setGroup();
      setAuthenticated(true);
      setNeedValidate(false);
    } catch (error) {
      if (error instanceof Error) {
        setNeedValidate(true);
        if (error.name === 'UserNotConfirmedException') {
            setNeedConfirm(true);
            return;
        }

        if (error.name === 'UserNotFoundException')
          setValidateMessage('유저 정보가 존재하지 않습니다.');
        else if (error.name === 'NotAuthorizedException')
          setValidateMessage('아이디 혹은 비밀번호가 올바르지 않습니다.');
        else setValidateMessage(error.message);
      }
    } finally {
      setIsAuthenticating(false);
    }
  }

  async function handleLogout(event: React.SyntheticEvent) {
    event.preventDefault();

    try {
      setIsAuthenticating(true);
      await Auth.signOut();

      setAuthenticated(false);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    } finally {
      setIsAuthenticating(false);
    }
  }

  const loginFormClasses = clsx(
    'w-full h-full flex min-h-full flex-col justify-center sm:px-6 lg:px-8 dark:bg-slate-900',
    { 'bg-default': theme === 'light' }
  );

  const inputStyles = clsx(inputClasses);

  return (
    <motion.div
      className={loginFormClasses}
      initial={{ opacity: 0 }}
      animate="visible"
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
      }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      {needConfirm ? (
        <ConfirmSignup email={fields.email} password={fields.password} />
      ) : (
        <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white dark:bg-slate-800 dark:text-white mx-4">
          <div className="px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <div className="flex items-center justify-between sm:mx-auto sm:w-full sm:max-w-md mb-2">
              <h1 className="text-xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
                Touch Todo App
              </h1>
              <img
                className="flex w-14 h-14"
                src="./android-chrome-192x192.png"
                alt="Todo App"
              />
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  이메일 주소
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoFocus
                    autoComplete="email"
                    required
                    className={inputStyles}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-between items-center">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    비밀번호
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className={inputStyles}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div>
                {needValidate && (
                  <div className="p-4 mt-2 bg-gray-100 rounded-sm">
                    <span className="inline-flex text-sm font-medium text-red-600">
                      {validateMessage}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-y-4">
                {isAuthenticated ? (
                  <Button
                    text="로그아웃"
                    type="primary"
                    buttonType="button"
                    containerClassNames="flex-1"
                    classNames="w-full"
                    onClick={handleLogout}
                  />
                ) : (
                  <Button
                    text="로그인"
                    type="primary"
                    buttonType="submit"
                    containerClassNames="flex-1"
                    classNames="w-full"
                    disabled={!validateForm()}
                    isLoading={isAuthenticating}
                  />
                )}
                <div className="flex justify-between">
                  <NavLink
                    to="/signup"
                    className="text-sm text-primary-500 hover:text-primary-400"
                  >
                    회원가입
                  </NavLink>
                  {/* <NavLink
                    to="/find"
                    className="text-sm text-primary-500 hover:text-primary-400"
                  >
                    비밀번호 찾기
                  </NavLink> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </motion.div>
  );
}
