import { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import DayHeader from '../components/DayHeader';
import set from 'lodash/fp/set';
import { useAppContext } from "../context/useAppContext";

export type UseHeaderTemplateProps = {
    initialProp?: { [key: string]: any };
}
export default function useHeaderTemplates({ initialProp }: UseHeaderTemplateProps): [
    UseHeaderTemplateProps,
    (key: string, value: any) => void
] {
    const { theme, setSlideOpen, setSwiping } = useAppContext();
    const defaultHeaderTemplate = {
        name: 'header',
        description: "List header template",
        style: {
            width: '100%',
        },
        props: {},
        template: {
            layout: "vlinear",
            width: '100%',
            children: [{
                layout: 'hlinear',
                width: '100%',
                itemsArrange: 'spaceBetween',
                children: [
                    {
                        value: "${@caption;Header}",
                        tag: "caption",
                        width: '50%',
                        align: "${@caption-align}",
                        style: {
                            fontWeight: "bold",
                            color: "${@caption-color;#333}",
                            background: 'red'
                        },
                    },
                    {
                        renderer: {
                            type: 'shape',
                            shape: '@menu',
                            shapeWidth: '25',
                            shapeHeight: '30',
                            styleCallback: (() => {
                                return {
                                    fill: theme === 'dark' ? 'white' : '#1e293b'
                                }
                            }),
                            clickable: true,
                            onClick: () => {
                                setSwiping(false);
                                setSlideOpen(true);
                                return true;
                            }
                        }
                    }]
            }, {
                tag: 'name',
                width: '100%',
                layoutStyle: {
                    flexGrow: 1
                },
                renderer: {
                    type: 'html',
                    style: {
                        flexGrow: 1,
                        textAlign: 'center',
                    },
                    html: renderToString(<DayHeader />)
                }
            }]
        }
    }

    const [headerTemplate, setHeaderTemplate] = useState<any>(
        initialProp ? initialProp : defaultHeaderTemplate
    );

    useEffect(() => {
        const headerStyle = set(
          'header.style.background',
          theme === 'dark' ? '#1e293b' : '#f3f4f6',
          headerTemplate
        );
        setHeaderTemplate(headerStyle);
      }, [theme]);

    return [
        headerTemplate,
        (key, value) => {
            setHeaderTemplate(
                set(key, value, headerTemplate)
            )
        }
    ];
}